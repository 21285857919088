import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RadioGroup } from "@headlessui/react";
import { useFormikContext } from "formik";
import { FlowData } from "@hilos/types/flow";
import { classNames } from "src/Helpers";
import { TRIGGER_TYPES } from "../constants/triggers";

export default function TriggerCategorySelector({
  handleTriggerTypeChange,
  triggersForCategory,
}) {
  const [t] = useTranslation();
  const { values } = useFormikContext<FlowData>();

  const TriggerIcon = useMemo(() => {
    return TRIGGER_TYPES[values.trigger_type].icon;
  }, [values.trigger_type]);

  return (
    <div className="-space-y-px rounded-md bg-white">
      <RadioGroup
        value={values.trigger_type}
        onChange={handleTriggerTypeChange}
      >
        {triggersForCategory.map((trigger, idx) => (
          <RadioGroup.Option
            as={Fragment}
            key={t(TRIGGER_TYPES[trigger].name)}
            value={trigger}
          >
            {({ active, checked }) => (
              <div
                className={classNames(
                  idx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                  idx === triggersForCategory.length - 1
                    ? "rounded-bl-md rounded-br-md"
                    : "",
                  checked
                    ? "z-10 border-hilos-alt-300 bg-hilos-light"
                    : "border-gray-200",
                  "relative flex cursor-pointer border p-4 focus:outline-none"
                )}
              >
                <span
                  className={classNames(
                    checked
                      ? "border-transparent bg-hilos"
                      : "border-gray-300 bg-white",
                    active ? "ring-2 ring-hilos ring-offset-2" : "",
                    "mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border"
                  )}
                  aria-hidden="true"
                >
                  <span className="h-1.5 w-1.5 rounded-full bg-white" />
                </span>
                <div className="block overflow-hidden">
                  <span className="ml-3 flex flex-col">
                    <span className="block text-sm font-medium text-gray-900">
                      {t(TRIGGER_TYPES[trigger].name)}
                    </span>
                    <span
                      className={classNames(
                        trigger === values.trigger_type ? "block" : "hidden",
                        "text-gray-500 text-sm transition-all ease-out duration-200"
                      )}
                    >
                      {t(TRIGGER_TYPES[trigger].description)}
                    </span>
                    <span className="absolute bottom-1 right-1 -z-10">
                      {TriggerIcon && (
                        <TriggerIcon className="h-12 w-12 text-gray-200" />
                      )}
                    </span>
                  </span>
                </div>
              </div>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
    </div>
  );
}
