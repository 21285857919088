import { useTranslation } from "react-i18next";
import {
  FlowStepEdit,
  FlowVersionDetailRead,
} from "@hilos/types/private-schema";
import Linkify from "src/components/Linkify";
import {
  ANSWER_OPTIONS_RENDER_TYPES,
  ANSWER_TYPES,
} from "../../builder/constants/steps/question";
import FlowStepMaxAttemptsDetail from "./components/FlowStepMaxAttemptsDetail";
import FlowStepMaxWaitTimeDetail from "./components/FlowStepMaxWaitTimeDetail";

interface FlowStepQuestionDetailProps {
  step: FlowStepEdit;
  flowVersion: FlowVersionDetailRead;
  stepInfo: FlowStepEdit;
  getStepById: (id: string) => FlowStepEdit;
}

export default function FlowStepQuestionDetail({
  step,
  flowVersion,
  stepInfo,
  getStepById,
}: FlowStepQuestionDetailProps) {
  const { t } = useTranslation();

  const optionRenderTypeData = ANSWER_OPTIONS_RENDER_TYPES.find(
    (at) => at.value === step.answer_options_render
  );

  const answerTypeData = ANSWER_TYPES.find(
    (at) => at.value === step.answer_type
  );

  return (
    <div className="space-y-4">
      <div className="w-80 space-y-2 rounded-lg bg-whatsapp-outgoing py-2 px-3 text-left text-sm text-gray-900">
        <p className="whitespace-pre-wrap break-words">
          <Linkify message={step.body || ""} />
        </p>
      </div>
      <div>
        {t(
          "flow-executions:steps-detail.question.answer-type",
          "Validating answer as"
        )}{" "}
        <span className="font-medium text-gray-900">
          {answerTypeData && t(answerTypeData.label)}
        </span>
        .
        {["SINGLE_OPTION", "MULTIPLE_OPTION"].includes(
          step.answer_type as string
        ) && (
          <div className="space-y-1">
            <p>
              {t(
                "flow-executions:steps-detail.question.showing-options-as",
                "Showing options as"
              )}
              Showing options as{" "}
              <span className="font-medium text-gray-900">
                {optionRenderTypeData && t(optionRenderTypeData.label)}
              </span>
              .
            </p>
            {step.answer_options && (
              <ul>
                {step.answer_options.map((option, idx) => (
                  <p className="text-gray-900 font-medium">{option}</p>
                ))}
              </ul>
            )}
            {step.options_from_variable && (
              <div>
                {t(
                  "flow-executions:steps-detail.question.rendering-options-from-var.description",
                  "Rendering options from variable"
                )}{" "}
                <span className="text-gray-900 font-medium">
                  {step.options_from_variable}
                </span>
                <ul>
                  <li>
                    {t(
                      "flow-executions:steps-detail.question.rendering-options-from-var.title",
                      "Variable title"
                    )}
                    :{" "}
                    <span className="text-gray-900 font-medium">
                      {step.option_from_variable_title}
                    </span>
                  </li>
                  <li>
                    {t(
                      "flow-executions:steps-detail.question.rendering-options-from-var.value",
                      "Variable value"
                    )}
                    :{" "}
                    <span className="text-gray-900 font-medium">
                      {step.option_from_variable_value}
                    </span>
                  </li>
                  <li>
                    {t(
                      "flow-executions:steps-detail.question.rendering-options-from-var.description",
                      "Variable description"
                    )}
                    :{" "}
                    <span className="text-gray-900 font-medium">
                      {step.option_from_variable_description}
                    </span>
                  </li>
                </ul>
              </div>
            )}
          </div>
        )}
      </div>

      <p>
        {t(
          "flow-executions:steps-detail.question.validation-message",
          "If the user's answer is not valid, answer with"
        )}{" "}
        <span className="text-gray-900 font-medium">
          "{step.answer_validation_message}"
        </span>
      </p>
      {step.has_max_wait_time && (
        <FlowStepMaxWaitTimeDetail step={step} getStepById={getStepById} />
      )}
      {step.has_max_answer_attempts && (
        <FlowStepMaxAttemptsDetail step={step} getStepById={getStepById} />
      )}
    </div>
  );
}
