import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { WorkflowTaskEdit } from "@hilos/types/private-schema";
import HeaderNavigation from "src/components/HeaderNavigation";
import { classNames } from "src/Helpers";
import { buildRoute } from "../../router/router";
import WorkflowBoardAddTask from "./WorkflowBoardAddTask";
import {
  WorkflowBoardStore,
  useWorkflowBoardContext,
} from "./WorkflowBoardContext";
import WorkflowBoardFilters from "./WorkflowBoardFilters";
import Board from "./board/Board";
import { Column } from "./board/Column";
import useWorkflowTasks from "./useWorkflowTasks";

export interface SelectedWorkflowTask {
  id: string;
  state: string;
}

export default function WorkflowBoardData() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { workflowId, stateId, taskId } = useParams();
  const [newWorkflowTask, setNewWorkflowTask] =
    useState<Partial<WorkflowTaskEdit> | null>(null);

  const { instanceId, name, columns, states, tasksByState, onMoveCard } =
    useWorkflowBoardContext<WorkflowBoardStore>();

  useWorkflowTasks({
    workflowId,
  });

  const showTask = useMemo(() => Boolean(stateId && taskId), [stateId, taskId]);

  const handleShowAddTask = useCallback((state: string) => {
    setNewWorkflowTask({
      state,
    });
  }, []);

  const handleClickCard = useCallback(
    (nextSelectedTask: SelectedWorkflowTask | null = null) => {
      if (nextSelectedTask) {
        navigate(
          buildRoute("workflow-task-details", {
            workflowId,
            stateId: nextSelectedTask.state,
            taskId: nextSelectedTask.id,
          })
        );
      }
    },
    [navigate, workflowId]
  );

  return (
    <div
      className={classNames(
        "flex flex-col w-full h-full overflow-hidden",
        showTask && "bg-gray-100"
      )}
    >
      {!showTask && (
        <div className="pt-4 px-4 sm:px-6 lg:px-8">
          <HeaderNavigation
            navPages={[
              {
                name: t("home"),
                url: buildRoute("dashboard"),
              },
              {
                name: t("workflows"),
                url: buildRoute("workflow-list"),
              },
              {
                name,
                url: buildRoute("workflow-board", { id: workflowId }),
              },
            ]}
          />
        </div>
      )}
      <Board
        instanceId={instanceId}
        tasksByState={tasksByState}
        onMoveCard={onMoveCard}
        className={showTask ? "bg-gray-50" : "bg-white py-4"}
      >
        <WorkflowBoardAddTask
          workflowId={workflowId as string}
          states={states}
          initialValues={newWorkflowTask}
          onClose={() => setNewWorkflowTask(null)}
        />
        <AnimatePresence mode="popLayout">
          {!showTask && <WorkflowBoardFilters states={states} />}
        </AnimatePresence>
        <div className="flex flex-1 overflow-hidden">
          <AnimatePresence mode="popLayout">
            <motion.div
              className={classNames(
                "flex flex-1 flex-row justify-start overflow-hidden gap-2",
                showTask ? "divide-x" : "px-4 sm:px-6 lg:px-8 overflow-x-scroll"
              )}
            >
              {states.map(
                (state) =>
                  Boolean(
                    state.id &&
                      columns.includes(state.id) &&
                      (!stateId || state.id === stateId)
                  ) && (
                    <Column
                      key={state.id}
                      id={state.id}
                      title={state.name}
                      tasks={tasksByState[state.id]}
                      selectedTaskId={taskId}
                      onClickCard={handleClickCard}
                      onShowAddTask={handleShowAddTask}
                    />
                  )
              )}
              <Outlet />
            </motion.div>
          </AnimatePresence>
        </div>
      </Board>
    </div>
  );
}
