import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ChatIcon } from "@heroicons/react/outline";
import axios from "axios";
import { Formik } from "formik";
import HeaderNavigation from "src/components/HeaderNavigation";
import StateButton from "src/components/StateButton";
import APIErrors from "src/components/base/APIErrors";
import { useChannel } from "src/hooks/useChannel";
import useHilosStore from "src/hooks/useHilosStore";
import { transformNullsToUndefined } from "src/Helpers";
import { API_ROUTES, buildAPIRoute, buildRoute } from "src/router/router";
import { axiosErr } from "src/types/axios";
import { ChannelProvider, ChannelType } from "src/types/channel";
import ChannelForm from "./ChannelForm";
import ChannelProxyForm from "./ChannelProxyForm";
import * as meta from "./Meta";
import * as cloudAPIMeta from "./MetaCloudAPISettingsMeta";

export default function ChannelHilosCloudAPIUpdate() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const channelId = params.id as string;
  const { channel } = useChannel(channelId);
  const { session, reloadSession } = useHilosStore();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});

  const onSubmit = async (formValues, setSubmitting) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.patch(
        buildAPIRoute(API_ROUTES.CHANNEL_DETAIL, { ":id": channelId }),
        formValues
      );
      setSubmitting(false);
      setSuccess(true);
      reloadSession();
      setTimeout(() => {
        navigate(buildRoute("config-channel"));
      }, 1000 * 2);
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      setSuccess(false);
      if (errorAxios?.response?.status === 400) {
        console.log("error", errorAxios);
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError(
          "Couldn't update your channel information. Please try again later."
        );
      }
      setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setSubmitted(true);
    }
  };

  const initialValues = useMemo(() => {
    if (!channel) {
      return {
        ...meta.getInitialValues(
          ChannelType.WHATSAPP,
          ChannelProvider.META_CLOUD_API
        ),
        settings: cloudAPIMeta.getInitialValues(),
      };
    }
    const initialValues = { ...channel };
    initialValues.is_default = channel.id === session?.account.default_channel;
    transformNullsToUndefined(initialValues);
    return initialValues;
  }, [channel]);

  if (!channel) {
    return null;
  }

  return (
    <>
      <div className="h-screen overflow-y-auto bg-gray-50">
        {/* Header */}
        <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
          <HeaderNavigation
            navPages={[
              {
                name: "Home",
                url: buildRoute("dashboard"),
              },
              {
                name: "Settings",
                url: buildRoute("config-account"),
              },
              {
                name: "WhatsApp Accounts",
                url: buildRoute("config-channel"),
              },
              {
                name: channel?.name,
                url: buildRoute("config-channel-hilos-cloud-api-details", {
                  id: channelId,
                }),
              },
            ]}
          />
          <div className="lg:flex lg:items-center lg:justify-between">
            <div className="min-w-0 flex-1">
              <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                <ChatIcon className="mr-2 h-7 w-7" aria-hidden="true" />
                {t(
                  "channel:update-channel-title",
                  "Update channel {{channel}}",
                  { channel: channel.name }
                )}
              </h2>
            </div>
          </div>
        </div>

        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto mt-6 max-w-3xl">
            <Formik
              validationSchema={meta.getSchema(channel.channel_provider)}
              onSubmit={(values, { setSubmitting }) =>
                onSubmit(values, setSubmitting)
              }
              enableReinitialize={true}
              initialValues={initialValues}
            >
              {(formik) => (
                <form noValidate onSubmit={formik.handleSubmit}>
                  <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                      <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          {t(
                            "channel:channel-information",
                            "Channel Information"
                          )}
                        </h3>
                        <p className="mt-1 text-sm text-gray-600">
                          {t(
                            "channel:add-phone-number-description",
                            "Here you can see and change your channel configuration."
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="mt-5 md:col-span-2 md:mt-0">
                      <div className="overflow-hidden bg-white shadow sm:rounded-md">
                        <APIErrors
                          APIError={backendError}
                          APIValidationErrors={backendValidationErrors}
                          fieldTranslations={meta.FIELDS}
                        />

                        <ChannelForm disablePhoneEditing />
                      </div>
                    </div>
                  </div>

                  <ChannelProxyForm formik={formik} />

                  <div className="mt-5">
                    <div className="text-right">
                      <StateButton
                        isSubmitting={formik.isSubmitting}
                        submitted={submitted}
                        success={success}
                        submittingText="Updating channel..."
                        successText="Channel updated!"
                        initialText={
                          <>
                            <span className="fe fe-save mr-2"></span>
                            {t("save-changes", "Save Changes")}
                          </>
                        }
                      />
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
