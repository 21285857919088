import { Fragment, PropsWithChildren } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useSidebar } from "src/components/ui/sidebar";
import { classNames } from "src/Helpers";

interface SlideOverProps {
  show: boolean;
  onClose: () => void;
}

export default function NotificationSlideOver({
  show,
  children,
  onClose,
}: PropsWithChildren<SlideOverProps>) {
  const { open } = useSidebar();

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-20 border border-blue-600"
        onClose={onClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div
            className={classNames(
              "fixed flex max-w-full inset-y-0 left-0 sm:pr-16",
              open ? "sm:left-[16rem]" : "sm:left-[3rem]"
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="transform transition-opacity ease-in-out duration-10 sm:duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transform transition-opacity ease-in-out duration-10 sm:duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="w-screen max-w-md">
                <div className="flex h-full flex-col bg-gray-50 shadow-xl border-r border-gray-200 pb-4">
                  {children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
