import { useCallback, useMemo, useState } from "react";
import { startOfDay } from "date-fns";
import {
  ConversationContent,
  InboxContactRead,
  WhatsAppMessage,
} from "@hilos/types/private-schema";
import ContentListScrollArea from "src/containers/workflow/ContentListScrollArea";
import Loading from "src/components/Loading";
import MessageMediaModal from "src/components/MessageMediaModal";
import {
  getContentHistory,
  getShowDetails,
  getTimestampFromContent,
} from "src/helpers/conversation";
import { queryClient } from "src/HilosProvider";
import { ChannelProvider } from "src/types/channel";
import { CursorPageData } from "src/types/hilos";
import { HandleMountConversationContent } from "./Conversation";
import ConversationContentItem from "./ConversationContentItem";
import ConversationContentItemSeparator from "./ConversationContentItemSeparator";

interface ConversationContentListProps {
  className?: string;
  pages: (CursorPageData<ConversationContent> | null)[];
  inboxContact: InboxContactRead;
  isUpdatingCursor: boolean;
  isFetchingNextPage: boolean;
  isFetchingPreviousPage: boolean;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  hasFirstPageLoaded: boolean;
  focusedConversationContentId: string | null;
  onNextPage: () => void;
  onPreviousPage: () => void;
  onFocusMessage: (messageId: string) => Promise<void>;
  onFocusStartOfContent: (focused: boolean) => void;
  onSelectContextContent: (content: ConversationContent) => void;
  onSelectForwardMessage: (message: WhatsAppMessage) => void;
  onCloseConversationSearch: () => void;
  onMountConversationContent: HandleMountConversationContent;
}

interface GroupedByDateReturn {
  content: ConversationContent;
  showDetails: boolean;
}

// TODO: Use and refactor InfiniteScroll component to standardize their use on all components
function ConversationContentList({
  className,
  pages,
  inboxContact,
  isUpdatingCursor,
  isFetchingNextPage,
  isFetchingPreviousPage,
  hasNextPage,
  hasPreviousPage,
  hasFirstPageLoaded,
  focusedConversationContentId,
  onNextPage,
  onPreviousPage,
  onFocusMessage,
  onFocusStartOfContent,
  onSelectContextContent,
  onSelectForwardMessage,
  onCloseConversationSearch,
  onMountConversationContent,
}: ConversationContentListProps) {
  const [selectedMessage, setSelectedMessage] =
    useState<WhatsAppMessage | null>(null);

  const [allowReplyTo, unseenMessagesCount] = useMemo(() => {
    return [
      [
        ChannelProvider.META_CLOUD_API,
        ChannelProvider.D360_CLOUD_API,
        ChannelProvider.TECH_PROVIDER_CLOUD_API,
        // @ts-ignore
      ].includes(inboxContact.channel.channel_provider),
      (inboxContact.is_unread && inboxContact.unseen_messages) || 0,
    ];
  }, [inboxContact]);

  const groupedByDate = useMemo(
    () => [
      ...pages.reduce((nextGroupedByDate, page, currentPageIndex) => {
        for (const currentIndex in page?.results) {
          const { currentContent, prevContent } = getContentHistory(
            pages,
            currentPageIndex,
            +currentIndex
          );

          const currentTimestamp = getTimestampFromContent(currentContent);

          if (!currentTimestamp || !currentContent) {
            continue;
          }

          const prevTimestamp = getTimestampFromContent(prevContent);

          const showDetails = getShowDetails(
            currentContent,
            prevContent,
            currentTimestamp,
            prevTimestamp
          );

          const formattedDate = startOfDay(currentTimestamp).toISOString();
          const nextConversationContents =
            nextGroupedByDate.get(formattedDate) || [];

          nextConversationContents.push({
            content: currentContent,
            showDetails,
          });

          nextGroupedByDate.set(formattedDate, nextConversationContents);
        }

        return nextGroupedByDate;
      }, new Map<string, GroupedByDateReturn[]>()),
    ],
    [pages, isFetchingNextPage, isFetchingPreviousPage]
  );

  const handleGoToFirstPage = useCallback(() => {
    queryClient.resetQueries(["conversation_content", inboxContact.id]);
    onCloseConversationSearch();
  }, [inboxContact, onCloseConversationSearch]);

  if (isUpdatingCursor) {
    return <Loading />;
  }

  return (
    <>
      {selectedMessage && (
        <MessageMediaModal
          message={selectedMessage}
          onClose={() => setSelectedMessage(null)}
        />
      )}
      <ContentListScrollArea
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        hasFirstPageLoaded={hasFirstPageLoaded}
        isFetchingNextPage={isFetchingNextPage}
        isFetchingPreviousPage={isFetchingPreviousPage}
        unseenMessagesCount={unseenMessagesCount}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
        onGoToFirstPage={handleGoToFirstPage}
        onFocusStartOfContent={onFocusStartOfContent}
        className={className}
      >
        {groupedByDate.map(([date, currentConversationContents]) => (
          <div
            className="relative flex flex-col gap-2"
            key={`${date}_${currentConversationContents.length}`}
          >
            <ConversationContentItemSeparator date={date} />
            <div className="flex flex-col-reverse gap-2">
              {currentConversationContents.map(({ content, showDetails }) => (
                <ConversationContentItem
                  key={`${date}_${currentConversationContents.length}_${content.id}`}
                  inboxContactId={inboxContact.id}
                  allowReplyTo={allowReplyTo}
                  showDetails={showDetails}
                  content={content}
                  isFocused={content.id === focusedConversationContentId}
                  onSelectMedia={setSelectedMessage}
                  onFocusMessage={onFocusMessage}
                  onSelectContextContent={onSelectContextContent}
                  onSelectForwardMessage={onSelectForwardMessage}
                  onMountConversationContent={onMountConversationContent}
                />
              ))}
            </div>
          </div>
        ))}
      </ContentListScrollArea>
    </>
  );
}

export default ConversationContentList;
