import { useTranslation } from "react-i18next";
import SelectorField from "src/components/Form/SelectorField";
import {
  CAPIConversionEvents,
  CAPI_CONVERSION_EVENTS,
} from "../../../constants/steps/wa_capi";
import { useStepField } from "../../../hooks/useStepField";
import FlowAdvancedOptionsDisclosure from "../../FlowBuilderAdvancedOptionsDisclosure";
import FormatOptionLabel from "../../FormatOptionLabel";
import TextAreaFieldWithVariables from "../../TextAreaFieldWithVariables";
import TextInputFieldWithVariables from "../../TextInputFieldWithVariables";

interface FlowBuilderStepWhatsAppCAPIProps {
  id: string;
  index: number;
}

function FlowBuilderStepWhatsAppCAPI({
  id,
  index,
}: FlowBuilderStepWhatsAppCAPIProps) {
  const [t] = useTranslation();
  const [conversionEvent] = useStepField({
    index,
    name: "conversion_event",
  });

  const conversionEventOptions = Object.entries(CAPI_CONVERSION_EVENTS).map(
    ([type, step]) => ({
      icon: step.icon,
      label: t(step.label),
      help: t(step.help),
      value: type as CAPIConversionEvents,
    })
  );

  return (
    <div className="space-y-2">
      <SelectorField
        label={t(
          "flows:steps.wa-capi.conversion_event.label",
          "Conversion event to send"
        )}
        name={`steps.${index}.conversion_event`}
        options={conversionEventOptions}
        //@ts-ignore
        formatOptionLabel={FormatOptionLabel}
      />

      <div className="pt-4">
        {conversionEvent === "CUSTOM" && (
          <TextInputFieldWithVariables
            path={`steps.${index}`}
            name="custom_conversion_event_name"
            label={t(
              "flows:steps.wa-capi.custom_conversion_event_name.label",
              "Custom event name"
            )}
            placeholder={t(
              "flows:steps.wa-capi.custom_conversion_event_name.placeholder",
              "Custom event to send to Meta"
            )}
            type="text"
          />
        )}

        <FlowAdvancedOptionsDisclosure>
          <TextAreaFieldWithVariables
            path={`steps.${index}`}
            name="custom_data"
            label={t("flows:steps.wa-capi.custom_data.label", "Event data")}
            placeholder={t(
              "flows:steps.wa-capi.custom_data.placeholder",
              "Data to send along with the event (JSON)"
            )}
            help={t(
              "flows:steps.wa-capi.custom_data.help",
              <>
                See Meta's{" "}
                <a
                  href="https://developers.facebook.com/docs/meta-pixel/reference#standard-events"
                  target="_blank"
                  rel="noreferrer"
                >
                  default events
                </a>{" "}
                docs on how to send specific data to Meta.
              </>
            )}
            rows={8}
          />
        </FlowAdvancedOptionsDisclosure>
      </div>
    </div>
  );
}

export default FlowBuilderStepWhatsAppCAPI;
