import { useTranslation } from "react-i18next";
import { ReplyIcon } from "@heroicons/react/outline";
import { classNames } from "src/Helpers";

export default function ReplyTo({ active, content, onSelectContextContent }) {
  const { t } = useTranslation();

  return (
    <button
      className={classNames(
        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
        "flex w-full items-center px-4 py-2 text-sm"
      )}
      onClick={() => onSelectContextContent(content)}
    >
      <ReplyIcon className="w-3 h-3 mr-1 text-gray-700" strokeWidth={3} />
      {t("reply-to", "Reply to")}
    </button>
  );
}
