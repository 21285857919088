import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DropzoneInputProps } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";
import { QueryObserverResult } from "react-query";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ClipboardListIcon,
  EmojiHappyIcon,
  LightningBoltIcon,
  MicrophoneIcon,
  PaperClipIcon,
} from "@heroicons/react/outline";
import { differenceInMinutes, isValid } from "date-fns";
import { EmojiClickData } from "emoji-picker-react";
import { motion, useMotionValue } from "framer-motion";
import debounce from "lodash/debounce";
import { InboxContactRead } from "@hilos/types/private-schema";
import EmojiPicker from "src/components/EmojiPicker";
import ResizeIcon from "src/components/icons/ResizeIcon";
import {
  getConversationCloseDate,
  getScheduleMessageFromDate,
} from "src/helpers/inbox";
import useEmojiPopover from "src/hooks/useEmojiPopover";
import { UpdateInboxContactFn } from "src/hooks/useInboxContactDetails";
import { classNames } from "src/Helpers";
import BottomBarMessageIconButton from "./BottomBarMessageIconButton";
import BottomBarMessageQuickReplies, {
  BottomBarMessageQuickRepliesRef,
} from "./BottomBarMessageQuickReplies";
import ScheduledMessageMenu from "./ScheduledMessageMenu";

export type MessageMode =
  | "QUICK_REPLIES"
  | "SELECT_TEMPLATE"
  | "VOICE_RECORDING"
  | null;

export interface BottomBarMessageInputRef {
  focus: () => void;
  setMessage: (message: string) => void;
}

interface BottomBarMessageInputProps {
  inboxContact: InboxContactRead;
  currentMessageMode: MessageMode;
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
  setCurrentMessageMode: (nextMessageMode: MessageMode) => void;
  onScheduleMessage: () => Promise<QueryObserverResult<any, unknown>>;
  onSendCurrentMessage: (message: string) => void;
  onUpdateInboxContact: UpdateInboxContactFn;
}

const TAB_KEY_CODE = 9;
const RETURN_KEY_CODE = 13;
const SHIFT_KEY_CODE = 16;

const BottomBarMessageInput = forwardRef<
  BottomBarMessageInputRef,
  BottomBarMessageInputProps
>(
  (
    {
      inboxContact,
      currentMessageMode,
      getInputProps,
      setCurrentMessageMode,
      onScheduleMessage,
      onSendCurrentMessage,
      onUpdateInboxContact,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const quickRepliesRef = useRef<BottomBarMessageQuickRepliesRef>(null);
    const keyPressedRef = useRef(new Set());
    const [message, setMessage] = useState(inboxContact.draft_message || "");
    const [searchQuickReplies, setSearchQuickReplies] = useState<string | null>(
      null
    );
    const [submitting, setSubmitting] = useState(false);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const textAreaHeight = useMotionValue(50);
    const {
      open: isOpenEmojiPopover,
      isMounted,
      styles,
      refs,
      context,
      getReferenceProps,
      getFloatingProps,
    } = useEmojiPopover();

    const lastInboundMessageOn = useMemo(() => {
      if (!isValid(inboxContact.last_inbound_message_on)) {
        return new Date(inboxContact.last_inbound_message_on as string);
      }
      return null;
    }, [inboxContact]);

    const handleUpdateDraftMessage = useMemo(
      () =>
        debounce((value: string) => {
          onUpdateInboxContact({
            draft_message: value || "",
          });
        }, 350),
      [onUpdateInboxContact]
    );

    const timeRemaining = useMemo(() => {
      if (!lastInboundMessageOn) {
        return null;
      }

      const getTimeRemaining = (futureTime) => {
        const timeDiff = futureTime.getTime() - Date.now();
        const hours = Math.floor(timeDiff / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);

        if (timeDiff < 0) {
          return null;
        }
        return { hours, minutes };
      };
      const closeDate = getConversationCloseDate(lastInboundMessageOn as Date);

      return getTimeRemaining(closeDate);
    }, [lastInboundMessageOn]);

    const handleResizeTextArea = useCallback(
      (event, info) => {
        const nextTextAreaHeight = textAreaHeight.get() - info.delta.y;
        if (nextTextAreaHeight > 50 && nextTextAreaHeight < 300) {
          textAreaHeight.set(nextTextAreaHeight);
        }
      },
      [textAreaHeight]
    );

    // ? Maybe it is better to use a useEffect with a debounce to update the message when required.
    const handleSetMessage = useCallback(
      (nextMessage: string) => {
        setMessage(nextMessage);
        handleUpdateDraftMessage(nextMessage);
      },
      [handleUpdateDraftMessage]
    );

    const handleSend = useCallback(async () => {
      if (
        message.length > 0 &&
        textAreaRef.current &&
        !textAreaRef.current.disabled
      ) {
        textAreaRef.current.disabled = true;

        setSubmitting(true);
        onSendCurrentMessage(message);
        setSubmitting(false);
        handleSetMessage("");
        if (textAreaRef.current) {
          textAreaRef.current.disabled = false;
          textAreaRef.current.focus();
        }
      }
    }, [message, onSendCurrentMessage, handleSetMessage]);

    const handleKeyUp = useCallback((event) => {
      if ([SHIFT_KEY_CODE, RETURN_KEY_CODE].includes(event.keyCode)) {
        keyPressedRef.current.delete(event.keyCode);
      }

      if (event.keyCode === SHIFT_KEY_CODE) {
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
    }, []);

    const handleKeyDown = useCallback(
      (event) => {
        const keyCode = event.keyCode;
        if (TAB_KEY_CODE === keyCode && quickRepliesRef.current) {
          event.preventDefault();
          quickRepliesRef.current.selectNextQuickReply();
        } else if (keyCode === RETURN_KEY_CODE && quickRepliesRef.current) {
          event.preventDefault();
          quickRepliesRef.current.setSelectedQuickReplyMessage();
        } else if ([SHIFT_KEY_CODE, RETURN_KEY_CODE].includes(keyCode)) {
          keyPressedRef.current.add(keyCode);
          if (
            keyCode === RETURN_KEY_CODE &&
            !keyPressedRef.current.has(SHIFT_KEY_CODE)
          ) {
            handleSend();
            event.preventDefault();
            event.stopPropagation();
            return false;
          }
        }

        if (keyCode === SHIFT_KEY_CODE) {
          event.preventDefault();
          event.stopPropagation();
          return false;
        }
      },
      [handleSend]
    );

    const handleSelectEmoji = useCallback(
      (data: EmojiClickData) => {
        const prevMessage = message || "";
        let currentSelectionIndex: number | null = null;
        if (textAreaRef.current) {
          currentSelectionIndex = textAreaRef.current.selectionStart;
        }
        let nextMessage = "";

        if (currentSelectionIndex !== null) {
          nextMessage =
            prevMessage.slice(0, currentSelectionIndex) +
            data.emoji +
            prevMessage.slice(currentSelectionIndex);
        } else {
          nextMessage = prevMessage + data.emoji;
        }

        handleSetMessage(nextMessage);
      },
      [message, handleSetMessage]
    );

    const handleChangeMessage = useCallback(
      (event) => {
        if (!submitting) {
          const nextMessage = event.target.value || "";
          handleSetMessage(nextMessage);

          if (nextMessage[0] === "/") {
            setSearchQuickReplies(nextMessage.slice(1) || "");
          } else if (searchQuickReplies !== null) {
            setSearchQuickReplies(null);
          }
        }
      },
      [submitting, searchQuickReplies, handleSetMessage]
    );

    const handleSubmit = useCallback(
      (event) => {
        event.preventDefault();
        handleSend();
      },
      [handleSend]
    );

    const handleCloseQuickReplies = useCallback(() => {
      setSearchQuickReplies(null);
    }, []);

    const allowScheduleMessage = useMemo(() => {
      if (!lastInboundMessageOn) {
        return false;
      }
      const closeDate = getConversationCloseDate(lastInboundMessageOn);
      const fromDate = getScheduleMessageFromDate();

      if (closeDate < fromDate) {
        return false;
      }
      if (differenceInMinutes(closeDate, fromDate) < 31) {
        // Too little time left to schedule!
        return false;
      }

      return true;
    }, [lastInboundMessageOn]);

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (textAreaRef.current) {
          textAreaRef.current.focus();
        }
      },
      setMessage: (nextMessage) => {
        setSearchQuickReplies(null);
        handleSetMessage(nextMessage);
      },
    }));

    useLayoutEffect(() => {
      if (isOpenEmojiPopover && textAreaHeight.get() > 50) {
        textAreaHeight.set(50);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenEmojiPopover]);

    return (
      <>
        {searchQuickReplies !== null && (
          <BottomBarMessageQuickReplies
            ref={quickRepliesRef}
            search={searchQuickReplies}
            inboxContact={inboxContact}
            onClose={handleCloseQuickReplies}
            onSetMessage={handleSetMessage}
          />
        )}
        <form
          noValidate
          onSubmit={handleSubmit}
          className={classNames(
            "relative transition-all duration-300 opacity-100",
            currentMessageMode === "VOICE_RECORDING" && "hidden opacity-0"
          )}
        >
          <EmojiPicker
            isOpen={isOpenEmojiPopover}
            isMounted={isMounted}
            styles={styles}
            refs={refs}
            context={context}
            getFloatingProps={getFloatingProps}
            getReferenceProps={getReferenceProps}
            onSelectEmoji={handleSelectEmoji}
          />
          <div
            ref={refs.setPositionReference}
            className="overflow-hidden rounded-lg border border-gray-300 bg-white shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500"
          >
            <motion.div
              className="absolute flex w-full cursor-row-resize justify-end"
              drag="y"
              dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
              dragElastic={0}
              dragMomentum={false}
              onDrag={handleResizeTextArea}
            >
              <div className="mr-0.5 w-4 rounded-t-lg bg-white pt-1">
                <ResizeIcon className="h-3 w-3 fill-gray-600" />
              </div>
            </motion.div>
            <label htmlFor="message" className="sr-only">
              {t("add-your-message", "Add your message")}
            </label>
            <motion.textarea
              rows={2}
              name="message"
              id="message"
              style={{ height: textAreaHeight }}
              className={`block h-full w-full resize-none border-0 pb-3 focus:ring-0 sm:text-sm ${
                submitting ? "text-gray-400" : ""
              }`}
              placeholder={t("add-your-message", "Add your message")}
              value={message}
              ref={textAreaRef}
              onKeyUp={handleKeyUp}
              onKeyDown={handleKeyDown}
              onChange={handleChangeMessage}
              maxLength={4096}
            />

            {/* Spacer element to match the height of the toolbar */}
            <div className="h-12" />
          </div>

          <div className="absolute inset-x-0 bottom-0 flex justify-between pt-2 pr-2">
            <div className="flex flex-row items-center">
              <div className="flex w-1" />
              <button
                type="button"
                ref={refs.setReference}
                {...getReferenceProps()}
                className={classNames(
                  "flex h-8 w-8 items-center justify-center rounded-full text-gray-400 hover:text-gray-500",
                  isOpenEmojiPopover && "text-indigo-500 hover:text-indigo-500"
                )}
              >
                <EmojiHappyIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <label
                htmlFor="message-file-upload"
                className="flex h-8 w-8 items-center justify-center rounded-full text-gray-400 hover:text-gray-500 cursor-pointer"
              >
                <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                <span className="sr-only">
                  {t("attach-a-file", "Attach a file")}
                </span>
              </label>
              <input hidden id="message-file-upload" {...getInputProps()} />
              <BottomBarMessageIconButton
                onClick={() => setCurrentMessageMode("QUICK_REPLIES")}
              >
                <LightningBoltIcon className="h-5 w-5" aria-hidden="true" />
                <span className="sr-only">
                  {t("inbox:open-quick-replies", "Open quick replies")}
                </span>
              </BottomBarMessageIconButton>
              <BottomBarMessageIconButton
                onClick={() => setCurrentMessageMode("SELECT_TEMPLATE")}
              >
                <ClipboardListIcon className="h-5 w-5" aria-hidden="true" />
                <span className="sr-only">
                  {t("inbox:open-quick-replies", "Open quick replies")}
                </span>
              </BottomBarMessageIconButton>
              <BottomBarMessageIconButton
                onClick={() => setCurrentMessageMode("VOICE_RECORDING")}
              >
                <MicrophoneIcon className="h-5 w-5" aria-hidden="true" />
                <span className="sr-only">
                  {t("inbox:record-audio-message", "Record voice message")}
                </span>
              </BottomBarMessageIconButton>
            </div>
            <div className="flex shrink-0 items-center pb-2">
              <div
                className={classNames(
                  "mr-2 text-xs",
                  message.length > 4000 ? "text-red-400" : "text-gray-400"
                )}
              >
                {message.length}/4096
              </div>
              <div
                className={classNames(
                  "relative flex items-center rounded-md border border-transparent  text-sm font-medium text-white shadow-sm",
                  submitting ? "bg-indigo-400" : "bg-indigo-600"
                )}
              >
                <button
                  type="submit"
                  disabled={submitting}
                  className="inline-flex h-full items-center py-2 px-4 text-sm font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  {t("send", "Send")}
                  <FontAwesomeIcon
                    // @ts-ignore
                    icon={faPaperPlane}
                    className="ml-1"
                  />
                </button>
                {message.length > 0 && allowScheduleMessage && (
                  <div className="relative h-full w-full border-l  border-gray-400">
                    <ScheduledMessageMenu
                      text={message}
                      inboxContactId={inboxContact.id}
                      lastInboundMessageOn={lastInboundMessageOn as Date}
                      onSetMessage={handleSetMessage}
                      onScheduleMessage={onScheduleMessage}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
        <div
          className={classNames(
            "flex justify-between gap-9 py-1.5",
            currentMessageMode === "VOICE_RECORDING" && "hidden"
          )}
        >
          <p className="items-center text-xs justify-start text-gray-500">
            {timeRemaining !== null ? (
              <>
                {t("inbox:conversation-closes-in", "Conversation closes in")}
                <span className="font-bold">{` ${timeRemaining.hours}h ${timeRemaining.minutes}min`}</span>
              </>
            ) : (
              <>{t("inbox:conversation-closed", "Conversation is closed")}</>
            )}
          </p>
          <p className="items-center text-xs text-gray-500">
            <Trans i18nKey="inbox:add-a-new-line">
              <span className="font-bold mr-1">Shift + Return </span>to add a
              new line
            </Trans>
          </p>
        </div>
      </>
    );
  }
);

export default BottomBarMessageInput;
