import { useTranslation } from "react-i18next";
import { ChatAlt2Icon } from "@heroicons/react/outline";

function WithoutConversation() {
  const { t } = useTranslation();
  return (
    <section className="hidden grow md:block">
      <div className="flex h-full w-full flex-col items-center justify-center bg-gray-100 text-gray-500">
        <ChatAlt2Icon className="mx-auto mb-2 h-12 w-12 text-gray-400" />
        <p className="text-center">
          {t(
            "inbox:conversations.select-conversation",
            "Select a conversation to start chatting."
          )}
        </p>
      </div>
    </section>
  );
}

export default WithoutConversation;
