import { useCallback } from "react";
import { useMutation } from "react-query";
import axios from "axios";
import { v4 as uuid } from "uuid";
import {
  ConversationContent,
  CreateWhatsAppMessage,
  WhatsAppMessage,
} from "@hilos/types/private-schema";
import { queryClient } from "src/HilosProvider";
import { updateConversationContentData } from "../helpers/conversation";
import { API_ROUTES } from "../router/router";

export type SendMessageFn = (
  data: CreateWhatsAppMessage
) => Promise<WhatsAppMessage>;

const createConversationMessage =
  (inboxContactId: string) => async (params) => {
    const { data } = await axios.post(
      API_ROUTES.CONVERSATION_MESSAGE_CREATE.replace(":id", inboxContactId),
      params
    );
    return data;
  };

function useConversationMessage(inboxContactId: string) {
  const {
    mutateAsync: createConversationMessageMutation,
    isLoading: isSubmitting,
  } = useMutation({
    mutationFn: createConversationMessage(inboxContactId),
    mutationKey: ["conversation_message_mutation", inboxContactId],
    onMutate: async (newMessage: CreateWhatsAppMessage) => {
      await queryClient.cancelQueries({
        queryKey: ["conversation_content", inboxContactId],
      });

      const timestamp = new Date().toISOString();
      const newConversationContent = {
        id: uuid(),
        timestamp,
        submitting: true,
        content_type: "MESSAGE",
        event: null,
        message: {
          ...newMessage,
          timestamp,
          direction: "OUTBOUND",
        },
      } as unknown as ConversationContent;

      queryClient.setQueryData(
        ["conversation_content", inboxContactId],
        updateConversationContentData(newConversationContent, false)
      );

      return { newConversationContent };
    },
    onError: (error, newConversationContent, context) => {
      queryClient.invalidateQueries({
        queryKey: ["conversation_content", inboxContactId],
      });
    },
  });

  const handleSendMessage = useCallback<SendMessageFn>(
    (nextMessage) => createConversationMessageMutation(nextMessage),
    [createConversationMessageMutation]
  );

  return {
    isSubmitting,
    handleSendMessage,
  };
}

export default useConversationMessage;
