import { useMemo } from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { DocumentTextIcon, TrashIcon } from "@heroicons/react/outline";
import { UploadIcon, UserIcon } from "@heroicons/react/outline";
import axios from "axios";
import FieldContainer from "src/components/Form/FieldContainer";
import { DropzoneStyles } from "src/components/base/Form";
import HeaderNavigation from "../../components/HeaderNavigation";
import StateButton from "../../components/StateButton";
import APIErrors from "../../components/base/APIErrors";
import { API_ROUTES, buildAPIRoute, buildRoute } from "../../router/router";
import ContactCSVTemplateDownload from "./ContactCSVTemplateDownload";
import * as meta from "./ContactMeta";

export default function ContactUploadCSV() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});
  const [contacts, setContacts] = useState({
    file: undefined,
    url: undefined,
    errors: [],
  });
  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone({
      accept: "text/csv",
      onDrop: (acceptedFiles, fileRejections) => {
        const errors = [];
        fileRejections.forEach((file) => {
          errors.push(
            `${file.file.name} ${t(
              "errors.not-a-csv",
              "is not a valid .csv file."
            )}`
          );
        });

        const nextFiles = acceptedFiles.map((file) => {
          if (file.size > 1024 * 20000) {
            errors.push(
              t(
                "errors.csv-size-limit",
                "Please upload a smaller csv, no larger than 20MB."
              )
            );
          }

          const reader = new FileReader();
          reader.onload = (e) => {
            setContacts({
              file: file,
              url: e.target.result,
              errors: errors,
            });
          };

          reader.readAsDataURL(file);
          return {
            source: file,
            preview: URL.createObjectURL(file),
          };
        });

        setContacts((prevState) => ({
          ...prevState,
          errors: errors,
        }));

        if (nextFiles.length > 0) {
          setContacts(nextFiles[0]);
        }
      },
    });

  const handleCleanFile = () =>
    setContacts({
      file: undefined,
      url: undefined,
      errors: [],
    });

  const style = useMemo(
    () => ({
      ...DropzoneStyles.base,
      ...(isDragActive ? DropzoneStyles.activeStyle : {}),
      ...(isDragAccept ? DropzoneStyles.acceptStyle : {}),
    }),
    [isDragActive, isDragAccept]
  );

  const formSubmit = async (ev) => {
    ev.preventDefault();

    const formData = new FormData();
    formData.append("contacts", contacts.file);

    setBackendValidationErrors({});
    setBackendError("");
    setSubmitting(true);
    try {
      const response = await axios.post(
        buildAPIRoute(API_ROUTES.CONTACT_UPLOAD_CSV),
        formData
      );
      setSubmitting(false);
      setSuccess(true);
      setTimeout(() => {
        navigate(
          buildRoute("contact-import-detail", {
            id: response.data.upload_group_id,
          })
        );
      }, 1000 * 2);
    } catch (err) {
      setSuccess(false);
      if (err.response.status === 400) {
        console.log("error", err);
        setBackendValidationErrors(err.response.data);
      } else {
        setBackendError(
          t(
            "errors.csv-error",
            "An error occurred while uploading your csv. Please try again."
          )
        );
      }
      setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setSubmitted(true);
    }
  };

  return (
    <div className="h-screen overflow-y-auto bg-slate-50">
      {/* Header */}
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("contacts"),
              url: buildRoute("contact-list"),
            },
            {
              name: t("contacts:upload-csv", "Upload contacts csv"),
              url: buildRoute("contact-upload-csv"),
            },
          ]}
        />

        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <UserIcon className="mr-2 h-7 w-7" aria-hidden="true" />
              {t("contacts:upload-contacts", "Upload contacts")}
            </h2>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-4xl md:mt-6">
          <div className="grid grid-cols-1 text-sm text-gray-800 md:gap-4">
            <div>
              <Trans i18nKey="contacts:csv-explanation">
                <h6 className="text-tiny uppercase tracking-wider text-gray-400">
                  CSV Upload: how it works
                </h6>
                <p className="mt-4">
                  Make sure you upload a .csv file with a first row of headers,
                  with at least one column with the value{" "}
                  <code className="font-mono font-medium text-indigo-500">
                    phone
                  </code>
                  . Phones must be in{" "}
                  <a
                    href="https://www.twilio.com/docs/glossary/what-e164"
                    className="font-medium text-blue-500"
                    target="_blank"
                    rel="noreferrer"
                  >
                    E.164 format
                  </a>
                  .
                </p>
                <p className="mt-2">
                  To set the contacts' first name, last name, email and external
                  url, add columns with the following headers:{" "}
                  <code className="font-mono font-medium text-indigo-500">
                    first_name
                  </code>
                  ,{" "}
                  <code className="font-mono font-medium text-indigo-500">
                    last_name
                  </code>
                  ,{" "}
                  <code className="font-mono font-medium text-indigo-500">
                    email
                  </code>
                  ,{" "}
                  <code className="font-mono font-medium text-indigo-500">
                    external_url
                  </code>
                  . All other columns will be saved as additional attributes.
                </p>
                <div className="mt-4">
                  <p>An example of how the recipients .csv file should look:</p>
                  <div className="mt-2 overflow-scroll font-mono text-gray-500">
                    <table className="table">
                      <thead>
                        <tr className="border-b-2 border-gray-200 text-left">
                          <th className="py-2 pr-3">phone,</th>
                          <th className="px-3 py-2">first_name,</th>
                          <th className="px-3 py-2">last_name,</th>
                          <th className="px-3 py-2">email,</th>
                          <th className="px-3 py-2">external_url,</th>
                          <th className="px-3 py-2">suscribed,</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border-b border-gray-100">
                          <td className="py-2 pr-3">+525576565089,</td>
                          <td className="px-3 py-2">Alfonso,</td>
                          <td className="px-3 py-2">,</td>
                          <td className="px-3 py-2">alfonso@hilos.com,</td>
                          <td className="px-3 py-2">
                            https://hilos.io/profile/alfonso,
                          </td>
                          <td className="px-3 py-2">true</td>
                        </tr>
                        <tr className="border-b border-gray-100">
                          <td className="py-2 pr-3">+525576565060,</td>
                          <td className="px-3 py-2">Ilyich,</td>
                          <td className="px-3 py-2">Lenin,</td>
                          <td className="px-3 py-2">,</td>
                          <td className="px-3 py-2">
                            https://hilos.io/profile/ilyich,
                          </td>
                          <td className="px-3 py-2">false</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Trans>
            </div>

            <div>
              <form
                noValidate
                onSubmit={(ev) => formSubmit(ev)}
                className="mt-6 px-0 md:mt-0 md:px-4"
              >
                <div className="my-4">
                  <APIErrors
                    APIError={backendError}
                    APIValidationErrors={backendValidationErrors}
                    fieldTranslations={meta.CSV_FIELDS}
                  ></APIErrors>
                </div>

                <label htmlFor="contacts" className="block">
                  {t(
                    "contacts:upload-contacts-csv",
                    "Upload a CSV to create contacts in bulk"
                  )}
                </label>

                <ContactCSVTemplateDownload />

                {contacts.file ? (
                  <div className="flex items-stretch rounded-lg border border-gray-200 p-3 shadow-sm">
                    <div className="grow self-center text-sm">
                      {contacts.file.name}
                    </div>
                    <div className="self-center text-right">
                      <button
                        className="inline-flex items-center rounded-md border border-red-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-600 shadow-sm hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        type="button"
                        onClick={handleCleanFile}
                      >
                        <TrashIcon
                          className="mr-2 h-4 w-4"
                          aria-hidden="true"
                        />
                        {t("remove")}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    {...getRootProps({ className: "dropzone", style: style })}
                    className="flex-1 flex flex-col items-center p-5 border-2 border-dashed border-gray-200 rounded-md bg-white text-gray-900 outline-none transition duration-200 ease-in-out"
                  >
                    <input {...getInputProps()} />
                    <p className="mb-0 flex items-center pb-0">
                      <DocumentTextIcon
                        className="mr-2 h-4 w-4"
                        aria-hidden="true"
                      />{" "}
                      {t(
                        "csv-drop",
                        "Click here to select a .csv or drag & drop it."
                      )}
                    </p>
                  </div>
                )}

                {contacts.url && (
                  <p className="mt-2">
                    {contacts.errors.map((e) => (
                      <span className="font-xs text-red-400" key={e}>
                        *{e}
                      </span>
                    ))}
                  </p>
                )}

                <div className="mt-5">
                  <div className="text-right">
                    <StateButton
                      isSubmitting={submitting}
                      submitted={submitted}
                      success={success}
                      disabled={contacts.errors && contacts.errors.length > 0}
                      submittingText="Uploading CSV..."
                      successText="Contacts created!"
                      initialText={
                        <>
                          {t("upload-csv", "Upload CSV")}
                          <UploadIcon
                            className="ml-1 h-5 w-5"
                            aria-hidden="true"
                          />
                        </>
                      }
                    ></StateButton>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
