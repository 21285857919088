"use client";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ChevronLeft, ChevronRight, FlaskConical } from "lucide-react";
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "src/components/ui/sidebar";
import useHilosStore from "src/hooks/useHilosStore";
import { buildRoute } from "src/router/router";

export function NavFooter() {
  const { session } = useHilosStore();
  const { t } = useTranslation();

  const { toggleSidebar, isMobile } = useSidebar();

  if (!session) {
    return null;
  }

  return (
    <SidebarMenu>
      {session.account.status === "IN_SANDBOX" && (
        <SidebarMenuItem>
          <SidebarMenuButton
            asChild
            className="h-auto max-h-40 justify-center items-start hover:bg-blue-50 active:bg-sky-50"
          >
            <div className="rounded-md bg-blue-50 p-4 flex flex-col justify-between">
              <div className="flex flex-row gap-2">
                <FlaskConical
                  aria-hidden="true"
                  className="shrink-0 self-center size-4 text-blue-800"
                />
                <p className="group-data-[collapsible=icon]:hidden font-semibold text-blue-800">
                  {t("navigation:sandbox.notice.title", "Sandbox mode")}
                </p>
              </div>
              <p className="group-data-[collapsible=icon]:hidden text-sm text-blue-700">
                {t(
                  "navigation:sandbox.notice.description",
                  "When you're done testing Hilos, connect your company's number here"
                )}
              </p>
              <p className="group-data-[collapsible=icon]:hidden mt-2 text-sm">
                <Link
                  to={buildRoute("config-channel-cloud-api-onboarding")}
                  className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                >
                  {t(
                    "navigation:sandbox.notice.connect-number",
                    "Connect number"
                  )}
                  <span aria-hidden="true"> &rarr;</span>
                </Link>
              </p>
            </div>
          </SidebarMenuButton>
        </SidebarMenuItem>
      )}
      <SidebarMenuItem>
        <SidebarMenuButton
          size="default"
          className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
          onClick={toggleSidebar}
        >
          <div className={"flex shrink-0 w-5 h-5 items-center justify-center"}>
            {isMobile ? (
              <ChevronRight />
            ) : (
              <ChevronLeft className="size-5 transition-transform duration-200 group-data-[collapsible=icon]:rotate-180" />
            )}
          </div>
          <div className="grid flex-1 text-left text-sm leading-tight">
            <span className="truncate">{t("collapse", "Collapse")}</span>
          </div>
        </SidebarMenuButton>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
