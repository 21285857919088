import { useCallback, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { useFormikContext } from "formik";
import { FlowData } from "@hilos/types/flow";
import SelectorField from "src/components/Form/SelectorField";
import { getUpdatedVariablesWithExtraSources } from "src/helpers/variables";
import { TriggerCategoryProps } from "../FlowBuilderTrigger";
import FlowAdvancedOptionsDisclosure from "../components/FlowBuilderAdvancedOptionsDisclosure";
import FlowTriggerMultipleContactExecutionAllowedForm from "../components/FlowTriggerMultipleContactExecutionAllowedForm";
import { INTEGRATION_TYPE_TRIGGER } from "../constants/triggers";
import TriggerCategorySelector from "./TriggerCategorySelector";

function IntegrationsTrigger({
  handleTriggerTypeChange,
}: TriggerCategoryProps) {
  const [t] = useTranslation();
  const { values, setFieldValue } = useFormikContext<FlowData>();

  const availableFlowVariables = useMemo(
    () =>
      values.variables.reduce((nextAvailableFlowVariables, variable) => {
        if (variable.source === "flow") {
          const name = variable.name.replace(
            /^(flow|flow_execution_variable)\./,
            ""
          );
          nextAvailableFlowVariables.push({
            value: name,
            label: name,
          });
        }

        return nextAvailableFlowVariables;
      }, [] as { value: string; label: string }[]),
    [values.variables]
  );

  const handleFlowVariableChange = useCallback(
    (nextValues) => {
      const flowVariableKeys = (nextValues || []).map(({ value }) => value);
      setFieldValue(
        "variables",
        getUpdatedVariablesWithExtraSources({
          flowVariableKeys,
          currentVariables: values.variables,
        })
      );
    },
    [values.variables, setFieldValue]
  );

  return (
    <>
      <div className="pb-4">
        <h2 className="text-lg font-medium text-gray-900">
          {t(
            "flows:trigger.integrations-trigger.title",
            "Start your flow from an external app"
          )}
        </h2>
      </div>

      <div className="space-y-4">
        <TriggerCategorySelector
          handleTriggerTypeChange={handleTriggerTypeChange}
          triggersForCategory={INTEGRATION_TYPE_TRIGGER}
        />

        {["INTEGRATIONS_HUBSPOT", "INTEGRATIONS_ZAPIER"].includes(
          values.trigger_type
        ) && (
          <>
            <div className="border-l-4 border-blue-400 bg-blue-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-blue-400"
                    aria-hidden="true"
                  />
                </div>
                <Trans i18nKey="flows:trigger.integrations-trigger.external.notice">
                  <div className="ml-2 space-y-2">
                    <p className="text-sm text-blue-700">
                      If you start to see multiple flows being triggered by your
                      integration, set a limit of how many times a contact can
                      receive this flow under{" "}
                      <strong className="font-bold italic">
                        Advanced options
                      </strong>
                      .
                    </p>
                    <p className="text-sm text-blue-700">
                      This way even if the flow is triggered multiple times for
                      the same contact, they won't be sent this flow more than X
                      times.
                    </p>
                  </div>
                </Trans>
              </div>
            </div>
          </>
        )}

        {values.trigger_type === "OUTBOUND_CAMPAIGN_API" && (
          <>
            <div className="border-l-4 border-blue-400 bg-blue-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-blue-400"
                    aria-hidden="true"
                  />
                </div>
                <Trans i18nKey="flows:trigger.integrations-trigger.api.notice">
                  <div className="ml-2 space-y-2">
                    <p className="text-sm text-blue-700">
                      First, create and publish your flow.
                    </p>
                    <p className="text-sm text-blue-700">
                      Once published you'll be able to send it to as many
                      contacts as you want by using our{" "}
                      <a
                        href="https://hilos.io/docs/apidocs/flow-execution/post-apiflow-run"
                        target="_blank"
                        rel="noreferrer"
                        className="font-bold underline"
                      >
                        Flow Execution
                      </a>{" "}
                      endpoint.
                    </p>
                  </div>
                </Trans>
              </div>
            </div>
          </>
        )}

        <FlowAdvancedOptionsDisclosure
          defaultOpen={
            values.flow_execution_variables &&
            values.flow_execution_variables.length > 0
          }
        >
          {["OUTBOUND_CAMPAIGN_API", "OUTBOUND_ANY"].includes(
            values.trigger_type
          ) && (
            <SelectorField
              isMulti
              isCreatable
              name="flow_execution_variables"
              label={t(
                "flows:flow-execution-variables.label",
                "What execution variables will this flow have?"
              )}
              help={t(
                "flows:flow-execution-variables.help",
                "The variables declared here will be available in the flow steps to use and you will provide them when executing this flow."
              )}
              options={availableFlowVariables}
              onSelect={handleFlowVariableChange}
            />
          )}

          <FlowTriggerMultipleContactExecutionAllowedForm />
        </FlowAdvancedOptionsDisclosure>
      </div>
    </>
  );
}

export default IntegrationsTrigger;
