import { Outlet, useMatch, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { classNames } from "src/Helpers";
import WorkflowBoardTaskConversation from "./WorkflowBoardTaskConversation";

export default function WorkflowBoardTask() {
  const isTaskDetailsVisible = useMatch(
    "/workflow/:workflowId/board/:stateId/task/:taskId/details"
  );
  const { taskId, workflowId } = useParams() as {
    taskId: string;
    workflowId: string;
  };

  if (!taskId || !workflowId) {
    return null;
  }

  return (
    <div className="sticky w-full h-full">
      <div className="flex w-full h-full bg-gray-100 overflow-hidden">
        <motion.div
          initial={{ opacity: 0, width: 0 }}
          animate={{ opacity: 1, width: "auto" }}
          exit={{
            opacity: 0,
            width: 0,
          }}
          transition={{
            opacity: { duration: 0.2 },
            width: { duration: 0.4 },
            layout: { duration: 0.3 },
          }}
          layout
          className={classNames(
            "flex-1 overflow-hidden",
            isTaskDetailsVisible ? "hidden lg:flex" : "flex"
          )}
        >
          <WorkflowBoardTaskConversation
            taskId={taskId}
            workflowId={workflowId}
          />
        </motion.div>
        <Outlet />
      </div>
    </div>
  );
}
