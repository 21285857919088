import { Fragment, forwardRef, memo, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { attachClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { preserveOffsetOnSource } from "@atlaskit/pragmatic-drag-and-drop/element/preserve-offset-on-source";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import { dropTargetForExternal } from "@atlaskit/pragmatic-drag-and-drop/external/adapter";
import invariant from "tiny-invariant";
import { WorkflowTaskRead } from "@hilos/types/private-schema";
import { ChannelBadgeById } from "src/containers/config/channel/ChannelBadge";
import { UserAvatarById } from "src/containers/inbox/UserAvatar";
import { classNames, contactToString } from "src/Helpers";
import {
  WorkflowTaskItem,
  useWorkflowBoardContext,
} from "../WorkflowBoardContext";
import { SelectedWorkflowTask } from "../WorkflowBoardData";

type CardState =
  | { type: "idle" }
  | { type: "preview"; container: HTMLElement; rect: DOMRect }
  | { type: "dragging" };

const idleState: CardState = { type: "idle" };
const draggingState: CardState = { type: "dragging" };

type CardPrimitiveProps = {
  item: WorkflowTaskItem;
  state: CardState;
  selected: boolean;
  onClickCard?: (task: SelectedWorkflowTask | null) => void;
};

const CardPrimitive = forwardRef<HTMLDivElement, CardPrimitiveProps>(
  function CardPrimitive({ item, state, selected, onClickCard }, ref) {
    const [t] = useTranslation();
    return (
      <div
        ref={ref}
        onClick={() =>
          onClickCard &&
          onClickCard(
            selected
              ? null
              : {
                  id: item.id,
                  state: item.state,
                }
          )
        }
        className={classNames(
          "grid grid-cols-1 gap-x-2 items-center",
          "w-full p-2 bg-white rounded-md relative hover:bg-gray-200 h-20 group",
          selected && "bg-blue-200",
          item.updating && "bg-gray-200",
          {
            idle: "cursor-grab shadow-md",
            dragging: "opacity-40 shadow-md",
            preview: undefined,
          }[state.type]
        )}
      >
        <div className="flex flex-row w-full justify-between">
          <div className="flex flex-row space-x-1 items-center w-full">
            <span className="truncate text-xs font-semibold text-gray-400">
              {item.number && `#${item.number} · `}
              {t("{{ date, PPp }}", {
                date: new Date(item.last_updated_on),
              })}
            </span>
            {item.inbox_contact.is_unread && (
              <div className="w-1.5 h-1.5 rounded-full bg-indigo-700" />
            )}
          </div>
        </div>
        <div className="grow">
          <div className="mx-auto text-md truncate">
            {contactToString(item.inbox_contact.contact)}
          </div>
        </div>
        <div className="flex flex-row w-full grow-0 justify-between">
          <div className="shrink grow-0">
            <ChannelBadgeById id={`${item.inbox_contact.channel}`} />
          </div>
          <div className="flex shrink-0 -space-x-1 overflow-hidden pl-1 justify-center items-center">
            {item.assigned_users?.map((userId) => (
              <UserAvatarById
                key={userId}
                id={userId}
                className="h-5 w-5 border border-white group-hover:border-gray-200 text-[10px] font-semibold tracking-tighter"
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
);

export const Card = memo(function Card({
  item,
  selected,
  onClickCard,
}: {
  item: WorkflowTaskRead;
  selected: boolean;
  onClickCard: (task: SelectedWorkflowTask | null) => void;
}) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [state, setState] = useState<CardState>(idleState);

  const instanceId = useWorkflowBoardContext((state) => state.instanceId);
  const registerCard = useWorkflowBoardContext((state) => state.registerCard);

  useEffect(() => {
    invariant(ref.current);
    return registerCard({
      cardId: item.id,
      entry: {
        element: ref.current,
      },
    });
  }, [registerCard, item]);

  useEffect(() => {
    const element = ref.current;
    invariant(element);
    return combine(
      draggable({
        element: element,
        getInitialData: () => ({
          type: "card",
          itemId: item.id,
          state: item.state,
          instanceId,
        }),
        onGenerateDragPreview: ({ location, source, nativeSetDragImage }) => {
          const rect = source.element.getBoundingClientRect();

          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: preserveOffsetOnSource({
              element,
              input: location.current.input,
            }),
            render({ container }) {
              setState({ type: "preview", container, rect });
              return () => setState(draggingState);
            },
          });
        },

        onDragStart: () => setState(draggingState),
        onDrop: () => setState(idleState),
      }),
      dropTargetForExternal({
        element: element,
      }),
      dropTargetForElements({
        element: element,
        canDrop: ({ source }) => {
          return (
            source.data.instanceId === instanceId &&
            source.data.type === "card" &&
            source.data.state !== item.state
          );
        },
        getIsSticky: () => true,
        getData: ({ input, element }) => {
          const data = { type: "card", itemId: item.id, state: item.state };

          return attachClosestEdge(data, {
            input,
            element,
            allowedEdges: ["top", "bottom"],
          });
        },
      })
    );
  }, [instanceId, item]);

  return (
    <Fragment>
      <CardPrimitive
        ref={ref}
        item={item}
        state={state}
        selected={selected}
        onClickCard={onClickCard}
      />
      {state.type === "preview" &&
        ReactDOM.createPortal(
          <div
            style={{
              /**
               * Ensuring the preview has the same dimensions as the original.
               *
               * Using `border-box` sizing here is not necessary in this
               * specific example, but it is safer to include generally.
               */
              boxSizing: "border-box",
              width: state.rect.width,
              height: state.rect.height,
            }}
          >
            <CardPrimitive item={item} state={state} selected={selected} />
          </div>,
          state.container
        )}
    </Fragment>
  );
});
