import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { faBell, faBellSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArchiveIcon, ExternalLinkIcon } from "@heroicons/react/outline";
import { ContactListRead } from "@hilos/types/private-schema";
import CopyPhoneToClipboardButton from "src/components/Form/CopyPhoneToClipboardButton";
import { classNames, contactToString, formatPhone } from "../../../Helpers";
import { getDistanceFromNow } from "../../../helpers/date";
import { buildRoute } from "../../../router/router";
import ConversationStatusIndicator from "../ConversationStatusIndicator";
import { getConnectionColor } from "../Helpers";

interface ConversationDetailsHeaderProps {
  contact: ContactListRead;
  silenced: boolean;
  archived: boolean;
  lastInboundMessageOn: string | null;
  onSwitchSilenced?: () => void;
  onSwitchArchived?: () => void;
}

function ConversationDetailsHeader({
  contact,
  silenced,
  archived,
  lastInboundMessageOn,
  onSwitchSilenced,
  onSwitchArchived,
}: ConversationDetailsHeaderProps) {
  const { t } = useTranslation();

  const statusColor = useMemo(
    () => getConnectionColor(lastInboundMessageOn),
    [lastInboundMessageOn]
  );

  return (
    <div className="flex-rows mr-2 items-center text-center justify-center font-medium text-gray-800">
      {!contact.first_name && !contact.last_name ? (
        <div className="flex items-center justify-center">
          <span className="ml-2">
            <CopyPhoneToClipboardButton
              value={contact.canonical_phone as string}
              text={formatPhone(contact.phone, true, true)}
            />
          </span>
          <ConversationStatusIndicator color={statusColor} />
        </div>
      ) : (
        <>
          <div className="flex items-center justify-center">
            <span className="ml-2">{contactToString(contact)}</span>
            <ConversationStatusIndicator color={statusColor} />
          </div>
          <div className="text-sm text-gray-500 ml-6">
            {/* {formatPhone(inboxContact.contact.phone, true, true)} */}
            <CopyPhoneToClipboardButton
              value={contact.canonical_phone as string}
              text={formatPhone(contact.phone, true, true)}
            />
          </div>
        </>
      )}

      {lastInboundMessageOn && statusColor !== "green" && (
        <div className="text-xs text-gray-400">
          ({getDistanceFromNow(lastInboundMessageOn)})
        </div>
      )}

      {archived && (
        <span className="ml-1 mb-1 text-xs text-gray-400">(Archived)</span>
      )}

      <Link
        className="flex items-center justify-center text-sm text-blue-500"
        to={buildRoute("contact-update", {
          id: contact.id,
        })}
        target="_blank"
        rel="noreferrer"
      >
        <ExternalLinkIcon className="mr-1 h-4 w-4" />
        <span>{t("update-contact", "Update contact")}</span>
      </Link>
      {!!contact.external_url && (
        <a
          className="flex items-center justify-center text-sm text-blue-500"
          href={contact.external_url}
          target="_blank"
          rel="noreferrer"
        >
          <ExternalLinkIcon className="mr-1 h-4 w-4" />
          <span>{t("see-in-crm", "See in CRM")}</span>
        </a>
      )}

      {onSwitchSilenced && (
        <button
          type="button"
          className="hidden h-8 w-8 items-center justify-center rounded-full text-gray-500 transition duration-150 ease-in-out hover:bg-gray-300 focus:outline-none sm:inline-flex"
          onClick={onSwitchSilenced}
        >
          <FontAwesomeIcon
            icon={silenced ? faBellSlash : faBell}
            className="h-5 w-5"
          />
        </button>
      )}
      {onSwitchArchived && (
        <button
          type="button"
          className="hidden h-8 w-8 items-center justify-center rounded-full text-gray-500 transition duration-150 ease-in-out hover:bg-gray-300 focus:outline-none sm:inline-flex"
          onClick={onSwitchArchived}
        >
          <ArchiveIcon
            className={classNames("h-5 w-5", archived && "text-gray-800")}
          />
        </button>
      )}
    </div>
  );
}

export default ConversationDetailsHeader;
