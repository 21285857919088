import { Link } from "react-router-dom";
import { ChevronLeft, MoreVertical, Search } from "lucide-react";
import { Button } from "src/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import useWorkflowTaskDetails from "src/hooks/useWorkflowTaskDetails";
import { contactToString, formatPhone } from "src/Helpers";
import { buildRoute } from "src/router/router";
import SelectStatus from "./SelectStatus";

interface WorkflowBoardTaskProps {
  taskId: string;
  workflowId: string;
}

export default function WorkflowBoardTaskHeader({
  taskId,
  workflowId,
}: WorkflowBoardTaskProps) {
  const { task, handleUpdateWorkflowTask } = useWorkflowTaskDetails({
    taskId,
    workflowId,
  });

  if (!task) {
    return null;
  }

  return (
    <div className="flex items-center p-2 bg-white border-b border-gray-200">
      <Button variant="ghost" size="icon" className="px-1 py-1">
        <Link to={buildRoute("workflow-board", { id: workflowId })}>
          <ChevronLeft className="h-4 w-4" />
        </Link>
      </Button>
      <Button variant="ghost" className="pl-1" asChild>
        <Link to="details">
          <div className="grid flex-1 text-left text-md leading-tight">
            <span className="truncate font-semibold">
              {contactToString(task.inbox_contact.contact)}
            </span>
            <span className="truncate text-xs">
              {formatPhone(task.inbox_contact.contact.phone, true, true)}
            </span>
          </div>
        </Link>
      </Button>
      <div className="ml-auto flex items-center gap-2">
        <SelectStatus
          currentStateId={task.state}
          onUpdateWorkflowTask={handleUpdateWorkflowTask}
        />
        <Button variant="ghost" size="icon">
          <Search className="h-4 w-4" />
          <span className="sr-only">Search</span>
        </Button>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon">
              <MoreVertical className="h-4 w-4" />
              <span className="sr-only">More</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem>Mute</DropdownMenuItem>
            <DropdownMenuItem>Archive</DropdownMenuItem>
            <DropdownMenuItem>Block</DropdownMenuItem>
            <DropdownMenuItem>Mark as unread</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
}
