import { PropsWithChildren, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BookOpenIcon, QuestionMarkCircleIcon } from "@heroicons/react/outline";
import { MODULE_HELP } from "src/containers/onboarding_module/OnboardingModuleMeta";
import { EMPTY_STATE_INFO } from "./EmptyStateMeta";

interface EmptyStateComponentProps extends PropsWithChildren {
  routeName: string;
  showInfo?: boolean;
  showDocumentation?: boolean;
}

interface ModuleInfo {
  title: string[];
  modal_title: string[];
  modal_icon: JSX.Element;
  content: JSX.Element;
  image: string;
  href: string;
  imgClassNames?: string;
}

interface EmptyStateInfo {
  title: string[];
  icon: JSX.Element;
  description: string[];
}

export default function EmptyStateComponent({
  routeName,
  children,
  showInfo,
  showDocumentation = true,
}: EmptyStateComponentProps) {
  const { t } = useTranslation();

  const moduleInfo: ModuleInfo = useMemo(() => {
    return MODULE_HELP[routeName];
  }, [routeName]);

  const emptyStateInfo: EmptyStateInfo = useMemo(() => {
    return EMPTY_STATE_INFO[routeName];
  }, [routeName]);

  return (
    <div className="inline-block transform overflow-hidden  px-4 pt-5 pb-4 text-left align-bottom transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6 sm:align-middle">
      <div className="flex flex-col gap-2 justify-center text-gray-800 items-center font-bold mb-12">
        <div className="h-12 w-12 rounded-full flex items-center justify-center text-hilos-alt-700 mr-4 bg-hilos-light">
          {emptyStateInfo.icon}
        </div>
        <div className="font-semibold mt-4 text-gray-800">
          {t(emptyStateInfo.title)}
        </div>
        <div className="text-sm font-normal text-gray-700">
          {t(emptyStateInfo.description)}
        </div>
        <div className="mt-4">{children}</div>
        {showInfo && <hr className="border-t border-gray-200 mt-12 w-full" />}
      </div>
      {showInfo && (
        <>
          <div className="">
            <div className="mx-auto  mt-6 items-center grid grid-cols-3">
              <div className="col-span-1">
                {
                  <img
                    src={moduleInfo.image}
                    alt="module"
                    className={moduleInfo.imgClassNames || "min-w-80"}
                  />
                }
              </div>
              <div className=" col-span-2 px-4">
                <p className="text-lg font-bold text-gray-800 mb-4">
                  {t(moduleInfo.title)}
                </p>
                <p>{moduleInfo.content}</p>
              </div>
            </div>
          </div>
          {showDocumentation && (
            <div className="w-full mt-10 flex">
              <div className="bg-blue-gray-light flex items-center rounded-md px-4 py-4">
                <div className="flex justify-center items-center p-2 bg-white rounded-full mr-4">
                  <QuestionMarkCircleIcon className="h-4 w-4 text-gray-500" />
                </div>
                <div>
                  <p className="font-semibold mb-1 text-gray-800">
                    {t("onboarding:module.help.title", "Any questions?")}
                  </p>
                  <p className="text-xs text-gray-500">
                    {t(
                      "onboarding:module.help.subtitle",
                      "For more information about this module, visit the documentation. If you still have questions, you can contact us via WhatsApp."
                    )}
                  </p>
                </div>
                <div className="ml-2">
                  <a
                    href={moduleInfo.href}
                    target="_blank"
                    rel="noreferrer"
                    className="mb-2"
                  >
                    <div className="rounded-sm mb-2 text-gray-500 bg-white px-3 py-1 flex items-center  justify-between ">
                      <h3 className="text-xs mr-2 text-left flex items-center">
                        {t("onboarding:module.documentation", "Documentation")}
                      </h3>
                      <BookOpenIcon className="h-4 w-4 " />
                    </div>
                  </a>
                  <a
                    href={moduleInfo.href}
                    key={moduleInfo.href}
                    target="_blank"
                    rel="noreferrer"
                    className=" mt-2"
                  >
                    <div className="rounded-sm text-green-700 bg-white px-3 py-1 flex items-center  justify-between ">
                      <h3 className="text-xs mr-2 text-left flex items-center">
                        {t("onboarding:module.contact-us", "Contact us")}
                      </h3>
                      <FontAwesomeIcon icon={faWhatsapp} className="w-3 h-3" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
