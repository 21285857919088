import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { buildRoute } from "src/router/router";
import SidebarMenuNotice from "./SidebarMenuNotice";

function SubscriptionIncompleteNotice() {
  const { t } = useTranslation();

  return (
    <SidebarMenuNotice
      title={t(
        "settings:subscription-incomplete-notice.title",
        "There was a problem processing your payment to start your subscription."
      )}
      description={t(
        "settings:subscription-incomplete-notice.subtitle",
        "To get started, please make sure the payment method you added is active."
      )}
    >
      <Link
        to={buildRoute("config-account-subscription")}
        className="group-data-[collapsible=icon]:hidden whitespace-nowrap font-medium text-red-700 hover:text-red-600"
      >
        {t("navigation:sandbox.notice.connect-number", "Connect number")}
        <span aria-hidden="true"> &rarr;</span>
      </Link>
    </SidebarMenuNotice>
  );
}

export default SubscriptionIncompleteNotice;
