import { getTranslationPayload as t } from "src/i18n";

export const CONVERSATION_ERROR_MESSAGES = {
  "0": t(
    "errors:whatsapp-provider.0",
    "We were unable to authenticate the app user."
  ),
  "1": t(
    "errors:whatsapp-provider.1",
    "Invalid request or possible server error."
  ),
  "2": t(
    "errors:whatsapp-provider.2",
    "Temporary due to downtime or due to being overloaded."
  ),
  "3": t("errors:whatsapp-provider.3", "Capability or permissions issue."),
  "4": t(
    "errors:whatsapp-provider.4",
    "The app has reached its API call rate limit."
  ),
  "10": t(
    "errors:whatsapp-provider.10",
    "Permission is either not granted or has been removed."
  ),
  "33": t(
    "errors:whatsapp-provider.33",
    "The business phone number has been deleted."
  ),
  "100": t(
    "errors:whatsapp-provider.100",
    "The request included one or more unsupported or misspelled parameters."
  ),
  "190": t("errors:whatsapp-provider.190", "Your access token has expired."),
  "200": t(
    "errors:whatsapp-provider.200",
    "Permission is either not granted or has been removed."
  ),
  "368": t(
    "errors:whatsapp-provider.368",
    "The WhatsApp Business Account associated with the app has been restricted or disabled for violating a platform policy."
  ),
  "80007": t(
    "errors:whatsapp-provider.80007",
    "The WhatsApp Business Account has reached its rate limit."
  ),
  "130429": t(
    "errors:whatsapp-provider.130429",
    "Cloud API message throughput has been reached."
  ),
  "130472": t(
    "errors:whatsapp-provider.130472",
    "Message was not sent as part of a WhatsApp internal experiment. Learn more here: https://developers.facebook.com/docs/whatsapp/cloud-api/guides/experiments#marketing-message-experiment"
  ),
  "130497": t(
    "errors:whatsapp-provider.130497",
    "The WhatsApp Business Account is restricted from messaging to users in certain countries."
  ),
  "131000": t(
    "errors:whatsapp-provider.131000",
    "Message failed to send due to an unknown error. Try again. If the error persists, open a Direct Support ticket here: https://business.facebook.com/direct-support"
  ),
  "131005": t(
    "errors:whatsapp-provider.131005",
    "Permission is either not granted or has been removed."
  ),
  "131008": t(
    "errors:whatsapp-provider.131008",
    "Required parameter is missing."
  ),
  "131009": t(
    "errors:whatsapp-provider.131009",
    "One or more parameter values are invalid. this can be related to PHONE_NUMBER_ID, MEDIA_URL OR MEDIA_ID."
  ),
  "131016": t(
    "errors:whatsapp-provider.131016",
    "A service is temporarily unavailable."
  ),
  "131021": t(
    "errors:whatsapp-provider.131021",
    "Sender and recipient phone number is the same."
  ),
  "131026": t(
    "errors:whatsapp-provider.131026",
    "Unable to deliver message. Reasons can include: The recipient phone number is not a WhatsApp phone number, recipient has not accepted our new Terms of Service and Privacy Policy, recipient using an old WhatsApp version."
  ),
  "131031": t(
    "errors:whatsapp-provider.131031",
    "The WhatsApp Business Account associated with the app has been restricted or disabled for violating a platform policy, or we were unable to verify data included in the request against data set on the WhatsApp Business Account"
  ),
  "131042": t(
    "errors:whatsapp-provider.131042",
    "Message failed to send because there were one or more errors related to your WhatsApp payment method. Check that you already have a credit card added on Facebook and that it's valid and active."
  ),
  "131045": t(
    "errors:whatsapp-provider.131045",
    "Message failed to send due to a phone number registration error. https://developers.facebook.com/docs/whatsapp/cloud-api/reference/registration"
  ),
  "131047": t(
    "errors:whatsapp-provider.131047",
    "More than 24 hours have passed since the recipient last replied to the sender number."
  ),
  "131048": t(
    "errors:whatsapp-provider.131048",
    "Message failed to send because there are restrictions on how many messages can be sent from this phone number. This may be because too many previous messages were blocked or flagged as spam."
  ),
  "131049": t(
    "errors:whatsapp-provider.131049",
    "This message was not delivered to maintain healthy ecosystem engagement."
  ),
  "131051": t("errors:whatsapp-provider.131051", "Unsupported message type."),
  "131052": t(
    "errors:whatsapp-provider.131052",
    "Unable to download the media sent by the user."
  ),
  "131053": t(
    "errors:whatsapp-provider.131053",
    "Unable to upload media: check that the image/document/video exists and can be accessed."
  ),
  "131056": t(
    "errors:whatsapp-provider.131056",
    "Too many messages sent from the sender phone number to the same recipient phone number in a short period of time."
  ),
  "131057": t(
    "errors:whatsapp-provider.131057",
    "The WhatsApp Business Account is in maintenance mode. One reason for this could be that the account is undergoing a throughput upgrade. https://developers.facebook.com/docs/whatsapp/cloud-api/reference/registration"
  ),
  "132000": t(
    "errors:whatsapp-provider.132000",
    "The number of variable parameter values included in the request did not match the number of variable parameters defined in the template."
  ),
  "132001": t(
    "errors:whatsapp-provider.132001",
    "The template does not exist in the specified language or the template has not been approved."
  ),
  "132005": t(
    "errors:whatsapp-provider.132005",
    "Translated text is too long."
  ),
  "132007": t(
    "errors:whatsapp-provider.132007",
    "Template content violates a WhatsApp policy."
  ),
  "132012": t(
    "errors:whatsapp-provider.132012",
    "Variable parameter values formatted incorrectly. See https://developers.facebook.com/docs/whatsapp/message-templates/guidelines/"
  ),
  "132015": t(
    "errors:whatsapp-provider.132015",
    "Template is paused by WhatsApp due to low quality so it cannot be sent in a template message."
  ),
  "132016": t(
    "errors:whatsapp-provider.132016",
    "Template has been paused too many times due to low quality and is now permanently disabled."
  ),
  "132068": t("errors:whatsapp-provider.132068", "Flow is in blocked state."),
  "132069": t(
    "errors:whatsapp-provider.132069",
    "Flow is in throttled state and 10 messages using this flow were already sent in the last hour."
  ),
  "133000": t(
    "errors:whatsapp-provider.133000",
    "A previous deregistration attempt failed, deregister the number again before registering."
  ),
  "133004": t(
    "errors:whatsapp-provider.133004",
    "Server is temporarily unavailable. Check: https://metastatus.com/whatsapp-business-api"
  ),
  "133005": t(
    "errors:whatsapp-provider.133005",
    "Two-step verification PIN incorrect."
  ),
  "133006": t(
    "errors:whatsapp-provider.133006",
    "Phone number needs to be verified before registering."
  ),
  "133008": t(
    "errors:whatsapp-provider.133008",
    "Too many two-step verification PIN guesses for this phone number."
  ),
  "133009": t(
    "errors:whatsapp-provider.133009",
    "Two-step verification PIN was entered too quickly."
  ),
  "133010": t(
    "errors:whatsapp-provider.133010",
    "Phone number not registered on the WhatsApp Business Platform."
  ),
  "133015": t(
    "errors:whatsapp-provider.133015",
    "The phone number you are attempting to register was recently deleted, and deletion has not yet completed."
  ),
  "133016": t(
    "errors:whatsapp-provider.133016",
    "Registration or Deregistration failed because there were too many attempts for this phone number in a short period of time."
  ),
  "135000": t(
    "errors:whatsapp-provider.135000",
    "Message failed to send because of an unknown error with your request parameters."
  ),
};
