import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UserIcon } from "@heroicons/react/outline";
import {
  FlowExecutionContactListSimple,
  FlowExecutionContactStatusEnum,
} from "@hilos/types/private-schema";
import DropdownMenu from "src/components/DropdownMenu";
import Table from "src/components/Table/Table";
import { classNames, formatPhone, userToString } from "../../../Helpers";
import { API_ROUTES, buildAPIRoute, buildRoute } from "../../../router/router";
import FlowExecutionContactStatusBadge from "../flow_execution_contact/FlowExecutionContactStatusBadge";

interface FlowViewerStepExecutionContactListProps {
  stepId?: string;
  flowExecutionId?: string;
  flowExecutionContactId?: string;
}

function FlowViewerStepExecutionContactList({
  stepId,
  flowExecutionId,
  flowExecutionContactId,
}: FlowViewerStepExecutionContactListProps) {
  const { t } = useTranslation();

  const params = useMemo(() => {
    const nextParams = {};

    if (stepId) {
      nextParams["execution_steps__step_id"] = stepId;
    }

    if (flowExecutionId) {
      nextParams["flow_execution"] = flowExecutionId;
    }

    if (flowExecutionContactId) {
      nextParams["id"] = flowExecutionContactId;
    }

    return nextParams;
  }, [stepId, flowExecutionId, flowExecutionContactId]);

  return (
    <div className="pt-3">
      <Table
        paginated
        bordered={false}
        baseURL={buildAPIRoute(API_ROUTES.FLOW_EXECUTION_CONTACT_LIST)}
        initialSearchParams={params}
        tableHead={
          <thead>
            <tr>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              >
                {t("phone", "Phone")}
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              >
                {t("name", "Name")}
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              >
                {t("status", "Status")}
              </th>
            </tr>
          </thead>
        }
        tableRow={(contactExecution: FlowExecutionContactListSimple) => (
          <>
            <td className="whitespace-nowrap py-3 px-4 text-sm text-gray-900">
              <Link
                className="font-medium text-blue-600"
                to={buildRoute("flow-execution-contact-detail", {
                  id: contactExecution.id,
                })}
              >
                {formatPhone(contactExecution.contact.phone, true, true)}
              </Link>

              <p className="text-sm text-gray-500">
                {t("{{ date, PPpp }}", {
                  date: contactExecution.created_on
                    ? new Date(contactExecution.created_on)
                    : "",
                })}
              </p>
            </td>
            <td className="whitespace-nowrap py-3 px-4 text-sm text-gray-500">
              {userToString(contactExecution.contact)}
            </td>
            <td className="whitespace-nowrap py-3 px-4 text-sm text-gray-500">
              <FlowExecutionContactStatusBadge
                status={
                  contactExecution.status as FlowExecutionContactStatusEnum
                }
              />
              {["CANCELED", "FAILED"].includes(
                contactExecution.status as string
              ) && (
                <p className="mt-1 text-xs ">
                  {t("reason", "Reason")}:{" "}
                  <span className="text-red-500">
                    {contactExecution.status_reason}
                  </span>
                </p>
              )}
            </td>
            <td className="whitespace-nowrap py-3 px-4 text-sm text-gray-900">
              <DropdownMenu
                width="50"
                menuItems={[
                  (active) => (
                    <Link
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "flex w-full items-center px-4 py-2 text-sm"
                      )}
                      type="button"
                      to={`/contact/${contactExecution.contact.id}/update`}
                    >
                      <UserIcon
                        className="-ml-0.5 mr-2 h-4 w-4"
                        aria-hidden="true"
                      />
                      {t("go-to-contact", "Go to contact")}
                    </Link>
                  ),
                ]}
              />
            </td>
          </>
        )}
        dataName={t("contact", "Contact")}
      />
    </div>
  );
}

export default FlowViewerStepExecutionContactList;
