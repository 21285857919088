import { Fragment, useCallback } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { WhatsAppMessage } from "@hilos/types/private-schema";
import { getValuesFromTemplateContent } from "src/helpers/template";
import MediaDocument from "./MediaDocument";
import MediaImage from "./MediaImage";

interface MessageMediaModalProps {
  message: WhatsAppMessage | null;
  onClose: () => void;
}

export default function MessageMediaModal({
  message,
  onClose,
}: MessageMediaModalProps) {
  const renderMediaComponent = useCallback(() => {
    if (!message) {
      return null;
    }

    switch (message.msg_type) {
      case "image":
        return <MediaImage onClose={onClose} message={message} />;
      case "document":
        return <MediaDocument onClose={onClose} message={message} />;
      case "template":
        const { headerURL } = getValuesFromTemplateContent(message.content);
        if (headerURL) {
          if (message.whatsapp_template?.components?.[0]?.format === "IMAGE") {
            return (
              <MediaImage
                onClose={onClose}
                message={message}
                content_url={headerURL}
              />
            );
          } else if (
            message.whatsapp_template.components[0].format === "DOCUMENT"
          ) {
            return (
              <MediaDocument
                onClose={onClose}
                message={message}
                content_url={headerURL}
              />
            );
          }
        }
        return headerURL ? (
          <MediaImage
            onClose={onClose}
            message={message}
            content_url={headerURL}
          />
        ) : null;
      default:
        return null;
    }
  }, [message, onClose]);

  return (
    <Transition.Root show={message !== null} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onClose}
      >
        <div className="flex min-h-screen items-end justify-center text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block transform overflow-hidden bg-black bg-opacity-70 text-left align-bottom shadow-xl transition-all max-h-screen   sm:align-middle">
              {renderMediaComponent()}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
