interface UnorderedListProps {
  text?: string | null;
}

export default function UnorderedList({ text }: UnorderedListProps) {
  if (!text) {
    return null;
  }
  const sentences = text
    .split(/(?<=\.\s)/)
    .filter((sentence) => sentence.trim() !== "");

  return (
    <ul className="list-disc">
      {sentences.map((sentence, index) => (
        <li key={index}>{sentence.trim()}</li>
      ))}
    </ul>
  );
}
