import { useCallback, useRef, useState } from "react";
import axios from "axios";
import { CursorPageData } from "@hilos/types/hilos";
import Loading from "src/components/Loading";
import useConversationContent from "src/hooks/useConversationContent";
import useInboxContactSync from "src/hooks/useInboxContactSync";
import { API_ROUTES } from "src/router/router";
import {
  ConversationContent,
  InboxContactRead,
  WhatsAppMessage,
} from "src/types/private-schema";
import { HandleMountConversationContent } from "../inbox/Conversation/Conversation";
import ConversationContentList from "../inbox/Conversation/ConversationContentList";

interface WorkflowBoardTaskContentProps {
  inboxContact: InboxContactRead;
  onSelectContextContent: (content: ConversationContent) => void;
  onSelectForwardMessage: (message: WhatsAppMessage) => void;
}

export default function WorkflowBoardTaskContent({
  inboxContact,
  onSelectContextContent,
  onSelectForwardMessage,
}: WorkflowBoardTaskContentProps) {
  const lastOutboundMessageIdRef = useRef<string | null>(null);
  const conversationContentByIdRef = useRef(new Map());
  const [focusedConversationContentId, setFocusedConversationContentId] =
    useState<string | null>(null);

  const {
    pages,
    isLoading,
    isFetchingNextPage,
    isFetchingPreviousPage,
    isUpdatingCursor,
    hasNextPage,
    hasPreviousPage,
    hasFirstPageLoaded,
    handleNextPage,
    handlePreviousPage,
    handleChangeInitialCursor,
  } = useConversationContent({
    inboxContactId: inboxContact.id,
    ignoreEvents: true,
  });

  useInboxContactSync({ id: inboxContact.id, ignoreEvents: true });

  const handleFocusConversationContent = useCallback(
    async (conversationContent: ConversationContent | null = null) => {
      if (conversationContent) {
        if (!conversationContentByIdRef.current.has(conversationContent.id)) {
          if (conversationContent.timestamp) {
            setFocusedConversationContentId(null);
            await handleChangeInitialCursor(conversationContent.timestamp);
          }
        }

        setFocusedConversationContentId(conversationContent.id);
      }
    },
    [handleChangeInitialCursor]
  );

  const handleFocusMessage = useCallback(
    async (messageId: string) => {
      const { data } = await axios.get<CursorPageData<ConversationContent>>(
        API_ROUTES.CONVERSATION_CONTENT.replace(":id", inboxContact.id),
        {
          params: {
            search: messageId,
          },
        }
      );
      if (data && data.results) {
        handleFocusConversationContent(data.results[0]);
      }
    },
    [inboxContact, handleFocusConversationContent]
  );

  const handleMountConversationContent =
    useCallback<HandleMountConversationContent>(
      ({ id, messageId, onFocus }) => {
        if (
          lastOutboundMessageIdRef.current &&
          lastOutboundMessageIdRef.current === messageId
        ) {
          onFocus();
          lastOutboundMessageIdRef.current = null;
        }
        conversationContentByIdRef.current.set(id, onFocus);
        return () => {
          conversationContentByIdRef.current.delete(id);
        };
      },
      []
    );

  const handleFocusStartOfContent = () => {};

  const handleCloseConversationSearch = () => {};

  if (isLoading || isUpdatingCursor) {
    return <Loading />;
  }

  return (
    <ConversationContentList
      inboxContact={inboxContact}
      pages={pages}
      className="p-3 sm:px-2"
      isUpdatingCursor={isUpdatingCursor}
      isFetchingNextPage={isFetchingNextPage}
      isFetchingPreviousPage={isFetchingPreviousPage}
      hasNextPage={hasNextPage}
      hasPreviousPage={hasPreviousPage}
      hasFirstPageLoaded={hasFirstPageLoaded}
      focusedConversationContentId={focusedConversationContentId}
      onNextPage={handleNextPage}
      onPreviousPage={handlePreviousPage}
      onFocusMessage={handleFocusMessage}
      onFocusStartOfContent={handleFocusStartOfContent}
      onSelectContextContent={onSelectContextContent}
      onSelectForwardMessage={onSelectForwardMessage}
      onCloseConversationSearch={handleCloseConversationSearch}
      onMountConversationContent={handleMountConversationContent}
    />
  );
}
