import { useTranslation } from "react-i18next";
import { faRectangleAd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChatAlt2Icon, CheckCircleIcon } from "@heroicons/react/outline";
import { useFormikContext } from "formik";
import { FlowData } from "@hilos/types/flow";
import SelectorField from "src/components/Form/SelectorField";
import { TriggerCategoryProps } from "../FlowBuilderTrigger";
import { MARKETING_TYPE_TRIGGER } from "../constants/triggers";
import TriggerCategorySelector from "./TriggerCategorySelector";

function MarketingTrigger({ handleTriggerTypeChange }: TriggerCategoryProps) {
  const [t] = useTranslation();
  const { values } = useFormikContext<FlowData>();

  const matchAllOptions = [
    {
      label: t(
        "flow-executions:input.meta-c2wa.match-type.all",
        "Match all messages"
      ),
      value: "MATCH_ALL",
      icon: <CheckCircleIcon className="h-4 w-4" />,
    },
    {
      label: t(
        "flow-executions:input.meta-c2wa.match-type.post",
        "Match only messages coming from a post"
      ),
      value: "MATCH_POST",
      icon: <ChatAlt2Icon className="h-4 w-4" />,
    },
    {
      label: t(
        "flow-executions:input.meta-c2wa.match-type.ad",
        "Match only messages coming from an ad"
      ),
      value: "MATCH_AD",
      icon: <FontAwesomeIcon icon={faRectangleAd} className="ml-1 text-lg" />,
    },
  ];

  const formatOptionLabel = ({ value, label, icon: RenderIcon, ...props }) => {
    return (
      <div className="flex items-center justify-between">
        <div className="flex items-center py-1">
          <div className="mx-2">{RenderIcon}</div>
          <div>
            <h4 className="font-medium">{label}</h4>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="pb-4">
        <h2 className="text-lg font-medium text-gray-900">
          {t(
            "flows:trigger.marketing-trigger.title",
            "Trigger when a message comes in from a Marketing Campaign"
          )}
        </h2>
      </div>

      <div className="space-y-4">
        <TriggerCategorySelector
          handleTriggerTypeChange={handleTriggerTypeChange}
          triggersForCategory={MARKETING_TYPE_TRIGGER}
        />

        {values.trigger_type === "META_C2WA" && (
          <>
            <SelectorField
              label={t(
                "flow-executions:input.meta-c2wa.c2wa_match_type.label",
                "Which messages should we match?"
              )}
              name="trigger_config.c2wa_match_type"
              options={matchAllOptions}
              formatOptionLabel={formatOptionLabel}
            />

            {/* {values.trigger_config.c2wa_match_type === "MATCH_POST" && (
              <>
                <SwitchField
                  label={t(
                    "flow-executions:input.meta-c2wa.c2wa_match_specific_post.label",
                    "Match only for a specific post?"
                  )}
                  help={t(
                    "flow-executions:input.meta-c2wa.c2wa_match_specific_post.help",
                    "If not set, we'll trigger for any message coming from a post"
                  )}
                  name="trigger_config.c2wa_match_specific_post"
                />

                {values.trigger_config.c2wa_match_specific_post && (
                  <TextInputField
                    name="trigger_config.c2wa_match_post_id"
                    placeholder={t(
                      "flow-executions:input.meta-c2wa.c2wa_match_post_id.placeholder",
                      "123..."
                    )}
                    label={t(
                      "flow-executions:input.meta-c2wa.c2wa_match_post_id.label",
                      "ID of the post you'd like us to match."
                    )}
                    help={
                      <Trans i18nKey="flow-executions:input.meta-c2wa.c2wa_match_post_id.help">
                        Not sure how to find the post id? Check our{" "}
                        <a
                          href="https://hilos.io/docs/en/user/hilos-101/introduction"
                          target="_blank"
                          rel="noreferrer"
                        >
                          docs
                        </a>{" "}
                        to find out more.
                      </Trans>
                    }
                    type="text"
                  />
                )}
              </>
            )}

            {values.trigger_config.c2wa_match_type === "MATCH_AD" && (
              <>
                <SwitchField
                  label={t(
                    "flow-executions:input.meta-c2wa.c2wa_match_specific_ad.label",
                    "Match only for a specific ad?"
                  )}
                  help={t(
                    "flow-executions:input.meta-c2wa.c2wa_match_specific_ad.help",
                    "If not set, we'll trigger for any message coming from an ad"
                  )}
                  name="trigger_config.c2wa_match_specific_ad"
                />

                {values.trigger_config.c2wa_match_specific_ad && (
                  <TextInputField
                    name="trigger_config.c2wa_match_ad_id"
                    placeholder={t(
                      "flow-executions:input.meta-c2wa.c2wa_match_ad_id.placeholder",
                      "123..."
                    )}
                    label={t(
                      "flow-executions:input.meta-c2wa.c2wa_match_ad_id.label",
                      "ID of the ad you'd like us to match"
                    )}
                    help={
                      <Trans i18nKey="flow-executions:input.meta-c2wa.c2wa_match_ad_id.help">
                        Not sure how to find the ad id? Check our{" "}
                        <a
                          href="https://hilos.io/docs/en/user/hilos-101/introduction"
                          target="_blank"
                          rel="noreferrer"
                          className="text-blue-500 underline"
                        >
                          docs
                        </a>{" "}
                        to find out more.
                      </Trans>
                    }
                    type="text"
                  />
                )}
              </>
            )} */}
          </>
        )}
      </div>
    </>
  );
}

export default MarketingTrigger;
