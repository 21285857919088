import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isToday } from "date-fns";

interface ConversationContentItemSeparatorProps {
  date: string | number;
}

function ConversationContentItemSeparator({
  date,
}: ConversationContentItemSeparatorProps) {
  const { t } = useTranslation();
  const label = useMemo(() => {
    const currentDate = new Date(date);

    return isToday(currentDate)
      ? t("today")
      : t("{{ date, relative }}", { date: currentDate });
  }, [date, t]);

  return (
    <>
      <div
        className="absolute h-8 inset-0 flex items-center"
        aria-hidden="true"
      >
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="sticky top-0 z-10">
        <div className="relative flex justify-center">
          <div className="flex bg-gray-100 h-8 items-center p-3 rounded-md shadow-md">
            <span className="text-sm text-gray-500 first-letter:capitalize">
              {label}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConversationContentItemSeparator;
