import {
  ChatIcon,
  ClipboardCheckIcon,
  ClipboardListIcon,
  InboxIcon,
  ShareIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { Bot } from "lucide-react";
import { getTranslationPayload as t } from "src/i18n";

export const EMPTY_STATE_INFO = {
  inbox: {
    title: t(
      "empty-states:inbox.title",
      "No conversations with messages found."
    ),
    description: t(
      "empty-states:inbox.description",
      "Why don't you start a new one?"
    ),
    icon: <InboxIcon className="w-5 h-5" />,
  },
  templates: {
    title: t(
      "empty-states:templates.title",
      "Looks like you don't have any templates yet"
    ),
    description: t(
      "empty-states:templates.description",
      "Why don't you create one? Do so in our Template Builder"
    ),
    icon: <ClipboardListIcon className="w-5 h-5" />,
  },
  flows: {
    title: t(
      "empty-states:flows.title",
      "Looks like you don't have any flows yet."
    ),
    description: t(
      "empty-states:flows.description",
      "Why don't you create one? Start with a template or from scratch."
    ),
    icon: <ShareIcon className="rotate-90 w-5 h-5" />,
  },
  contacts: {
    title: t(
      "empty-states:contacts.title",
      "Looks like you don't have any contacts yet."
    ),
    description: t(
      "empty-states:contacts.description",
      "Why don't you create one?"
    ),
    icon: <UserIcon className="w-5 h-5" />,
  },
  broadcasts: {
    title: t(
      "empty-states:broadcasts.title",
      "Looks like you don't have any broadcasts yet."
    ),
    description: t(
      "empty-states:broadcasts.description",
      "Why don't you create one?"
    ),
    icon: <ChatIcon className="w-5 h-5" />,
  },
  ai_tasks: {
    title: t(
      "empty-states:ai_tasks.title",
      "Looks like you don't have any tasks yet."
    ),
    description: t(
      "empty-states:ai_tasks.description",
      "Every morning, we create tasks for conversations where you were the last person to send a message."
    ),
    icon: <Bot className="w-5 h-5" />,
  },
};
