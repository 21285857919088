import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { InformationCircleIcon, PlusIcon } from "@heroicons/react/outline";
import { useVesselLink } from "@vesselapi/react-vessel-link";
import axios from "axios";
import { VesselApp } from "@hilos/types/private-schema";
import Button from "src/components/Button";
import Loading from "src/components/Loading";
import useHilosStore from "src/hooks/useHilosStore";
import { API_ROUTES, buildRoute } from "src/router/router";

interface VesselInstallProps {
  integrationId: string;
}

export default function VesselInstall({ integrationId }: VesselInstallProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { session } = useHilosStore();
  const [connecting, setConnecting] = useState(false);

  const { open } = useVesselLink({
    onSuccess: async (publicToken) => {
      const response = await axios.post<VesselApp>(
        API_ROUTES.VESSEL_ACCESS_TOKEN,
        {
          public_token: publicToken,
          integration_id: integrationId,
        }
      );
      setConnecting(false);
      navigate(
        buildRoute("integrations-vessel-connected", {
          integrationId: integrationId,
          externalAppId: response.data.id,
        })
      );
    },
    onLoad: () => console.log("loaded"),
    onClose: () => {
      setConnecting(false);
      console.log("closed");
    },
  });

  const getLinkToken = useCallback(async () => {
    const response = await axios.get(API_ROUTES.VESSEL_SESSION_TOKEN);
    return response.data.linkToken;
  }, []);

  if (connecting) {
    return <Loading />;
  }

  return (
    <>
      {session?.account.current_subscription ? (
        <div className="rounded-md bg-blue-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                className="h-5 w-5 text-blue-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-blue-700">
                {t(
                  "integrations:external-apps.non-free-app.description",
                  "This integration is not included in your base plan. We'll add it to your suscription when you connect it."
                )}
              </p>
              <p className="mt-3 text-sm md:ml-6 md:mt-0">
                <a
                  href="https://hilos.io/pricing"
                  target="_blank"
                  rel="noreferrer"
                  className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                >
                  {t("learn-more", "Learn more")}
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="rounded-md bg-blue-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                className="h-5 w-5 text-blue-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-blue-700">
                {t(
                  "integrations:external-apps.non-free-app.subscription-required",
                  "Start your Hilos subscription to add this integration."
                )}
              </p>
              <p className="mt-3 text-sm md:ml-6 md:mt-0">
                <Link
                  to={buildRoute("config-account-subscription")}
                  target="_blank"
                  rel="noreferrer"
                  className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                >
                  {t("subscribe-now", "Subscribe now")}
                  <span aria-hidden="true"> &rarr;</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      )}

      {session?.account.current_subscription && (
        <div className="flex items-center justify-center">
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
            <Button
              onClick={async () => {
                setConnecting(true);
                open({
                  integrationId: integrationId,
                  linkToken: await getLinkToken(),
                });
              }}
              className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <PlusIcon
                className="h-5 w-5 md:-ml-1 md:mr-2"
                aria-hidden="true"
              />
              <span className="hidden md:block">{t("connect")}</span>
            </Button>
          </h2>
        </div>
      )}
    </>
  );
}
