import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useHilosStore from "src/hooks/useHilosStore";
import { buildRoute } from "src/router/router";
import SidebarMenuNotice from "./SidebarMenuNotice";

function FreeTrialDaysRemainingNotice() {
  const { session } = useHilosStore();
  const [t] = useTranslation();

  const freeTrialDaysRemaining = useMemo(
    () => (session && session.account.remaining_free_trial_days) || 0,
    [session]
  );

  const linkButton = useMemo(
    () => (
      <Link
        to={buildRoute("config-account-subscription")}
        className="group-data-[collapsible=icon]:hidden whitespace-nowrap font-medium text-red-700 hover:text-red-600"
      >
        {t("settings:free-trial-days-remaining-notice.subscribe", "Subscribe")}
        <span aria-hidden="true"> &rarr;</span>
      </Link>
    ),
    [t]
  );

  if (freeTrialDaysRemaining > 1) {
    return (
      <SidebarMenuNotice
        title={t(
          "settings:free-trial-days-remaining-notice.almost-over.title",
          "Your free trial is almost over."
        )}
        description={t(
          "settings:free-trial-days-remaining-notice.almost-over.description",
          "You have {{ freeTrialDaysRemaining }} more days in your free trial, after that you'll need to subscribe to continue using Hilos.",
          { freeTrialDaysRemaining }
        )}
      >
        {linkButton}
      </SidebarMenuNotice>
    );
  }

  if (freeTrialDaysRemaining === 1) {
    return (
      <SidebarMenuNotice
        title={t(
          "settings:free-trial-days-remaining-notice.last-day.title",
          "Tomorrow's your last free trial day."
        )}
        description={t(
          "settings:free-trial-days-remaining-notice.last-day.description",
          "Don't forget to suscribe to continue using Hilos."
        )}
      >
        {linkButton}
      </SidebarMenuNotice>
    );
  }

  return (
    <SidebarMenuNotice
      title={t(
        "settings:free-trial-days-remaining-notice.ended.title",
        "Your free trial has ended."
      )}
      description={t(
        "settings:free-trial-days-remaining-notice.ended.description",
        "Suscribe to Hilos to continue using it."
      )}
    >
      {linkButton}
    </SidebarMenuNotice>
  );
}

export default FreeTrialDaysRemainingNotice;
