import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSidebar } from "src/components/ui/sidebar";
import WorkflowBoardData from "./WorkflowBoardData";
import { WorkflowBoardProvider } from "./WorkflowBoardProvider";

export default function WorkflowBoard() {
  const { workflowId } = useParams();
  const { open, setOpen } = useSidebar();

  useEffect(() => {
    if (open) {
      setOpen(false);
    }
  }, []);

  if (!workflowId) {
    return null;
  }

  return (
    <WorkflowBoardProvider id={workflowId}>
      <WorkflowBoardData />
    </WorkflowBoardProvider>
  );
}
