import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { ArrowSmRightIcon, CheckIcon } from "@heroicons/react/outline";
import { buildRoute } from "src/router/router";
import { STEP_TYPES } from "../constants/steps";

export function ActionStepDescription() {
  return (
    <Trans i18nKey={"flows:step-types.action.help"}>
      <div className="pl-3">
        <div className="text-sm text-gray-700">
          <h3 className="font-medium text-gray-800 text-base">
            <span className="inline-flex items-center rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-800 mr-1">
              Advanced
            </span>{" "}
            Make HTTP requests to external APIs
          </h3>
          <div className="mt-2 grid grid-cols-2 space-x-4 items-start overflow-hidden">
            <div className="space-y-3">
              <p>
                Use this step to query data and use it on your flow, or save
                data from your flow on your favorite tools.
              </p>
              <ul className="space-y-2">
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Query data and use the results as normal flow variables. You
                    can use these results everywhere.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Save data on your CRM, database or any other place with
                    Zapier.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Use lists of objects returned by your API to generate
                    dynamic options for Question steps.
                  </p>
                </li>
              </ul>
            </div>
            <div className="space-y-4">
              <div className="grid grid-cols-2 space-x-2">
                <img
                  className="mt-2 rounded-lg"
                  src="https://uploads-ssl.webflow.com/62e06c89457f36d21c5b726f/64b56f5c27db082efb502db8_5.png"
                  alt="hubspot"
                />
                <img
                  className="mt-2 rounded-lg"
                  src="https://uploads-ssl.webflow.com/62e06c89457f36d21c5b726f/64c9e85b54cac71068a4fa15_facebook.png"
                  alt="facebook"
                />
                <img
                  className="mt-2 rounded-lg"
                  src="https://uploads-ssl.webflow.com/62e06c89457f36d21c5b726f/64b56f5be7f6021996f978ab_4.png"
                  alt="salesforce"
                />
                <img
                  className="mt-2 rounded-lg"
                  src="https://uploads-ssl.webflow.com/62e06c89457f36d21c5b726f/64b56f5c0297f06b93bc33d4_1.png"
                  alt="airtable"
                />
                <img
                  className="mt-2 rounded-lg"
                  src="https://uploads-ssl.webflow.com/62e06c89457f36d21c5b726f/64b56f5bfd28958e95f0e604_3.png"
                  alt="zapier"
                />
                <img
                  className="mt-2 rounded-lg"
                  src="https://uploads-ssl.webflow.com/62e06c89457f36d21c5b726f/64b5735628fd159d30d24b3f_11.png"
                  alt="stripe"
                />
                <img
                  className="mt-2 rounded-lg"
                  src="https://uploads-ssl.webflow.com/62e06c89457f36d21c5b726f/64b56f5c8ce177442512fa88_2.png"
                  alt="shopify"
                />
                <img
                  className="mt-2 rounded-lg"
                  src="https://uploads-ssl.webflow.com/62e06c89457f36d21c5b726f/64b57356814d2d55c431fe41_10.png"
                  alt="notion"
                />
                <img
                  className="mt-2 rounded-lg"
                  src="https://uploads-ssl.webflow.com/62e06c89457f36d21c5b726f/64b573579a9b1f77e95f22c9_9.png"
                  alt="drive"
                />
                <img
                  className="mt-2 rounded-lg"
                  src="https://uploads-ssl.webflow.com/62e06c89457f36d21c5b726f/64b56f5b21ae39cbf5dffe42_8.png"
                  alt="slack"
                />
              </div>
              <div>
                <a
                  href={STEP_TYPES.ACTION.docsLink}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center justify-center rounded bg-indigo-50 px-2.5 py-1.5 text-indigo-600 shadow-sm hover:bg-indigo-100 w-full"
                >
                  Learn more
                  <ArrowSmRightIcon className="h-5 w-5 ml-1" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Trans>
  );
}

export function ConditionalStepDescription() {
  return (
    <Trans i18nKey={"flows:step-types.conditional.help"}>
      <div className="pl-3">
        <div className="text-sm text-gray-700">
          <h3 className="font-medium text-gray-800 text-base">
            Add a conditional check to your flow.
          </h3>
          <div className="mt-2 overflow-y-scroll grid grid-cols-2 space-x-4 items-start">
            <div className="space-y-3">
              <p>
                Build flows with multiple paths, choose which to take based on
                one or more conditions.
              </p>
              <ul className="space-y-2">
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Useful to conditionally show certain parts of your flow.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    For example, you might skip asking your customer for their
                    email if you already have it saved.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Add all the conditions you need: if they all evaluate to{" "}
                    <code className="font-mono font-medium text-indigo-500">
                      true
                    </code>
                    , the user will continue through that branch.
                  </p>
                </li>
              </ul>
            </div>
            <div className="space-y-4">
              <img
                className="w-60 rounded-lg"
                src="https://hilos-media.s3.us-east-2.amazonaws.com/app/flows/conditional.png"
                alt="conditional"
              />
              <div>
                <a
                  href={STEP_TYPES.CONDITIONAL.docsLink}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center justify-center rounded bg-indigo-50 px-2.5 py-1.5 text-indigo-600 shadow-sm hover:bg-indigo-100 w-full"
                >
                  Learn more
                  <ArrowSmRightIcon className="h-5 w-5 ml-1" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Trans>
  );
}

export function ContactUpdateStepDescription() {
  return (
    <Trans i18nKey={"flows:step-types.contact-update.help"}>
      <div className="pl-3">
        <div className="text-sm text-gray-700 grid grid-cols-2 space-x-4">
          <div className="overflow-y-scroll items-start">
            <h3 className="font-medium text-gray-800 text-base">
              Update the contact on Hilos.
            </h3>
            <div className="mt-2 space-y-3">
              <p>
                Overwrite basic properties or add custom properties to the user.
              </p>
              <ul className="space-y-2">
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Useful to save the user's answers to questions on this flow.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    If you'd like to be notified when a contact is updated, you
                    can do so with our{" "}
                    <Link
                      to={buildRoute("webhook-create")}
                      className="text-blue-500"
                    >
                      webhooks
                    </Link>
                    .
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>Add as many custom properties as you want.</p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    These basic and custom properties can then be seen and
                    updated in the{" "}
                    <strong className="font-medium text-gray-800">
                      Contact tab
                    </strong>{" "}
                    in your Inbox.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <span className="inline-flex items-center rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-800 mr-1">
                    Soon!
                  </span>
                  <p>Set advanced filters using custom contact properties.</p>
                </li>
              </ul>
              <div>
                <a
                  href={STEP_TYPES.CONTACT_UPDATE.docsLink}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center justify-center rounded bg-indigo-50 px-2.5 py-1.5 text-indigo-600 shadow-sm hover:bg-indigo-100 w-full"
                >
                  Learn more
                  <ArrowSmRightIcon className="h-5 w-5 ml-1" />
                </a>
              </div>
            </div>
          </div>
          <div className="space-y-4">
            <img
              className="w-60 rounded-lg"
              src="https://hilos-media.s3.us-east-2.amazonaws.com/app/flows/contact-update.png"
              alt="contact-update"
            />
          </div>
        </div>
      </div>
    </Trans>
  );
}

export function HubspotContactUpsertStepDescription() {
  return (
    <Trans i18nKey={"flows:step-types.hubspot-contact-upsert.help"}>
      <div className="pl-3">
        <div className="text-sm text-gray-700 grid grid-cols-2 space-x-4">
          <div className="overflow-y-scroll items-start">
            <h3 className="font-medium text-gray-800 text-base">
              Create or Update contacts in HubSpot
            </h3>
            <div className="mt-2 space-y-3">
              <p>
                Send to HubSpot the most relevant data from the conversation and
                assign it to your contact's basic or custom properties.
              </p>
              <ul className="space-y-2">
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Profile your Leads in WhatsApp and send the information to
                    HubSpot custom properties.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Update your users' contact details on WhatsApp and send the
                    information to HubSpot.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Update HubSpot with relevant contact information such as
                    lifecycle stage or buyer role.
                  </p>
                </li>
              </ul>
              <div>
                <a
                  href={STEP_TYPES.HUBSPOT_CONTACT_UPSERT.docsLink}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center justify-center rounded bg-indigo-50 px-2.5 py-1.5 text-indigo-600 shadow-sm hover:bg-indigo-100 w-full"
                >
                  Learn more
                  <ArrowSmRightIcon className="h-5 w-5 ml-1" />
                </a>
              </div>
            </div>
          </div>
          <div className="space-y-4">
            <img
              className="w-60 rounded-lg"
              src="https://hilos-media.s3.us-east-2.amazonaws.com/app/flows/hubspot-contact-upsert.png"
              alt="hubspot-contact-upsert"
            />
          </div>
        </div>
      </div>
    </Trans>
  );
}

export function HubspotContactGetStepDescription() {
  return (
    <Trans i18nKey={"flows:step-types.hubspot-contact-get.help"}>
      <div className="pl-3">
        <div className="text-sm text-gray-700 grid grid-cols-2 space-x-4">
          <div className="overflow-y-scroll items-start">
            <h3 className="font-medium text-gray-800 text-base">
              Get HubSpot Contact Information
            </h3>
            <div className="mt-2 space-y-3">
              <p>
                Get relevant contact properties in HubSpot to use in your
                replies in WhatsApp.
              </p>
              <ul className="space-y-2">
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Get relevant contact information to use in the conversation.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Allow your users to get data from the contact's properties (
                    such as payment dates, quote status, etc)
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Provide a personalized experience on WhatsApp for your
                    customer with their HubSpot information.
                  </p>
                </li>
              </ul>
              <div>
                <a
                  href={STEP_TYPES.HUBSPOT_CONTACT_GET.docsLink}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center justify-center rounded bg-indigo-50 px-2.5 py-1.5 text-indigo-600 shadow-sm hover:bg-indigo-100 w-full"
                >
                  Learn more
                  <ArrowSmRightIcon className="h-5 w-5 ml-1" />
                </a>
              </div>
            </div>
          </div>
          <div className="space-y-4">
            <img
              className="w-60 rounded-lg"
              src="https://hilos-media.s3.us-east-2.amazonaws.com/app/flows/hubspot_contact_get.png"
              alt="hubspot-contact-upsert"
            />
          </div>
        </div>
      </div>
    </Trans>
  );
}

export function ConversationStepDescription() {
  return (
    <Trans i18nKey={"flows:step-types.conversation.help"}>
      <div className="pl-3">
        <div className="text-sm text-gray-700">
          <h3 className="font-medium text-gray-800 text-base">
            Handoff the conversation to one or more users.
          </h3>
          <div className="mt-2 overflow-y-scroll grid grid-cols-2 space-x-4 items-start">
            <div className="space-y-3">
              <p>
                They will be able to continue the conversation on the Inbox.
              </p>
              <ul className="space-y-2">
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Use it when you wish to always assign it to one or more
                    specific users. If you want advanced assignment, use the{" "}
                    <strong className="font-medium text-gray-800">
                      Team Step
                    </strong>
                    .
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    The assigned users will see this conversation as unread in
                    the Inbox and will be notified.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Add tags to the conversation or contact in addition to
                    assigning it.
                  </p>
                </li>
              </ul>
            </div>
            <div className="space-y-4">
              <img
                className="rounded-lg"
                src="https://hilos-media.s3.us-east-2.amazonaws.com/app/flows/conversation.png"
                alt="inbox"
              />
              <img
                className="mt-2 rounded-lg"
                src="https://hilos-media.s3.us-east-2.amazonaws.com/app/flows/conversation-2.png"
                alt="step"
              />

              <div>
                <a
                  href={STEP_TYPES.CONVERSATION.docsLink}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center justify-center rounded bg-indigo-50 px-2.5 py-1.5 text-indigo-600 shadow-sm hover:bg-indigo-100 w-full"
                >
                  Learn more
                  <ArrowSmRightIcon className="h-5 w-5 ml-1" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Trans>
  );
}

export function DelayStepDescription() {
  return (
    <Trans i18nKey={"flows:step-types.delay.help"}>
      <div className="pl-3">
        <div className="text-sm text-gray-700">
          <h3 className="font-medium text-gray-800 text-base">
            Wait for a time before resuming this flow.
          </h3>
          <div className="mt-2 overflow-y-scroll grid grid-cols-2 space-x-4 items-start">
            <div className="space-y-3">
              <p>Use this step to build more advanced customer journeys.</p>
              <ul className="space-y-2">
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Select how long to wait before continuing, on days, hours or
                    minutes.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    This step doesn't resume if the user answers, it will always
                    wait. If you'd like to resume if you receive a message from
                    the user, use the{" "}
                    <strong className="font-medium text-gray-800">
                      Question step
                    </strong>{" "}
                    instead.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    To avoid sending messages at unwanted hours or days, choose
                    a time window in which to continue.
                  </p>
                </li>
              </ul>
            </div>
            <div className="space-y-4">
              <img
                className="mt-2 rounded-lg"
                src="https://hilos-media.s3.us-east-2.amazonaws.com/app/flows/delay.png"
                alt="delay"
              />

              <div>
                <a
                  href={STEP_TYPES.DELAY.docsLink}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center justify-center rounded bg-indigo-50 px-2.5 py-1.5 text-indigo-600 shadow-sm hover:bg-indigo-100 w-full"
                >
                  Learn more
                  <ArrowSmRightIcon className="h-5 w-5 ml-1" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Trans>
  );
}

export function GoToStepDescription() {
  return (
    <Trans i18nKey={"flows:step-types.go-to.help"}>
      <div className="pl-3">
        <div className="text-sm text-gray-700 grid grid-cols-2 space-x-4">
          <div className="overflow-y-scroll items-start">
            <h3 className="font-medium text-gray-800 text-base">
              Go to another step in the flow.
            </h3>
            <div className="mt-2 space-y-3">
              <p>Useful for building loops or returning to a previous step.</p>
              <ul className="space-y-2">
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Create FAQs with the option to choose a different answer.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Re-use parts of your flow: if they can be reached from two
                    places, use this step to join them.
                  </p>
                </li>
              </ul>
              <div>
                <a
                  href={STEP_TYPES.GO_TO.docsLink}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center justify-center rounded bg-indigo-50 px-2.5 py-1.5 text-indigo-600 shadow-sm hover:bg-indigo-100 w-full"
                >
                  Learn more
                  <ArrowSmRightIcon className="h-5 w-5 ml-1" />
                </a>
              </div>
            </div>
          </div>
          <div className="space-y-4">
            <video
              className="w-60 rounded-lg"
              src="https://hilos-media.s3.us-east-2.amazonaws.com/app/flows/goto.mp4"
              controls={false}
              autoPlay={true}
              loop={true}
            />
          </div>
        </div>
      </div>
    </Trans>
  );
}

export function MenuStepDescription() {
  return (
    <Trans i18nKey={"flows:step-types.menu.help"}>
      <div className="pl-3">
        <div className="text-sm text-gray-700">
          <h3 className="font-medium text-gray-800 text-base">
            Quickly build navigation menus.
          </h3>
          <div className="mt-2 overflow-y-scroll grid grid-cols-2 space-x-4 items-start">
            <div className="space-y-3">
              <p>
                Go to different branches of your flow according to your users'
                answers.
              </p>
              <ul className="space-y-2">
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Show the menu options as buttons, interactive lists, or
                    numbers.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    If the user hasn't answered past some time, add a follow-up
                    message step.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    If the user doesn't answer the way you expect them to, stop
                    waiting for an answer and go to a different step.
                  </p>
                </li>
              </ul>
            </div>
            <div className="space-y-4">
              <video
                className="w-60 rounded-lg"
                src="https://hilos-media.s3.us-east-2.amazonaws.com/app/flows/menu.mp4"
                controls={false}
                autoPlay={true}
                loop={true}
              />
              <div>
                <a
                  href={STEP_TYPES.MENU.docsLink}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center justify-center rounded bg-indigo-50 px-2.5 py-1.5 text-indigo-600 shadow-sm hover:bg-indigo-100 w-full"
                >
                  Learn more
                  <ArrowSmRightIcon className="h-5 w-5 ml-1" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Trans>
  );
}

export function WAFlowStepDescription() {
  return (
    <Trans i18nKey={"flows:step-types.wa-flow.help"}>
      <div className="pl-3">
        <div className="text-sm text-gray-700">
          <h3 className="font-medium text-gray-800 text-base">
            Use WhatsApp Flows to show forms in the WhatsApp conversation.
          </h3>
          <div className="mt-2 overflow-y-scroll grid grid-cols-2 space-x-4 items-start">
            <div className="space-y-3">
              <p>
                Particularly useful for when you need to ask several questions
                from your user.
              </p>
              <ul className="space-y-2">
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>Add text fields, dropdowns, date selectors and more.</p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    If the user hasn't answered past some time, add a follow-up
                    message step.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    If the user doesn't answer the way you expect them to, stop
                    waiting for an answer and go to a different step.
                  </p>
                </li>
              </ul>
            </div>
            <div className="space-y-4">
              <video
                className="w-60 rounded-lg"
                src="https://hilos-media.s3.us-east-2.amazonaws.com/app/flows/menu.mp4"
                controls={false}
                autoPlay={true}
                loop={true}
              />
              <div>
                <a
                  href={STEP_TYPES.WA_FLOW.docsLink}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center justify-center rounded bg-indigo-50 px-2.5 py-1.5 text-indigo-600 shadow-sm hover:bg-indigo-100 w-full"
                >
                  Learn more
                  <ArrowSmRightIcon className="h-5 w-5 ml-1" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Trans>
  );
}

export function WACAPIStepDescription() {
  return (
    <Trans i18nKey={"flows:step-types.wa-flow.help"}>
      <div className="pl-3">
        <div className="text-sm text-gray-700">
          <h3 className="font-medium text-gray-800 text-base">
            Use WhatsApp Flows to show forms in the WhatsApp conversation.
          </h3>
          <div className="mt-2 overflow-y-scroll grid grid-cols-2 space-x-4 items-start">
            <div className="space-y-3">
              <p>
                Particularly useful for when you need to ask several questions
                from your user.
              </p>
              <ul className="space-y-2">
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>Add text fields, dropdowns, date selectors and more.</p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    If the user hasn't answered past some time, add a follow-up
                    message step.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    If the user doesn't answer the way you expect them to, stop
                    waiting for an answer and go to a different step.
                  </p>
                </li>
              </ul>
            </div>
            <div className="space-y-4">
              <video
                className="w-60 rounded-lg"
                src="https://hilos-media.s3.us-east-2.amazonaws.com/app/flows/menu.mp4"
                controls={false}
                autoPlay={true}
                loop={true}
              />
              <div>
                <a
                  href={STEP_TYPES.WA_CAPI.docsLink}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center justify-center rounded bg-indigo-50 px-2.5 py-1.5 text-indigo-600 shadow-sm hover:bg-indigo-100 w-full"
                >
                  Learn more
                  <ArrowSmRightIcon className="h-5 w-5 ml-1" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Trans>
  );
}

export function MessageStepDescription() {
  return (
    <Trans i18nKey={"flows:step-types.message.help"}>
      <div className="pl-3">
        <div className="text-sm text-gray-700">
          <h3 className="font-medium text-gray-800 text-base">
            Send a message
          </h3>
          <div className="mt-2 overflow-y-scroll grid grid-cols-2 space-x-4 items-start">
            <div className="space-y-3">
              <p>Send free-form text messages, documents, images or videos.</p>
              <ul className="space-y-2">
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Useful once the WhatsApp conversation is opened. No need to
                    keep using templates!
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Once the message is sent, we'll continue executing the next
                    step on the flow.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Send personalized images or documents by using contact
                    variables.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <span className="inline-flex items-center rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-800 mr-1">
                    Soon!
                  </span>
                  <p>Send voice notes, audio, and more.</p>
                </li>
              </ul>
            </div>
            <div className="space-y-4">
              <video
                className="w-60 rounded-lg"
                src="https://hilos-media.s3.us-east-2.amazonaws.com/app/flows/message.mp4"
                controls={false}
                autoPlay={true}
                loop={true}
              />
              <div>
                <a
                  href={STEP_TYPES.MESSAGE.docsLink}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center justify-center rounded bg-indigo-50 px-2.5 py-1.5 text-indigo-600 shadow-sm hover:bg-indigo-100 w-full"
                >
                  Learn more
                  <ArrowSmRightIcon className="h-5 w-5 ml-1" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Trans>
  );
}

export function QuestionStepDescription() {
  return (
    <Trans i18nKey={"flows:step-types.question.help"}>
      <div className="pl-3">
        <div className="text-sm text-gray-700 grid grid-cols-2 space-x-4">
          <div className="overflow-y-scroll items-start">
            <h3 className="font-medium text-gray-800 text-base">
              Ask a question, validate and save the answer.
            </h3>
            <div className="mt-2 space-y-3">
              <p>
                Build your forms with smart validation directly on WhatsApp.
              </p>
              <ul className="space-y-2">
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Validate from single option answers (using buttons or
                    interactive lists) to dates, locations, files and more.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    If the user hasn't answered past some time, add a follow-up
                    message step.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    If the user doesn't answer the way you expect them to, stop
                    waiting for an answer and go to a different step.
                  </p>
                </li>
              </ul>
              <div>
                <a
                  href={STEP_TYPES.QUESTION.docsLink}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center justify-center rounded bg-indigo-50 px-2.5 py-1.5 text-indigo-600 shadow-sm hover:bg-indigo-100 w-full"
                >
                  Learn more
                  <ArrowSmRightIcon className="h-5 w-5 ml-1" />
                </a>
              </div>
            </div>
          </div>
          <div className="space-y-4">
            <video
              className="w-60 rounded-lg"
              src="https://hilos-media.s3.us-east-2.amazonaws.com/app/flows/question.mp4"
              controls={false}
              autoPlay={true}
              loop={true}
            />
          </div>
        </div>
      </div>
    </Trans>
  );
}

export function TagStepDescription() {
  return (
    <Trans i18nKey={"flows:step-types.tag.help"}>
      <div className="pl-3">
        <div className="text-sm text-gray-700">
          <h3 className="font-medium text-gray-800 text-base">
            Tag the contact or conversation
          </h3>
          <div className="mt-2 overflow-y-scroll grid grid-cols-2 space-x-4 items-start">
            <div className="space-y-3">
              <p>
                Use this to add or replace the contact's or the conversation's
                tags.
              </p>
              <ul className="space-y-2">
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    After adding the tags, find this conversation easily in your
                    Inbox using filters.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Use{" "}
                    <strong className="font-medium text-gray-800">
                      Conversation tags
                    </strong>{" "}
                    to distinguish between sales vs support conversations, for
                    example.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Use{" "}
                    <strong className="font-medium text-gray-800">
                      Contact tags
                    </strong>{" "}
                    when it's something related to the contact and not the
                    current or future conversations.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Choose to add tags or replace existing conversation and
                    contact tags.
                  </p>
                </li>
              </ul>
            </div>
            <div className="space-y-4">
              <img
                className="mt-2 rounded-lg"
                src="https://hilos-media.s3.us-east-2.amazonaws.com/app/flows/tag.png"
                alt="tag"
              />

              <div>
                <a
                  href={STEP_TYPES.TAG.docsLink}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center justify-center rounded bg-indigo-50 px-2.5 py-1.5 text-indigo-600 shadow-sm hover:bg-indigo-100 w-full"
                >
                  Learn more
                  <ArrowSmRightIcon className="h-5 w-5 ml-1" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Trans>
  );
}

export function TeamStepDescription() {
  return (
    <Trans i18nKey={"flows:step-types.team.help"}>
      <div className="pl-3">
        <div className="text-sm text-gray-700">
          <h3 className="font-medium text-gray-800 text-base">
            Handoff the conversation to a user from a specific team.
          </h3>
          <div className="mt-2 overflow-y-scroll grid grid-cols-2 space-x-4 items-start">
            <div className="space-y-3">
              <p>
                Use this to have this conversation automatically be assigned to
                someone on the team according to the team's rules.
              </p>
              <ul className="space-y-2">
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Every team can have their own assignment rules. Choose from
                    a round robin assignment, least busy, or default assignee.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Create teams and their assignment rules on{" "}
                    <Link
                      className="text-blue-500"
                      to={buildRoute("config-team-create")}
                    >
                      the team's settings page
                    </Link>
                    .
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    If you want to always assign it to a specific user, use the{" "}
                    <strong className="font-medium text-gray-800">
                      Conversation Step
                    </strong>{" "}
                    instead.
                  </p>
                </li>

                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    The assigned user will see this conversation as unread in
                    the Inbox and will be notified.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Add tags to the conversation or contact in addition to
                    assigning it.
                  </p>
                </li>
              </ul>
            </div>
            <div className="space-y-4">
              <img
                className="rounded-lg"
                src="https://hilos-media.s3.us-east-2.amazonaws.com/app/flows/conversation.png"
                alt="inbox"
              />
              <img
                className="mt-2 rounded-lg"
                src="https://hilos-media.s3.us-east-2.amazonaws.com/app/flows/team.png"
                alt="step"
              />

              <div>
                <a
                  href={STEP_TYPES.TEAM.docsLink}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center justify-center rounded bg-indigo-50 px-2.5 py-1.5 text-indigo-600 shadow-sm hover:bg-indigo-100 w-full"
                >
                  Learn more
                  <ArrowSmRightIcon className="h-5 w-5 ml-1" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Trans>
  );
}

export function TemplateStepDescription() {
  return (
    <Trans i18nKey={"flows:step-types.template.help"}>
      <div className="pl-3">
        <div className="text-sm text-gray-700">
          <h3 className="font-medium text-gray-800 text-base">
            Send a WhatsApp Template
          </h3>
          <div className="mt-2 overflow-y-scroll grid grid-cols-2 space-x-4 items-start">
            <div className="space-y-3">
              <p>
                Useful when you want to send a campaign, or restart a
                conversation after a delay.
              </p>
              <ul className="space-y-2">
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Use buttons to help your users find what they need faster.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Choose if you want to save the user's answer and validate
                    it.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    If the user hasn't answered past some time, add a follow-up
                    message step.
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    If the user doesn't answer the way you expect them to, stop
                    waiting for an answer and go to a different step.
                  </p>
                </li>
              </ul>
            </div>
            <div className="space-y-4">
              <img
                className="w-60 rounded-lg"
                src="https://hilos-media.s3.us-east-2.amazonaws.com/app/flows/template.png"
                alt="template"
              />
              <div>
                <a
                  href={STEP_TYPES.TEMPLATE.docsLink}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center justify-center rounded bg-indigo-50 px-2.5 py-1.5 text-indigo-600 shadow-sm hover:bg-indigo-100 w-full"
                >
                  Learn more
                  <ArrowSmRightIcon className="h-5 w-5 ml-1" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Trans>
  );
}

export function TriggerFlowStepDescription() {
  return (
    <Trans i18nKey={"flows:step-types.trigger-flow.help"}>
      <div className="pl-3">
        <div className="text-sm text-gray-700">
          <h3 className="font-medium text-gray-800 text-base">
            <span className="inline-flex items-center rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-800 mr-1">
              Advanced
            </span>{" "}
            Finish this flow and trigger another one.
          </h3>
          <div className="mt-2 overflow-y-scroll grid grid-cols-2 space-x-4 items-start">
            <div className="space-y-3">
              <p>
                Make common parts of your flow reusable, define them once and
                trigger them from multiple flows.
              </p>
              <ul className="space-y-2">
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    When we trigger another flow, this flow will be marked as{" "}
                    <strong className="font-medium text-gray-800">
                      Complete
                    </strong>
                    , while the triggered flow will be{" "}
                    <strong className="font-medium text-gray-800">
                      Running
                    </strong>
                    .
                  </p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>You can trigger any flow you'd like.</p>
                </li>
                <li className="relative flex items-start">
                  <CheckIcon className="text-green-500 mr-1 h-5 w-5 shrink-0" />
                  <p>
                    Keep in mind that variables from this flow won't be
                    available in the triggered flow, so use the{" "}
                    <strong className="font-medium text-gray-800">
                      Contact Update step
                    </strong>{" "}
                    to save them.
                  </p>
                </li>
              </ul>
            </div>
            <div className="space-y-4">
              <img
                className="w-60 rounded-lg"
                src="https://hilos-media.s3.us-east-2.amazonaws.com/app/flows/trigger.png"
                alt="trigger-flow"
              />
              <div>
                <a
                  href={STEP_TYPES.TRIGGER_FLOW.docsLink}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center justify-center rounded bg-indigo-50 px-2.5 py-1.5 text-indigo-600 shadow-sm hover:bg-indigo-100 w-full"
                >
                  Learn more
                  <ArrowSmRightIcon className="h-5 w-5 ml-1" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Trans>
  );
}
