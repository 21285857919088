import useHilosStore from "src/hooks/useHilosStore";
import FreeTrialDaysRemainingNotice from "./FreeTrialDaysRemainingNotice";
import SubscriptionIncompleteNotice from "./SubscriptionIncompleteNotice";
import SubscriptionPaymentFailedNotice from "./SubscriptionPaymentFailedNotice";

export default function NoticeManager() {
  const { session } = useHilosStore();

  if (!session || !session.account) {
    return null;
  }

  if (
    ["incomplete", "canceled"].includes(
      session.account.current_subscription?.status || ""
    )
  ) {
    return <SubscriptionIncompleteNotice />;
  }

  if (
    ["past_due", "unpaid"].includes(
      session.account.current_subscription?.status || ""
    )
  ) {
    return <SubscriptionPaymentFailedNotice />;
  }

  if (
    !session.account.current_subscription &&
    session.account.remaining_free_trial_days < 4
  ) {
    return <FreeTrialDaysRemainingNotice />;
  }

  // Commenting these notices because we need to fix Inbox CSS issues first,
  // otherwise these notices push the entire interface down and people can't
  // use it.
  // if (
  //   matchPath([
  //     buildRoute("config-account"),
  //     buildRoute("contact-list"),
  //     buildRoute("flow-list"),
  //     "/inbox",
  //     buildRoute("message-blast-list"),
  //     buildRoute("whatsapp-template-list"),
  //   ],pathname)
  // ) {
  //   return <AccountStatusNotice />;
  // }

  return null;
}
