import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CalendarIcon,
  ChatAltIcon,
  ChatIcon,
  ClipboardListIcon,
  PencilAltIcon,
  TagIcon,
  TrashIcon,
  UserIcon,
} from "@heroicons/react/outline";
import axios, { AxiosError } from "axios";
import { format } from "date-fns";
import { CursorPageData } from "@hilos/types/hilos";
import { PatchedContactEdit } from "@hilos/types/private-schema";
import { WhatsAppTemplate } from "@hilos/types/wa/templates";
import DropdownMenu from "src/components/DropdownMenu";
import Loading from "src/components/Loading";
import { getRenderedMessage } from "src/helpers/template";
import { useCustomFields } from "src/hooks/useContactCustomField";
import useHilosStore from "src/hooks/useHilosStore";
import { API_ROUTES, buildAPIRoute } from "src/router/router";
import { classNames, userToString } from "../../../Helpers";
import { hasItems } from "../../../helpers/utils";
import { QuickReplyData } from "../../../hooks/useQuickReplies";
import InfiniteScroll from "./InfiniteScroll";
import QuickReplyTemplateSendModal from "./QuickReplyTemplateSendModal";

interface QuickRepliesListProps {
  pages: (CursorPageData<QuickReplyData> | null)[] | null;
  isLoading?: boolean;
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
  onNextPage: () => void;
  onSetMessage: (text: string) => void;
  onDeleteQuickReply: (id: string) => Promise<void>;
  onSelectEditQuickReply: (qr: QuickReplyData) => void;
  contact: PatchedContactEdit | null | undefined;
  onCloseModal: () => void;
  setOpenUpdateModal: (f: boolean) => void;
  setBackendValidationErrors: Dispatch<SetStateAction<{}>>;
  setBackendError: Dispatch<SetStateAction<string>>;
  setSelectedTemplate: (t: WhatsAppTemplate | undefined) => void;
  selectedTemplate?: WhatsAppTemplate;
  setSelectedQuickReply: (t: QuickReplyData | undefined) => void;
  selectedQuickReply?: QuickReplyData;
  setIsUpdate: (f: boolean) => void;
}

function QuickRepliesList({
  pages,
  isLoading,
  isFetchingNextPage,
  hasNextPage,
  onNextPage,
  onSetMessage,
  onDeleteQuickReply,
  onSelectEditQuickReply,
  onCloseModal,
  contact,
  setOpenUpdateModal,
  setBackendValidationErrors,
  setBackendError,
  setSelectedTemplate,
  selectedTemplate,
  setSelectedQuickReply,
  setIsUpdate,
}: QuickRepliesListProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [loadingTemplate, setLoadingTemplate] = useState<boolean>(false);
  const { session } = useHilosStore();
  const [templateVariables, setTemplateVariables] = useState<string[]>([]);
  const { contactCustomFields } = useCustomFields();

  const getRenderedVariables = (variables) => {
    const vars = JSON.parse(JSON.stringify(variables));

    if (contact && session) {
      Object.entries(vars).forEach(([key, value]) => {
        if (Array.isArray(value))
          value.map(
            (val, idx) =>
              (vars[key][idx] = getRenderedMessage(
                val,
                contact,
                session,
                contactCustomFields
              ))
          );
        else if (typeof value === "string")
          vars[key] = getRenderedMessage(
            value,
            contact,
            session,
            contactCustomFields
          );
      });

      return vars;
    }
    return vars;
  };

  const handleSelectQuickReplyTemplate = async (
    quickReply: QuickReplyData,
    isEdit?: boolean
  ) => {
    setSelectedTemplate(undefined);
    setBackendError("");
    setBackendValidationErrors({});
    setLoadingTemplate(true);
    setSelectedQuickReply(quickReply);

    try {
      const { data } = await axios.get(
        buildAPIRoute(API_ROUTES.WHATSAPP_TEMPLATE, {
          ":id": quickReply.template?.id,
        })
      );
      if (data) {
        setSelectedTemplate(data);
        if (quickReply.template_variables) {
          if (!isEdit) {
            setTemplateVariables(
              getRenderedVariables(quickReply.template_variables)
            );
          } else {
            setTemplateVariables(quickReply.template_variables);
          }
        }
        if (isEdit) {
          setOpenUpdateModal(true);
          setIsUpdate(isEdit);
        }
        setOpen(true);
      }
    } catch (err) {
      type axiosErr =
        | AxiosError<{ status?: number; data?: unknown }, unknown>
        | undefined;
      const errorAxios: axiosErr = err as axiosErr;

      if (errorAxios?.response?.status === 404) {
        setBackendError(
          t(
            "inbox:quick-replies.template-not-found-error",
            "Template not found, be sure this template is still available in your template list."
          )
        );
      } else if (errorAxios?.response?.status === 400) {
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError(
          t(
            "inbox:quick-replies.template-general-error",
            "There was an error loading this template."
          )
        );
      }
    } finally {
      setLoadingTemplate(false);
    }
  };

  const onCancelSend = () => {
    setOpen(false);
    setSelectedQuickReply(undefined);
    setSelectedTemplate(undefined);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (pages && pages.length > 0) {
    return (
      <>
        <InfiniteScroll
          className="h-auto grow"
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
          onNextPage={onNextPage}
        >
          <>
            {pages.map(
              (page, pageIndex) =>
                page &&
                hasItems(page.results) &&
                page.results.map((reply, index) => (
                  <div key={`${reply.id}_${pageIndex}_${index}`}>
                    <div className="py-3 px-4 hover:bg-gray-100 sm:px-6">
                      <div className="align-center flex justify-between">
                        {reply.text ? (
                          <button
                            type="button"
                            className="mb-1 flex w-full items-center truncate text-left text-sm font-medium text-indigo-600"
                            onClick={(_) => {
                              if (reply.text) onSetMessage(reply.text);
                            }}
                          >
                            <>
                              <span className="mr-2 rounded-lg bg-green-50 p-1 text-xs font-medium text-green-700">
                                <ChatIcon
                                  className=" h-4 w-4"
                                  aria-hidden="true"
                                />
                              </span>
                              {reply.name || reply.text}
                            </>
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="mb-1 flex w-full items-center truncate text-left text-sm font-medium text-indigo-600"
                            onClick={() =>
                              handleSelectQuickReplyTemplate(reply)
                            }
                          >
                            <>
                              <span className="mr-2 rounded-lg bg-blue-50 p-1 text-xs font-medium text-blue-700">
                                <ClipboardListIcon
                                  className=" h-4 w-4"
                                  aria-hidden="true"
                                />
                              </span>
                              {reply.name || reply.template_name}
                            </>
                          </button>
                        )}
                        <div>
                          <DropdownMenu
                            menuItems={[
                              (active) => (
                                <button
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "group flex w-full items-center px-4 py-2 text-left text-sm"
                                  )}
                                  type="button"
                                  onClick={(_) => {
                                    reply.text
                                      ? onSelectEditQuickReply(reply)
                                      : handleSelectQuickReplyTemplate(
                                          reply,
                                          true
                                        );
                                  }}
                                >
                                  <PencilAltIcon
                                    className="-ml-0.5 mr-2 h-4 w-4"
                                    aria-hidden="true"
                                  />
                                  {t("edit", "Edit")}
                                </button>
                              ),
                              (active) => (
                                <button
                                  className={classNames(
                                    active
                                      ? "bg-red-100 text-red-800"
                                      : "text-gray-700",
                                    "group flex w-full items-center px-4 py-2 text-sm"
                                  )}
                                  type="button"
                                  onClick={(_) => onDeleteQuickReply(reply.id)}
                                >
                                  <TrashIcon
                                    className="-ml-0.5 mr-2 h-4 w-4"
                                    aria-hidden="true"
                                  />
                                  {t("delete", "Delete")}
                                </button>
                              ),
                            ]}
                          />
                        </div>
                      </div>
                      {reply.tags && hasItems(reply.tags) && (
                        <p className="flex items-center text-xs text-gray-500">
                          <TagIcon
                            className="mr-1 h-4 w-4 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          {reply.tags.map(({ name }) => name).join(", ")}
                        </p>
                      )}
                      <div className="mt-2 flex items-center justify-between space-x-2">
                        <p className="flex items-center text-xs text-gray-500">
                          <UserIcon
                            className="mr-1 h-4 w-4 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          {t("created-by", "Created by")}{" "}
                          {userToString(reply.created_by)}
                        </p>
                        <div className="flex items-center text-xs text-gray-500">
                          <CalendarIcon
                            className="mr-1 h-4 w-4 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          <p>
                            {t("created-on", "Created on")}{" "}
                            <time dateTime={reply.created_on}>
                              {format(new Date(reply.created_on), "d/M/yyyy")}
                            </time>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
            )}
          </>
        </InfiniteScroll>
        <QuickReplyTemplateSendModal
          contact={contact}
          setOpen={setOpen}
          open={open}
          setSelectedTemplate={setSelectedTemplate}
          selectedTemplate={selectedTemplate}
          loading={loadingTemplate}
          onCloseQKModal={onCloseModal}
          onCancel={onCancelSend}
          variables={templateVariables}
        />
      </>
    );
  }

  return (
    <div className="py-5 text-center">
      <ChatAltIcon className="mx-auto h-12 w-12 text-gray-400" />
      <h3 className="mt-2 text-sm font-medium text-gray-900">
        No quick replies found.
      </h3>
    </div>
  );
}

export default QuickRepliesList;
