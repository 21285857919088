import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UploadIcon } from "@heroicons/react/outline";
import { DateTime } from "luxon";
import { ContactImport } from "@hilos/types/private-schema";
import Table from "src/components/Table/Table";
import { userToString } from "src/Helpers";
import { API_ROUTES, buildAPIRoute, buildRoute } from "../../router/router";
import ContactImportStatusBadge from "./ContactImportStatusBadge";

export default function ContactImportList() {
  const { t } = useTranslation();
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="h-full sm:px-6 sm:pt-8 lg:px-8">
        <Table<ContactImport>
          baseURL={buildAPIRoute(API_ROUTES.CONTACT_IMPORT_LIST)}
          paginated={true}
          tableHead={
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-4 py-3 text-left max-w-[400px] text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("import", "Importación")}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("status", "Status")}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("created-on", "Created on")}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("created-by", "Created by")}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("contacts:contacts-total", "Contacts total")}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("contacts:contacts-created", "Contacts created")}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("contacts:contacts-updated", "Contacts updated")}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                >
                  {t("contacts:contacts-ignored", "Contacts ignored")}
                </th>
              </tr>
            </thead>
          }
          tableRow={(contactImport) => (
            <>
              <td className="max-w-[400px] truncate whitespace-nowrap px-4 py-3 text-sm text-gray-900">
                <Link
                  className="font-medium text-blue-500"
                  to={buildRoute("contact-import-detail", {
                    id: contactImport.id,
                  })}
                >
                  {contactImport.name}
                </Link>
              </td>
              <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                <ContactImportStatusBadge status={contactImport.status} />
              </td>
              <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                <time className="text-muted" dateTime="2020-03-22">
                  {DateTime.fromISO(
                    contactImport.timestamp as string
                  ).toLocaleString(DateTime.DATETIME_SHORT)}
                </time>
              </td>
              <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                {userToString(contactImport.created_by)}
              </td>
              <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                {contactImport.results_total}
              </td>
              <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                {contactImport.results_created}
              </td>
              <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                {contactImport.results_updated}
              </td>
              <td className="text-info-600 whitespace-nowrap px-4 py-3 text-sm">
                {contactImport.results_ignored}
              </td>
            </>
          )}
          dataName={t("csv-imports", "CSV Imports")}
          noResults={
            <>
              <Trans i18nKey="contacts:no-imports-with-filters">
                <h4>Couldn't find any imports with these filters.</h4>
                <p className="text-muted">Remove filters to try again.</p>
              </Trans>
            </>
          }
          createObject={
            <>
              <Trans i18nKey="contacts:no-imports-yet">
                <h4>Looks like you haven't imported any contacts yet.</h4>
                <p className="text-sm text-gray-500">
                  Why don't you upload a .csv?
                </p>
                <Link
                  to={buildRoute("contact-upload-csv")}
                  className="mt-2 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <UploadIcon
                    className="-ml-1 mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  Upload CSV
                </Link>
              </Trans>
            </>
          }
        ></Table>
      </div>
    </div>
  );
}
