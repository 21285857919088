import { Fragment, HTMLProps } from "react";
import { useTranslation } from "react-i18next";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/outline";
import { getAvailableChannelData } from "src/containers/config/channel/Meta";
import { useChannelAvailability } from "src/hooks/useChannel";
import useHilosStore from "src/hooks/useHilosStore";
import { classNames, formatPhone } from "src/Helpers";

interface ChannelFilterFieldProps {
  handleOptionChange: (nextChannels: ChannelChoice[] | ChannelChoice) => void;
  showLabel?: boolean;
  selected: number[];
  isMultiple?: boolean;
}

export interface ChannelChoice {
  icon?: (props: HTMLProps<HTMLElement>) => Element;
  baseColor: string;
  label: string;
  help: string;
  value: number;
  selected: boolean;
}

export default function ChannelFilterField({
  handleOptionChange,
  showLabel = false,
  selected = [],
  isMultiple = true,
}: ChannelFilterFieldProps) {
  const { t } = useTranslation();
  const { data: channels } = useChannelAvailability();
  const { session } = useHilosStore();
  //@ts-ignore
  const choices: ChannelChoice[] = channels.map((ch) => {
    const channelData = getAvailableChannelData(ch.channel);
    return {
      icon: channelData?.icon,
      baseColor: channelData?.baseColor,
      label: ch.channel.name,
      help: formatPhone(ch.channel.channel_id, true, true),
      value: ch.channel.id,
      selected:
        session?.account.default_channel === ch.channel.id ||
        (isMultiple && selected.includes(ch.channel.id)),
    };
  });

  const onChangeSelection = (channels: ChannelChoice[] | ChannelChoice) => {
    if (Array.isArray(channels)) {
      handleOptionChange(channels);
    } else {
      handleOptionChange([channels]);
    }
  };

  const selectedChannels: ChannelChoice[] = selected
    .map((channelId) => choices.find((choice) => choice.value === channelId))
    .filter((x) => x !== undefined) as ChannelChoice[];

  return (
    <Listbox
      value={selectedChannels}
      onChange={onChangeSelection}
      by="value"
      multiple={isMultiple}
    >
      {({ open }) => (
        <div className="flex items-center space-x-2">
          {showLabel && (
            <Listbox.Label className="block text-sm font-medium text-gray-700">
              Channel
            </Listbox.Label>
          )}
          <div className="group relative w-full">
            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-pink-50 focus:outline-none focus:ring-1 focus:ring-pink-50 sm:text-sm">
              {selectedChannels?.length === 0 && (
                <span className="text-gray-500">
                  {t("channel:showing-all-channels", "Showing all channels")}
                </span>
              )}
              {selectedChannels?.length === 1 && (
                <>
                  {selectedChannels.map((channel) => (
                    <span className="flex items-center" key={channel.value}>
                      {/* @ts-ignore */}
                      <channel.icon className="mr-1 h-3 w-3 self-center rounded-full bg-green-100 p-1 text-lg text-green-800" />
                      <div className="block truncate">
                        {channel.label}
                        <span className="ml-2 text-xs text-gray-500">
                          {channel.help}
                        </span>
                      </div>
                    </span>
                  ))}
                </>
              )}

              {selectedChannels?.length > 1 && (
                <div className="flex items-center">
                  <div className="flex grow-0 -space-x-1 overflow-hidden">
                    {selectedChannels.map((channel) => (
                      <div
                        key={channel.value}
                        className="flex justify-center rounded-full bg-green-100 ring-2 ring-white"
                      >
                        {/* @ts-ignore */}
                        <channel.icon className="h-3 w-3 self-center rounded-full bg-green-100 p-1 text-green-800" />
                      </div>
                    ))}
                  </div>
                  <span className="ml-2">
                    {/* t("channel:channels-selected_other", "{{count}} channels selected") */}
                    {t("channel:channels-selected", { count: selected.length })}
                  </span>
                </div>
              )}
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {choices.map((channel) => (
                  <Listbox.Option
                    key={channel.value}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-pink-50 text-pink-700" : "text-gray-900",
                        "relative cursor-default select-none py-2 px-3"
                      )
                    }
                    value={channel}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          {/* @ts-ignore */}
                          <channel.icon className="mr-3 h-5 w-5 self-center rounded-full bg-green-100 p-1 text-lg text-green-800" />
                          <div className="block grow space-y-1 truncate">
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal"
                              )}
                            >
                              {channel.label}
                            </span>
                            <span className="block truncate text-xs text-gray-500">
                              {channel.help}
                            </span>
                          </div>
                          <span className="pl-2">
                            {selected && (
                              <CheckIcon className="h-5 w-5 text-pink-700" />
                            )}
                            {/* <input
                              type="checkbox"
                              checked={selected}
                              disabled={true}
                              onChange={toggleCheckbox}
                              className="h-4 w-4 rounded border-gray-300 text-pink-50 focus:ring-pink-50"
                            /> */}
                          </span>
                        </div>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
}
