import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { BadgeCheckIcon, InboxIcon, PlusSmIcon } from "@heroicons/react/solid";
import { SegmentedControl } from "@radix-ui/themes";
import { Theme } from "@radix-ui/themes";
import { Inbox, Play } from "lucide-react";
import EmptyStateComponent from "src/components/EmptyStates/EmptyStateComponent";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { hasItems } from "src/helpers/utils";
import useUserTask, { TaskView } from "src/hooks/useUserTasks";
import { contactToString } from "src/Helpers";
import { buildRoute } from "src/router/router";
import UnorderedList from "./UnorderedList";
import UserTaskListPagination from "./UserTaskListPagination";

export default function UserTaskList() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams({
    view: "today",
    page: "1",
  });
  const view = (searchParams.get("view") ?? "today") as TaskView;
  const page = Number(searchParams.get("page") || 1);
  const search = searchParams.get("search") ?? "";
  const {
    data: paginatedData,
    isLoading,
    updateUserTaskDetails,
  } = useUserTask(view, page);
  const handleUpdateParams = useCallback(
    (key, value) => {
      searchParams.set(key, value);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const handleViewChange = useCallback(
    (value) => {
      handleUpdateParams("view", value);
      handleUpdateParams("page", "1");
    },
    [handleUpdateParams]
  );

  return (
    <Theme className="bg-gray-50 min-h-fit">
      <div className="flex flex-col bg-gray-50 ">
        <div className="sm:hidden flex items-center align-middle justify-center">
          <Select
            defaultValue="today"
            value={view}
            onValueChange={handleViewChange}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Today" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="today">
                {t("dashboard:user-task.views.today", "Today")}
              </SelectItem>
              <SelectItem value="coming-up">
                {t("dashboard:user-task.views.coming-up", "Coming up")}
              </SelectItem>
              <SelectItem value="no-due-date">
                {t("dashboard:user-task.views.no-due-date", "No due date")}
              </SelectItem>
              <SelectItem value="past-due">
                {t("dashboard:user-task.views.past-due", "Past due")}
              </SelectItem>
              <SelectItem value="complete">
                {t("dashboard:user-task.views.complete", "Complete")}
              </SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className="hidden sm:flex justify-center mt-2">
          <SegmentedControl.Root
            size="1"
            defaultValue="today"
            value={view}
            onValueChange={handleViewChange}
          >
            <SegmentedControl.Item value="today">
              {t("dashboard:user-task.views.today", "Today")}
            </SegmentedControl.Item>
            <SegmentedControl.Item value="coming-up">
              {t("dashboard:user-task.views.coming-up", "Coming up")}
            </SegmentedControl.Item>
            <SegmentedControl.Item value="no-due-date">
              {t("dashboard:user-task.views.no-due-date", "No due date")}
            </SegmentedControl.Item>
            <SegmentedControl.Item value="past-due">
              {t("dashboard:user-task.views.past-due", "Past due")}
            </SegmentedControl.Item>
            <SegmentedControl.Item value="complete">
              {t("dashboard:user-task.views.complete", "Complete")}
            </SegmentedControl.Item>
          </SegmentedControl.Root>
        </div>
      </div>
      <div className="pt-2 bg-gray-50 flex flex-col justify-center items-center">
        <ul className="space-y-3 w-full">
          {paginatedData &&
            hasItems(paginatedData.results) &&
            paginatedData.results &&
            paginatedData.results.map((item) => (
              <li
                key={item.id}
                className="overflow-hidden bg-white px-4 py-4 shadow sm:rounded-md sm:px-6"
              >
                <div className="flex">
                  <div className="relative flex items-start w-full">
                    <div className="flex h-6 items-center align-middle">
                      <input
                        id={item.id}
                        name="comments"
                        type="checkbox"
                        aria-describedby={`description-${item.id}`}
                        checked={item.done}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        onChange={(ev) => {
                          updateUserTaskDetails({
                            ...item,
                            done: ev.target.checked,
                          });
                        }}
                      />
                    </div>
                    <div className="flex flex-col ml-3 w-full">
                      <div className="font-medium flex ">
                        <h6 className="text-sm font-bold basis-3/4 flex-1 items-center justify-center">
                          {item.title}
                        </h6>
                        <p className="basis-1/4 flex-1 font-light text-xs flex items-center justify-center">
                          {item.link && (
                            <Link
                              to={item.link}
                              className="flex justify-around items-center"
                            >
                              <span className="hidden sm:inline">
                                {/* +524499806467 */}
                                {item.inbox_contact &&
                                  item.inbox_contact.contact &&
                                  item.inbox_contact.contact.canonical_phone}
                              </span>
                              <Inbox className="h-5 w-5 sm:h-3 sm:w-3 sm:m- text-gray-500 m-3" />
                            </Link>
                          )}
                        </p>
                      </div>
                      <div className="text-sm/6">
                        <span
                          id={`description-${item.id}`}
                          className="text-gray-500"
                        >
                          <UnorderedList text={item.description} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
        </ul>
        {paginatedData && paginatedData.count > 0 ? (
          <UserTaskListPagination
            searchParams={searchParams}
            results={paginatedData?.results ?? []}
            count={paginatedData?.count ?? 0}
            updateSearchParams={handleUpdateParams}
            dataName="Task"
            MAX_RESULTS_PER_PAGE={5}
          />
        ) : (
          <EmptyStateComponent
            routeName="ai_tasks"
            showInfo={false}
            showDocumentation={false}
          >
            {/* <Link
              // to="https://rsvp.withgoogle.com/events/google-for-startups-accelerator-ai-first-latam"
              to="https://www.youtube.com/watch?v=_4wuHwhUSM8"
              target="_blank"
              className="mt-2 inline-flex items-center rounded-md border border-transparent bg-hilos px-4 py-2 text-sm font-medium text-white shadow-sm focus:ring-offset-2"
            >
              <Play className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              View AI First Latam Demo Day 2024
            </Link> */}
          </EmptyStateComponent>
        )}
      </div>
    </Theme>
  );
}
