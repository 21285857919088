"use client";

import { useCallback, useMemo, useState } from "react";
import { ChevronsUpDown } from "lucide-react";
import {
  WorkflowStateRead,
  WorkflowTaskEdit,
} from "@hilos/types/private-schema";
import { Button } from "src/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "src/components/ui/command";
import { Drawer, DrawerContent, DrawerTrigger } from "src/components/ui/drawer";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import { useMediaQuery } from "src/hooks/useMediaQuery";
import {
  WorkflowBoardStore,
  useWorkflowBoardContext,
} from "./WorkflowBoardContext";

interface SelectStatusProps {
  currentStateId: string;
  onUpdateWorkflowTask: (task: Partial<WorkflowTaskEdit>) => void;
}

export default function SelectStatus({
  currentStateId,
  onUpdateWorkflowTask,
}: SelectStatusProps) {
  const [open, setOpen] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const { states } = useWorkflowBoardContext<WorkflowBoardStore>();

  const state = useMemo(
    () => states.find((state) => state.id === currentStateId) || null,
    [states, currentStateId]
  );

  const handleChangeTaskState = useCallback(
    (state: string) => {
      onUpdateWorkflowTask({ state });
    },
    [onUpdateWorkflowTask]
  );

  if (!state) {
    return null;
  }

  if (isDesktop) {
    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button variant="ghost" className="max-w-[150px] justify-start">
            <span className="truncate uppercase">{state.name}</span>
            <ChevronsUpDown className="ml-auto size-4" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0" align="end">
          <StatusList
            states={states}
            setOpen={setOpen}
            onChange={handleChangeTaskState}
          />
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger asChild>
        <Button variant="secondary" className="max-w-[150px] justify-start">
          <span className="truncate">{state.name}</span>
          <ChevronsUpDown className="ml-auto size-4" />
        </Button>
      </DrawerTrigger>
      <DrawerContent>
        <div className="mt-4 border-t">
          <StatusList
            states={states}
            setOpen={setOpen}
            onChange={handleChangeTaskState}
          />
        </div>
      </DrawerContent>
    </Drawer>
  );
}

function StatusList({
  states,
  setOpen,
  onChange,
}: {
  states: WorkflowStateRead[];
  setOpen: (open: boolean) => void;
  onChange: (stateId: string) => void;
}) {
  return (
    <Command>
      <CommandInput
        placeholder="Filter status..."
        className="border-none focus:outline-none focus:ring-0"
      />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup>
          {states.map((state) => (
            <CommandItem
              key={state.id}
              value={state.id}
              // disabled
              onSelect={(value) => {
                const selectedState = states.find(({ id }) => id === value);

                if (selectedState) {
                  onChange(selectedState.id);
                  setOpen(false);
                }
              }}
            >
              {state.name}
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );
}
