import { useCallback } from "react";
import { UseMutateAsyncFunction, useQuery } from "react-query";
import axios from "axios";
import { PaginatedUserTaskList, UserTask } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "src/router/router";

export type TaskView =
  | "today"
  | "drafts"
  | "no-due-date"
  | "complete"
  | "past-due";

export type UpdateOpenAIDetailsFn = UseMutateAsyncFunction<
  UserTask | null,
  unknown,
  UserTask,
  unknown
>;

export function useUserTask(view: TaskView, page: number) {
  const fetchUserTask = useCallback(async () => {
    const { data } = await axios.get<PaginatedUserTaskList>(
      API_ROUTES.USER_TASK_LIST,
      {
        params: {
          view,
          page,
          page_size: 5,
        },
      }
    );
    return data;
  }, [page, view]);

  const { data, refetch, isLoading } = useQuery(
    ["hilos_task", view, page],
    fetchUserTask,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
    }
  );

  const updateUserTaskDetails = useCallback(
    async (payload: UserTask) => {
      const { id, ...rest } = payload;
      const { data } = await axios.patch<UserTask>(
        buildAPIRoute(API_ROUTES.USER_TASK_DETAIL, {
          ":id": id,
        }),
        payload
      );
      refetch();
      return data;
    },
    [refetch]
  );

  return { data, refetch, isLoading, updateUserTaskDetails };
}

export default useUserTask;
