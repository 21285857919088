import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useMatch,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  ChatAlt2Icon,
  CogIcon,
  UserCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import Loading from "src/components/Loading";
import { classNames } from "../../../Helpers";
import useInboxContactDetails from "../../../hooks/useInboxContactDetails";
import ActionsTab from "./ActionTab/ActionsTab";
import ContactTab from "./ContactTab/ContactTab";
import ConversationDetailsHeader from "./ConversationDetailsHeader";
import ConversationTab from "./ConversationTab/ConversationTab";

const TABS = [
  {
    // t("inbox:tabs.label.conversation", "Conversation")
    name: "conversation",
    label: "inbox:tabs.label.conversation",
    icon: <ChatAlt2Icon className="mr-1 h-4 w-4" />,
    hideOnLgScreens: false,
  },
  {
    // t("inbox:tabs.label.contact", "Contact")
    name: "contact",
    label: "inbox:tabs.label.contact",
    icon: <UserCircleIcon className="mr-1 h-4 w-4" />,
    hideOnLgScreens: false,
  },
  {
    // t("inbox:tabs.label.actions", "Actions")
    name: "actions",
    label: "inbox:tabs.label.actions",
    icon: <CogIcon className="h-4 w-4" />,
    hideOnLgScreens: true,
  },
];

function ConversationDetails() {
  const { t } = useTranslation();
  const isFocusedWithMessage = useMatch(
    "/inbox/conversation/:inboxContactId/message/:messageId/details"
  );
  const isFocused =
    useMatch("/inbox/conversation/:inboxContactId/details") ||
    isFocusedWithMessage;
  const { inboxContactId } = useParams();
  const navigate = useNavigate();
  const {
    inboxContact,
    isLoading,
    handleSwitchArchived,
    handleSwitchSilenced,
    handleUpdateConversation,
  } = useInboxContactDetails({ inboxContactId });

  const [searchParams, setSearchParams] = useSearchParams();

  const [currentTab, setCurrentTab] = useState(
    searchParams.get("tab") || "conversation"
  );

  const handleClose = useCallback(() => {
    if (inboxContactId) {
      navigate("/inbox/conversation/:id".replace(":id", inboxContactId));
    }
  }, [navigate, inboxContactId]);

  const handleTabClick = (tabName) => {
    setCurrentTab(tabName);
    searchParams.set("tab", tabName);
    setSearchParams(searchParams);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!inboxContact) {
    return null;
  }

  return (
    <aside
      className={`flex h-full flex-1 ${
        isFocused ? "w-full flex-shrink-0 lg:w-72" : "hidden lg:block"
      }`}
      data-tour="convo-details"
    >
      <div className="relative flex h-full w-full flex-col overflow-y-auto bg-gray-100 lg:w-72">
        <div>
          {/* Close button */}
          <div className="absolute top-0 right-0 block p-3 lg:hidden">
            <button
              type="button"
              className="rounded-md text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={handleClose}
            >
              <span className="sr-only">Close</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>

          <div className="px-3 py-3 sm:px-4 mt-1">
            <ConversationDetailsHeader
              contact={inboxContact.contact}
              silenced={inboxContact.is_silenced}
              archived={inboxContact.is_archived}
              lastInboundMessageOn={inboxContact.last_inbound_message_on}
              onSwitchArchived={handleSwitchArchived}
              onSwitchSilenced={handleSwitchSilenced}
            />
          </div>
          <div className="-mt-2">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex" aria-label="Tabs">
                {TABS.map((tab) => (
                  <button
                    key={tab.name}
                    data-tour={`tab-${tab.name.toLowerCase()}-conversation-details`}
                    type="button"
                    onClick={() => handleTabClick(tab.name)}
                    className={classNames(
                      tab.name === currentTab
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      "flex grow items-center justify-center border-b-2 py-2 px-2 text-center text-sm font-medium"
                    )}
                    aria-current={tab.name === currentTab ? "page" : undefined}
                  >
                    {tab.icon}
                    <span
                      className={tab.hideOnLgScreens ? "ml-1 lg:hidden" : ""}
                    >
                      {t(tab.label, tab.name)}
                    </span>
                  </button>
                ))}
              </nav>
            </div>
          </div>

          <div className="flex flex-1 space-y-5" data-tour="convo-detail-tab">
            {currentTab === "conversation" && (
              <ConversationTab
                inboxContact={inboxContact}
                handleUpdateConversation={handleUpdateConversation}
              />
            )}
            {currentTab === "contact" && (
              <ContactTab contactId={inboxContact.contact.id} />
            )}
            {currentTab === "actions" && (
              <ActionsTab inboxContact={inboxContact} />
            )}
          </div>
        </div>
      </div>
    </aside>
  );
}

export default ConversationDetails;
