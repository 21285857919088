import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { X } from "lucide-react";
import ContactTab from "src/containers/inbox/Conversation/ContactTab/ContactTab";
import ConversationDetailsHeader from "src/containers/inbox/Conversation/ConversationDetailsHeader";
import Loading from "src/components/Loading";
import { Button } from "src/components/ui/button";
import useWorkflowTaskDetails from "src/hooks/useWorkflowTaskDetails";
import { buildRoute } from "src/router/router";

export default function WorkflowBoardTaskDetails() {
  const [t] = useTranslation();
  const { taskId, stateId, workflowId } = useParams() as {
    taskId: string;
    stateId: string;
    workflowId: string;
  };

  const { task, isLoading } = useWorkflowTaskDetails({
    taskId,
    workflowId,
  });

  if (isLoading) {
    return <Loading />;
  }

  if (!task) {
    return null;
  }

  return (
    <motion.div
      initial={{ opacity: 0, width: 0 }}
      animate={{ opacity: 1, width: "auto" }}
      exit={{
        opacity: 0,
        width: 0,
      }}
      transition={{
        opacity: { duration: 0.6 },
        width: { duration: 0.4 },
        layout: { duration: 0.3 },
      }}
      layout
    >
      <div className="flex flex-col w-full lg:w-72 h-full overflow-hidden pt-0 space-y-4 bg-gray-100 border-l border-gray-200">
        <div className="flex flex-1 items-center flex-row w-full py-2">
          <Button variant="ghost" size="icon" className="p-1">
            <Link
              to={buildRoute("workflow-task", { workflowId, stateId, taskId })}
            >
              <X className="h-4 w-4" />
            </Link>
          </Button>
          <h3 className="font-medium">
            {t("workflow:task.contact-information", "Contact Information")}
          </h3>
        </div>
        <div className="flex flex-col h-full overflow-y-auto">
          <ConversationDetailsHeader
            contact={task.inbox_contact.contact}
            silenced={task.inbox_contact.is_silenced}
            archived={task.inbox_contact.is_archived}
            lastInboundMessageOn={task.inbox_contact.last_inbound_message_on}
          />
          <ContactTab contactId={task.inbox_contact.contact.id} />
        </div>
      </div>
    </motion.div>
  );
}
