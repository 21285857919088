import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import DocViewer, {
  DocViewerRenderers,
  IHeaderOverride,
} from "@cyntler/react-doc-viewer";
import {
  ChevronLeftIcon,
  ExternalLinkIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import { WhatsAppMessage } from "@hilos/types/private-schema";

interface MediaDocumentProps {
  onClose: () => void;
  message: WhatsAppMessage;
  content_url?: string;
}

export default function MediaDocument({
  onClose,
  message,
  content_url,
}: MediaDocumentProps) {
  const { t } = useTranslation();
  const [error, setError] = useState(false);

  const CustomHeader: IHeaderOverride = () => {
    return (
      <div className=" px-2 right-0 flex bg-white py-3 w-full">
        <button
          type="button"
          className="mr-auto rounded-md p-2"
          onClick={onClose}
        >
          <ChevronLeftIcon className="h-6 w-6 text-gray-600" />
        </button>
        <a
          href={content_url ?? message.content_url ?? ""}
          target="_blank"
          rel="noreferrer"
          className="rounded-md flex items-center hover:font-bold hover:border-2 text-xs font-semibold text-hilos border border-hilos p-2"
        >
          <span className="mr-2">{t("open-browser", "Open in browser")}</span>
          <ExternalLinkIcon className="ml-2 h-4 w-4" />
        </a>
      </div>
    );
  };

  const handleRefresh = useCallback(() => {
    setError(false);
  }, []);

  if (error) {
    return (
      <div
        className="inline-block transform overflow-hidden bg-black bg-opacity-70 text-left align-bottom shadow-xl transition-all max-h-screen   sm:align-middle"
        onClick={handleRefresh}
      >
        <RefreshIcon className="h-8 w-8 cursor-pointer" />
      </div>
    );
  }
  return (
    <div className="inline-block transform overflow-hidden bg-black bg-opacity-70 text-left align-bottom shadow-xl transition-all max-h-screen   sm:align-middle">
      <div className="w-screen h-screen flex items-center justify-center relative">
        <div className="h-screen w-screen relative flex items-center justify-center">
          <DocViewer
            documents={[{ uri: content_url ?? message.content_url ?? "" }]}
            pluginRenderers={DocViewerRenderers}
            theme={{
              disableThemeScrollbar: true,
            }}
            config={{
              header: {
                overrideComponent: CustomHeader,
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}
