import { Trans } from "react-i18next";
import {
  ChartBarIcon,
  ClipboardListIcon,
  InboxIcon,
  ShareIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import GFSAImg from "src/assets/img/onboarding/GFSA.png";
import contactOnboardingImg from "src/assets/img/onboarding/contact-onboarding.png";
import flowsOnboardingImg from "src/assets/img/onboarding/flows-onboarding.png";
import reportsOnboardingImg from "src/assets/img/onboarding/reports-onboarding.png";
import templatesOnboardingImg from "src/assets/img/onboarding/template-onboarding.png";
import { getTranslationPayload as t } from "src/i18n";

export const MODULE_HELP = {
  inbox: {
    modal_title: t("onboarding:module.inbox.modal_title", "Welcome to Inbox!"),
    modal_icon: <InboxIcon className="w-5 h-5" />,
    title: t(
      "onboarding:module.inbox.title",
      "Organize your conversations and more with Inbox!"
    ),
    content: (
      <Trans i18nKey="onboarding:module.inbox.content">
        <div>
          <p className="mt-1 mb-2">
            Discover some of the things you can do in our inbox:
          </p>
          <ul>
            <li className="text-gray-600 mt-1">
              <p className="text-hilos font-semibold">Quick replies</p>
              Speed up your work with custom replies
            </li>
            <li className="text-gray-600 mt-1">
              <p className="text-hilos font-semibold">Views</p> Organize your
              conversations automatically into separate inboxes
            </li>
            <li className="text-gray-600 mt-1">
              <p className="text-hilos font-semibold">Assignment</p> Assign
              people to conversations manually, or use flows to do it
              automatically
            </li>
            <li className="text-gray-600 mt-1">
              <p className="text-hilos font-semibold">Tags</p> Add Tags to sort
              your conversations, can be done manually, or automatically with
              flows
            </li>
            <li>
              <a
                className="underline text-blue-500 text-sm"
                target="_blank"
                rel="noreferrer"
                href="https://hilos.io/docs/user/using-hilos/inbox"
              >
                Learn these and more features visiting our docs.
              </a>
            </li>
          </ul>
        </div>
      </Trans>
    ),
    image:
      "https://assets-global.website-files.com/62e06c89457f36d21c5b726f/62f4994f0245da672c6075b8_inbox-mobile.webp",
    href: "https://hilos.io/docs/user/using-hilos/inbox",
  },
  contacts: {
    modal_title: t(
      "onboarding:module.contacts.modal_title",
      "Welcome to Contacts!"
    ),
    modal_icon: <UserGroupIcon className="w-5 h-5" />,
    title: t(
      "onboarding:module.contacts.title",
      "Manage your contacts in a simple way!"
    ),
    content: (
      <Trans i18nKey="onboarding:module.contacts.content">
        <div>
          <p className="mt-1 mb-2">
            Contacts are the people you want to send messages to. They can be
            your customers, your employees, or anyone else you want to send
            messages to
          </p>
          <ul>
            <li className="text-gray-600 mt-1">
              <p className="text-hilos font-semibold">Importing contacts</p>{" "}
              There are two ways of doing it: manually or by importing a CSV
              file.
            </li>
            <li className="text-gray-600 mt-1">
              <p className="text-hilos font-semibold">Editing a contact</p> If
              you wish to add attributes for a contact or change the contact’s
              information, you can do so by editing the contact. To do this go
              to your contact list and look for the contact you wish to edit.
              Click on the edit button on the right side of the contact’s
              information and you will be redirected to the contact’s page.
            </li>
            <li className="text-gray-600 mt-1">
              <p className="text-hilos font-semibold">Deleting a contact</p> To
              delete a contact from your contact list simply go to your contact
              list and click on the delete button on the right side of the
              contact you want to delete.
            </li>
            <li>
              <a
                className="underline text-blue-500 text-sm"
                target="_blank"
                rel="noreferrer"
                href="https://hilos.io/docs/user/using-hilos/contacts"
              >
                Learn these and more features visiting our docs.
              </a>
            </li>
          </ul>
        </div>
      </Trans>
    ),
    image: contactOnboardingImg,
    href: "https://hilos.io/docs/user/using-hilos/contacts",
  },
  templates: {
    modal_title: t(
      "onboarding:module.templates.modal_title",
      "Welcome to Templates!"
    ),
    modal_icon: <ClipboardListIcon className="w-5 h-5" />,
    title: t(
      "onboarding:module.templates.title",
      "What WhatsApp templates are and how they work."
    ),
    content: (
      <Trans i18nKey="onboarding:module.templates.content">
        <div className="text-gray-600">
          <p className="mt-1 mb-2">
            WhatsApp Templates are required by WhatsApp to start conversations
            with users.
          </p>
          <p className="mt-1 mb-2">
            If a user has not sent you a message in the last 24h, you will need
            to use a template to initiate a conversation.
          </p>
          <p className="mt-1 mb-2">
            Templates are created in Hilos and sent to WhatsApp for approval (5
            min to 48h). Once they are approved you can use them wherever you
            want, Inbox, automations, etc
          </p>
        </div>
      </Trans>
    ),
    image: templatesOnboardingImg,
    href: "https://hilos.io/docs/user/using-hilos/templates",
  },
  flows: {
    modal_title: t("onboarding:module.flows.modal_title", "Welcome to Flows!"),
    modal_icon: <ShareIcon className="rotate-90 w-5 h-5" />,
    title: t(
      "onboarding:module.flows.title",
      "Automate your conversations with Flows"
    ),
    content: (
      <Trans i18nKey="onboarding:module.flows.content">
        <div>
          <p className="mt-1 mb-2">
            Flows let you use no-code building blocks to design your automated
            conversations with customers.
          </p>
          <p className="mt-1 mb-2">
            Start with basic interactions, and connect it with other tools you
            already use via API to level up your chatbot game!
          </p>
          <ul>
            <li className="text-gray-600 mt-1">
              <p className="text-hilos font-semibold">Inbound</p> Respond
              automatically to your customer’s requests. Build chatbots that
              assign people, add tags, and send information to your CRM.
            </li>
            <li className="text-gray-600 mt-1">
              <p className="text-hilos font-semibold">Outbound</p> Initiate
              automated conversations at scale to close and onboard your
              customers. Trigger via API, CSV, Hubspot, or Zapier
            </li>
            <li>
              <a
                className="underline text-blue-500 text-sm"
                target="_blank"
                rel="noreferrer"
                href="https://hilos.io/docs/user/using-hilos/flows"
              >
                Learn these and more features visiting our docs.
              </a>
            </li>
          </ul>
        </div>
      </Trans>
    ),
    image: flowsOnboardingImg,
    href: "https://hilos.io/docs/user/using-hilos/flows",
  },
  reports: {
    modal_title: t(
      "onboarding:module.reports.modal_title",
      "Welcome to Reports!"
    ),
    modal_icon: <ChartBarIcon className="w-5 h-5" />,
    title: t(
      "onboarding:module.reports.title",
      "Understand and improve how your team uses Hilos"
    ),
    content: (
      <Trans i18nKey="onboarding:module.reports.content">
        <div>
          <p className="mt-1 mb-2">
            Hilos provides comprehensive information to understand how your
            users are interacting with your flows adding your conversations, to
            make necessary adjustments or optimizations.
          </p>
          <ul>
            <li className="text-gray-600 mt-1">
              <p className="text-hilos font-semibold">Flow reports</p> Displays
              information about flows in three tabs: Contacts, Executions (for
              Outbound), and Versions. Contacts show who has gone through the
              flow, Executions detail individual runs, and Versions track
              changes. Detailed views provide contact statuses and a visual flow
              summary.
            </li>
            <li className="text-gray-600 mt-1">
              <p className="text-hilos font-semibold">Inbox reports</p>
              Hilos provides reports to understand team conversations. Use
              Conversation Tags, close conversations promptly, and choose the
              right report type: Summary, By Agent, By Tag, or Detail Inbox.
              Apply filters for tailored insights..
            </li>
            <li>
              <a
                className="underline text-blue-500 text-sm"
                target="_blank"
                rel="noreferrer"
                href="https://hilos.io/docs/user/using-hilos/reports"
              >
                Learn these and more features visiting our docs.
              </a>
            </li>
          </ul>
        </div>
      </Trans>
    ),
    image: reportsOnboardingImg,
    href: "https://hilos.io/docs/user/using-hilos/reports",
  },
  ai_tasks: {
    modal_title: t("onboarding:module.ai_tasks.modal_title", "AI Tasks"),
    modal_icon: <ShareIcon className="rotate-90 w-5 h-5" />,
    title: t(
      "onboarding:module.ai_tasks.title",
      "Google for Startups Accelerator: AI First Latin America - Demo Day 2024"
    ),
    content: (
      <Trans i18nKey="onboarding:module.ai_tasks.content">
        <div>
          <p className="mt-1 mb-2">
            We are excited to present for Google for Startups Accelerator: AI
            First Latin America - Demo Day 2024. AI generated tasks.
          </p>
          <p className="mt-1 mb-2">
            With our new feature, agents can start each day with a clear
            overview of their pending tasks. This tool highlights priority items
            and ensures no conversation is overlooked. By leveraging Generative
            AI, we provide concise summaries of the previous day's interactions,
            generating actionable task lists that keep agents focused on what
            matters most.
          </p>
        </div>
      </Trans>
    ),
    image: GFSAImg,
    imgClassNames: " ",
  },
};
