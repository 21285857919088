import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  PlusSmIcon,
  QuestionMarkCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { ArrayHelpers, FieldArray, useFormikContext } from "formik";
import { debounce } from "lodash";
import { FlowStepAnswerTypes } from "@hilos/types/flow";
import SelectorField from "src/components/Form/SelectorField";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import { hasItems } from "src/helpers/utils";
import { classNames } from "src/Helpers";
import listImg from "src/assets/img/flow/list.webp";
import { ANSWER_OPTIONS_RENDER_TYPES } from "../../../constants/steps/question";
import useFlowBuilderStore from "../../../hooks/useFlowBuilderStore";
import { useStepField } from "../../../hooks/useStepField";
import FormatOptionLabel from "../../FormatOptionLabel";
import FlowBuilderOptionsFromVariable from "./FlowBuilderOptionsFromVariable";

interface FlowBuilderStepQuestionSingleOptionProps {
  id: string;
  index: number;
  isStepMenu?: boolean;
}

function getMaxOptionsLength(
  isStepMenu: boolean,
  answerType: FlowStepAnswerTypes,
  answerOptionsRender: string
) {
  if (isStepMenu || answerType === "SINGLE_OPTION") {
    if (answerOptionsRender === "BUTTONS") {
      return 3;
    }
    if (answerOptionsRender === "LIST") {
      return 10;
    } else {
      return 15;
    }
  }
  if (answerType === "BOOL") {
    return 2;
  }
  return null;
}

function FlowBuilderStepQuestionSingleOption({
  id,
  index,
  isStepMenu = false,
}: FlowBuilderStepQuestionSingleOptionProps) {
  const [t] = useTranslation();
  const hasPendingRefresh = useRef(false);
  const [showListExample, setShowListExample] = useState(false);
  const { setFieldValue } = useFormikContext();
  const [answerOptions] = useStepField({
    index,
    name: "answer_options",
  });
  const [nextStepAlternate] = useStepField({
    index,
    name: "next_step_alternate",
  });
  const [nextStepOptions, setNextStepOptions] = useStepField({
    index,
    name: "next_steps_for_options",
  });
  const [answerType] = useStepField({
    index,
    name: "answer_type",
  });
  const [answerOptionsRender] = useStepField({
    index,
    name: "answer_options_render",
  });
  const [hasOptionsFromVariable] = useStepField({
    index,
    name: "has_options_from_variable",
  });
  const { onFlowRefresh } = useFlowBuilderStore();

  const maxOptionsLength = useMemo(
    () => getMaxOptionsLength(isStepMenu, answerType, answerOptionsRender),
    [answerType, answerOptionsRender, isStepMenu]
  );

  const hasAnswerWithNumbers = useMemo(
    () => ["EMOJIS", "NUMBERS"].includes(answerOptionsRender),
    [answerOptionsRender]
  );

  const answerRenderTypeOptions = useMemo(
    () =>
      ANSWER_OPTIONS_RENDER_TYPES.map((option) => ({
        ...option,
        label: t(option.label),
        help: t(option.help),
      })),
    [t]
  );

  const handleRefreshFlow = useCallback(
    debounce(async () => {
      onFlowRefresh(id);
    }, 350),
    [id, onFlowRefresh]
  );

  const handleClearDataSourceOptions = useCallback(() => {
    setFieldValue(`steps.${index}.missing_options_message`, "");
    setFieldValue(`steps.${index}.options_from_variable`, "");
    setFieldValue(`steps.${index}.option_from_variable_value`, "");
    setFieldValue(`steps.${index}.option_from_variable_title`, "");
    setFieldValue(`steps.${index}.option_from_variable_description`, "");
  }, [index, setFieldValue]);

  const handleAnswerOptionsRenderChange = useCallback(
    (nextAnswerOptionsRender) => {
      const nextAnswerOptionsRenderValue =
        nextAnswerOptionsRender && nextAnswerOptionsRender.value;
      const nextMaxOptionsLength = getMaxOptionsLength(
        isStepMenu,
        answerType,
        nextAnswerOptionsRenderValue
      );
      let hasAnswerOptionsChanges = false;

      if (hasItems(answerOptions)) {
        if (
          nextMaxOptionsLength !== null &&
          answerOptions.length > nextMaxOptionsLength
        ) {
          const nextOptions = answerOptions.slice(0, nextMaxOptionsLength);
          setFieldValue(`steps.${index}.answer_options`, nextOptions);
          hasAnswerOptionsChanges = true;
        } else if (!nextAnswerOptionsRenderValue) {
          setFieldValue(`steps.${index}.answer_options`, []);
          hasAnswerOptionsChanges = true;
        }
      } else if (isStepMenu) {
        setFieldValue(`steps.${index}.answer_options`, []);
      }

      if (
        !nextAnswerOptionsRenderValue ||
        !["BUTTONS", "LIST"].includes(nextAnswerOptionsRenderValue)
      ) {
        setFieldValue(`steps.${index}.has_options_from_variable`, false);
        handleClearDataSourceOptions();
        hasAnswerOptionsChanges = true;
      }

      if (isStepMenu && hasAnswerOptionsChanges) {
        hasPendingRefresh.current = true;
      }
    },
    [
      index,
      answerOptions,
      answerType,
      isStepMenu,
      setFieldValue,
      handleClearDataSourceOptions,
    ]
  );

  const handleChangeHasOptionsFromVariable = useCallback(() => {
    setFieldValue(`steps.${index}.answer_options`, [""]);
    handleClearDataSourceOptions();
    hasPendingRefresh.current = true;
  }, [index, setFieldValue, handleClearDataSourceOptions]);

  const handleAddAnswerOption = useCallback(
    (helpers: ArrayHelpers, answerOptionName: string) => {
      return () => {
        if (isStepMenu) {
          setNextStepOptions([...(nextStepOptions || []), null]);
          hasPendingRefresh.current = true;
        }
        helpers.push(answerOptionName);
      };
    },
    [isStepMenu, nextStepOptions, setNextStepOptions]
  );

  const handleDeleteAnswerOption = useCallback(
    (helpers: ArrayHelpers, answerOptionIndex: number) => {
      return () => {
        if (isStepMenu && hasItems(nextStepOptions)) {
          const nextStepOptionsToUpdate = [...nextStepOptions];
          nextStepOptionsToUpdate.splice(answerOptionIndex, 1);
          setNextStepOptions(nextStepOptionsToUpdate);
          hasPendingRefresh.current = true;
        }
        helpers.remove(answerOptionIndex);
      };
    },
    [isStepMenu, nextStepOptions, setNextStepOptions]
  );

  useEffect(() => {
    if (hasPendingRefresh.current) {
      handleRefreshFlow();
      hasPendingRefresh.current = false;
    }
  }, [answerOptions, hasOptionsFromVariable, handleRefreshFlow]);

  return (
    <>
      <SelectorField
        name={`steps.${index}.answer_options_render`}
        label={t(
          "flows:steps.question.answer-options-render.label",
          "How would you like to show these options?"
        )}
        options={answerRenderTypeOptions}
        formatOptionLabel={FormatOptionLabel}
        isOptionDisabled={(option) => option.soon}
        onSelect={handleAnswerOptionsRenderChange}
      />

      {answerOptionsRender === "LIST" && (
        <>
          <button
            className="mt-1 flex items-center text-sm text-blue-500"
            type="button"
            onClick={() =>
              setShowListExample((prevShowListExample) => !prevShowListExample)
            }
          >
            <QuestionMarkCircleIcon className="mr-0.5 h-4 w-4" />
            {showListExample
              ? t("hide-example", "Hide example")
              : t("show-example", "Show example")}
          </button>

          {showListExample && (
            <div className="text-center text-sm italic text-gray-500">
              <img src={listImg} alt="Message with list example" />
              <p>
                {t(
                  "flows:steps.question.example-instruction",
                  "Example of a message with an interactive list"
                )}
              </p>
            </div>
          )}

          <TextInputField
            name={`steps.${index}.answer_options_render_list_button_title`}
            label={t(
              "flows:steps.question.answer-options-render-list-button-title.label",
              "Open list button text"
            )}
            placeholder={t(
              "flows:steps.question.answer-options-render-list-button-title.placeholder",
              "For example, Available Options"
            )}
            type="text"
            maxLength={20}
          />
        </>
      )}

      {!isStepMenu && !hasAnswerWithNumbers && (
        <SwitchField
          disabled={hasOptionsFromVariable && !!nextStepAlternate}
          name={`steps.${index}.has_options_from_variable`}
          label={t(
            "flows:steps.question.has-options-from-variable.label",
            "Use a variable as a data source for options?"
          )}
          help={t(
            "flows:steps.question.has-options-from-variable.placeholder",
            "You can select a variable as data source for the options and choose which properties will be used as value and title."
          )}
          onChange={handleChangeHasOptionsFromVariable}
        />
      )}

      <div className="space-y-2">
        {!hasOptionsFromVariable && (
          <p className="block text-sm font-medium text-gray-700">
            {t(
              "flows:steps.question.options-title",
              "Which options can the user choose from?"
            )}
          </p>
        )}

        {hasAnswerWithNumbers && (
          <p className="text-xs text-gray-500">
            {t(
              "flows:steps.question.has-answer-with-number-instruction",
              "We'll add these options to the question text along with numbers to let the customer know how to answer."
            )}
          </p>
        )}

        {hasOptionsFromVariable ? (
          <FlowBuilderOptionsFromVariable
            currentStepIndex={index}
            maxOptionsLength={maxOptionsLength}
          />
        ) : (
          <FieldArray
            name={`steps.${index}.answer_options`}
            render={(arrayHelpers) => (
              <>
                <div className="list-group list-group-flush">
                  {answerOptions &&
                    answerOptions.map((_, optionIndex) => (
                      <div className="flex items-center py-1" key={optionIndex}>
                        <div className="mr-4 grow-0">
                          <code>{optionIndex + 1}</code>
                        </div>
                        <div className="grow">
                          <TextInputField
                            name={`steps.${index}.answer_options.${optionIndex}`}
                            placeholder={t(
                              "flows:steps.question.answer-options.placeholder",
                              "Check order status."
                            )}
                            type="text"
                            onKeyDown={handleRefreshFlow}
                            maxLength={
                              answerOptionsRender === "BUTTONS"
                                ? 20
                                : answerOptionsRender === "LIST"
                                ? 24
                                : undefined
                            }
                          />
                        </div>

                        <div className="ml-4 grow-0">
                          <button
                            type="button"
                            disabled={
                              nextStepOptions && nextStepOptions[optionIndex]
                            }
                            className={classNames(
                              "inline-flex w-full items-center justify-center rounded-md border border-red-500 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-700",
                              "hover:bg-red-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2",
                              "disabled:hover:bg-gray-50 disabled:focus:ring-0 disabled:border-gray-300 disabled:text-gray-300"
                            )}
                            onClick={handleDeleteAnswerOption(
                              arrayHelpers,
                              optionIndex
                            )}
                          >
                            <XIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    ))}
                </div>

                {maxOptionsLength !== null &&
                  answerOptions &&
                  answerOptions.length < maxOptionsLength && (
                    <button
                      type="button"
                      className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-blue-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-blue-500 shadow-sm hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
                      onClick={handleAddAnswerOption(arrayHelpers, "")}
                    >
                      <PlusSmIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                      {t("flows:steps.question.add-option", "Add option")}
                    </button>
                  )}
              </>
            )}
          />
        )}
      </div>
    </>
  );
}

export default FlowBuilderStepQuestionSingleOption;
