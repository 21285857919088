import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useStripeSubscription } from "src/hooks/useStripe";
import { buildRoute } from "src/router/router";
import SidebarMenuNotice from "./SidebarMenuNotice";

function SubscriptionPaymentFailedNotice() {
  const { t } = useTranslation();
  const { subscription } = useStripeSubscription();

  return (
    <SidebarMenuNotice
      title={t(
        "settings:subscription-payment-failed-notice.title",
        "We weren't able to charge your account for this month's usage."
      )}
      description={t(
        "settings:subscription-payment-failed-notice.subtitle",
        "To avoid losing access to Hilos, pay your outstanding invoice before the 10th of this month."
      )}
    >
      {subscription?.last_invoice?.stripe_invoice_url ? (
        <a
          href={subscription?.last_invoice?.stripe_invoice_url || ""}
          target="_blank"
          rel="noreferrer"
          className="group-data-[collapsible=icon]:hidden whitespace-nowrap font-medium text-red-700 hover:text-red-600"
        >
          {t("settings:subscription-payment-failed-notice.button", "Pay now")}
          <span aria-hidden="true"> &rarr;</span>
        </a>
      ) : (
        <Link
          to={buildRoute("config-account-subscription")}
          className="group-data-[collapsible=icon]:hidden whitespace-nowrap font-medium text-red-700 hover:text-red-600"
        >
          {t("settings:subscription-payment-failed-notice.button", "Pay now")}
          <span aria-hidden="true"> &rarr;</span>
        </Link>
      )}
    </SidebarMenuNotice>
  );
}

export default SubscriptionPaymentFailedNotice;
