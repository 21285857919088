import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { ClockIcon } from "@heroicons/react/outline";
import { differenceInDays, isValid } from "date-fns";
import { UpdateInboxContactFn } from "@hilos/hooks/useInboxContactDetails";
import {
  ConversationContent,
  InboxContactRead,
} from "@hilos/types/private-schema";
import { hasItems } from "src/helpers/utils";
import { WhatsAppTemplateProvider } from "src/hooks/WhatsAppTemplateProvider";
import useConversationMessage from "src/hooks/useConversationMessage";
import useHilosStore from "src/hooks/useHilosStore";
import useMediaDropzone from "src/hooks/useMediaDropzone";
import { useInboxContactScheduledMessages } from "src/hooks/useScheduledMessages";
import { buildRoute } from "src/router/router";
import BottomBarMessageForm from "../inbox/Conversation/BottomBarMessageForm";
import BottomBarTemplateSend from "../inbox/Conversation/BottomBarTemplateSend";
import ConversationBlocked from "../inbox/Conversation/ConversationBlocked";

interface WorkflowBoardTaskFooterProps {
  inboxContact: InboxContactRead;
  context: ConversationContent | null;
  onUpdateInboxContact: UpdateInboxContactFn;
  onSelectContextContent: (content: ConversationContent | null) => void;
  onFocusConversationContent: (data: ConversationContent) => void;
  onDisableMessageMediaUpload: (disable: boolean) => void;
}

function WorkflowBoardTaskFooter({
  inboxContact,
  context,
  onUpdateInboxContact,
  onSelectContextContent,
  onFocusConversationContent,
  onDisableMessageMediaUpload,
}: WorkflowBoardTaskFooterProps) {
  const { session } = useHilosStore();
  const { t } = useTranslation();

  const { handleSendMessage } = useConversationMessage(inboxContact.id);

  const { data: scheduledMessages, refetch } = useInboxContactScheduledMessages(
    { inboxContactId: inboxContact.id }
  );

  const mustSendTemplate = useMemo(() => {
    if (inboxContact.last_inbound_message_on) {
      const lastInboundMessageTimestamp = new Date(
        inboxContact.last_inbound_message_on
      );

      return (
        !isValid(lastInboundMessageTimestamp) ||
        differenceInDays(new Date(), lastInboundMessageTimestamp) >= 1
      );
    }
    return true;
  }, [inboxContact]);

  const { getRootProps, getInputProps } = useMediaDropzone({
    disabled: mustSendTemplate,
    handleSendMessage,
  });

  if (inboxContact.is_blocked) {
    return <ConversationBlocked />;
  }

  if (
    session?.account.has_used_multiple_channels &&
    inboxContact.channel.status !== "ACTIVE"
  ) {
    return (
      <div className="rounded-md bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationCircleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              {t("inbox:channel-inactive", "This channel is inactive")}
            </h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>
                {t(
                  "inbox:channel-inactive-description",
                  "You can see the entire conversation history but won't be able to send messages using this number."
                )}
              </p>
            </div>
            <div className="mt-4">
              <div className="-mx-2 -my-1.5 flex">
                <Link
                  to={buildRoute("config-channel-cloud-api-details", {
                    id: inboxContact.channel.id,
                  })}
                  className="rounded-md bg-yellow-50 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50"
                >
                  {t(
                    "inbox:channel-inactive-see-details",
                    "See channel details"
                  )}
                  <span aria-hidden="true"> &rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {hasItems(scheduledMessages) && (
        <div className="bg-blue-100 px-4 py-2 text-xs text-blue-800">
          <Link
            to="/inbox/scheduled-messages"
            className=" ml-1 flex font-medium"
          >
            <ClockIcon className="mr-2 h-4 w-4" />
            {t(
              "inbox:scheduled-message.conversation-footer",
              "This conversation has scheduled messages"
            )}{" "}
            <span className="ml-1 font-bold">({scheduledMessages.length})</span>
          </Link>
        </div>
      )}
      <div
        {...getRootProps({
          className: "shrink-0 bg-gray-100 px-4 cursor-default",
        })}
      >
        <div className="min-w-0" data-tour="convo-footer">
          <WhatsAppTemplateProvider>
            {mustSendTemplate ? (
              <BottomBarTemplateSend inboxContact={inboxContact} />
            ) : (
              <BottomBarMessageForm
                inboxContact={inboxContact}
                context={context}
                getInputProps={getInputProps}
                onSendMessage={handleSendMessage}
                onScheduleMessage={refetch}
                onUpdateInboxContact={onUpdateInboxContact}
                onFocusConversationContent={onFocusConversationContent}
                onDisableMessageMediaUpload={onDisableMessageMediaUpload}
                onSelectContextContent={onSelectContextContent}
              />
            )}
          </WhatsAppTemplateProvider>
        </div>
      </div>
    </>
  );
}

export default WorkflowBoardTaskFooter;
