import { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ArrowSmRightIcon,
  CalendarIcon,
  ChatIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import { Channel } from "@hilos/types/private-schema";
import HeaderNavigation from "src/components/HeaderNavigation";
import { useChannels } from "src/hooks/useChannel";
import useHilosStore from "src/hooks/useHilosStore";
import { formatPhone } from "src/Helpers";
import i18n from "src/i18n";
import { buildRoute } from "src/router/router";
import { ChannelProvider, ChannelType } from "src/types/channel";
import ChannelStatusBadge from "./ChannelStatusBadge";
import { AVAILABLE_CHANNELS, getAvailableChannelData } from "./Meta";

export default function ChannelList() {
  const { t } = useTranslation();
  const channels = useChannels();
  const { session } = useHilosStore();
  const { language } = i18n;

  const updateLink = useCallback((channel: Channel) => {
    switch (channel.channel_type) {
      case ChannelType.WHATSAPP:
        switch (channel.channel_provider) {
          case ChannelProvider.DIALOG360:
            return buildRoute("config-channel-360dialog-details", {
              id: channel.id,
            });
          case ChannelProvider.D360_CLOUD_API:
            return buildRoute("config-channel-360dialog-details", {
              id: channel.id,
            });
          case ChannelProvider.META_CLOUD_API:
          case ChannelProvider.TECH_PROVIDER_CLOUD_API:
            return buildRoute("config-channel-cloud-api-details", {
              id: channel.id,
            });
          default:
            return "";
        }
      default:
        return "";
    }
  }, []);

  return (
    <>
      <div className="h-screen overflow-y-auto bg-gray-50">
        {/* Header */}
        <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
          <HeaderNavigation
            navPages={[
              {
                name: "Home",
                url: buildRoute("dashboard"),
              },
              {
                name: "Settings",
                url: buildRoute("config-account"),
              },
              {
                name: "WhatsApp Accounts",
                url: buildRoute("config-channel"),
              },
            ]}
          />
          <div className="lg:flex lg:items-center lg:justify-between">
            <div className="min-w-0 flex-1">
              <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                <ChatIcon className="mr-2 h-7 w-7" aria-hidden="true" />
                {t("channel:channel-list-title", "WhatsApp Accounts")}
              </h2>
              <p className="mt-1 text-sm text-gray-600">
                <Trans i18nKey="channel:channel-list-subtitle">
                  See your connected channels or connect a new one.
                </Trans>
              </p>
            </div>
          </div>
        </div>

        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto mt-6 max-w-3xl">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {t(
                      "channel:channel-active-title",
                      "Active WhatsApp accounts"
                    )}
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    {t(
                      "channel:channel-active-subtitle",
                      "See the WhatsApp accounts you've connected to your account and their status."
                    )}
                  </p>
                </div>
              </div>
              <div className="mt-5 md:col-span-2 md:mt-0">
                <div className="overflow-hidden bg-white shadow sm:rounded-md">
                  <ul className="divide-y divide-gray-200">
                    {channels.map((channel: Channel) => (
                      <li key={channel.id}>
                        <Link
                          to={updateLink(channel)}
                          className="block hover:bg-gray-50"
                        >
                          <div className="flex items-center px-4 py-4 sm:px-6">
                            <div className="flex min-w-0 flex-1 items-center">
                              <div className="flex-shrink-0 text-center">
                                <img
                                  className="max-h-8 object-scale-down"
                                  src={getAvailableChannelData(channel)?.logo}
                                  alt=""
                                />
                                {channel.channel_provider ===
                                  "META_CLOUD_API" && (
                                  <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                                    {t("channel:direct", "Directo")}
                                  </span>
                                )}
                              </div>
                              <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                <div className="space-y-0.5 self-start">
                                  <p className="truncate text-sm font-medium text-indigo-600">
                                    {channel.name}
                                  </p>
                                  {channel.id ===
                                    session?.account.default_channel && (
                                    <div className="flex items-center text-sm text-gray-500">
                                      <CheckCircleIcon className="-ml-0.5 mr-1 h-5 w-5" />
                                      {t(
                                        "channel:default-channel",
                                        "Default channel"
                                      )}
                                    </div>
                                  )}
                                  <p className="text-sm text-gray-500">
                                    <span>
                                      {channel.channel_type === "WHATSAPP" &&
                                        formatPhone(
                                          channel.channel_id,
                                          true,
                                          true
                                        )}
                                    </span>
                                  </p>
                                </div>
                                <div className="self-center text-right">
                                  <ChannelStatusBadge status={channel.status} />
                                </div>
                              </div>
                            </div>
                            <div>
                              <ArrowSmRightIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                          {channel.status === "INACTIVE" &&
                            channel.connection_error_msg && (
                              <div className="bg-yellow-50 p-4">
                                <div className="flex">
                                  <div className="flex-shrink-0">
                                    <ExclamationCircleIcon
                                      className="h-5 w-5 text-yellow-400"
                                      aria-hidden="true"
                                    />
                                  </div>
                                  <div className="ml-3">
                                    <h3 className="text-sm font-medium text-yellow-800">
                                      {t(
                                        "channel:connection-failed",
                                        "Channel connection failed"
                                      )}
                                      :
                                    </h3>
                                    <div className="mt-1 text-sm text-yellow-700">
                                      <p>{channel.connection_error_msg}</p>
                                    </div>
                                    <div className="mt-4 text-sm text-yellow-700">
                                      <p>
                                        {t(
                                          "channel:connection-failed-help",
                                          "We can help you get it set up and running, send us a message and we can help!"
                                        )}
                                      </p>
                                    </div>
                                    <div className="mt-2">
                                      <div className="-mx-2 -my-1.5 flex">
                                        <a
                                          href={
                                            language &&
                                            language
                                              .toLowerCase()
                                              .indexOf("es") === 0
                                              ? "https://api.whatsapp.com/send?phone=525592257050&text=Hola!%20Necesito%20ayuda%20con%20Hilos."
                                              : "https://api.whatsapp.com/send?phone=+525592257050&text=Hi!%20I%20need%20help%20with%20Hilos."
                                          }
                                          className="flex items-center rounded-md bg-yellow-50 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50"
                                        >
                                          <FontAwesomeIcon
                                            //@ts-ignore
                                            icon={faWhatsapp}
                                            className="ml-0.5 mr-1 text-lg"
                                          />{" "}
                                          {t(
                                            "navigation:support.chat",
                                            "Chat with support"
                                          )}
                                        </a>
                                        <a
                                          href="https://calendly.com/alfonso-hilos/onboarding"
                                          className="flex items-center rounded-md bg-yellow-50 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50"
                                        >
                                          <CalendarIcon
                                            //@ts-ignore
                                            className="ml-0.5 mr-1 text-lg h-5 w-5"
                                          />{" "}
                                          {t(
                                            "channel:connection-failed-book-call",
                                            "Book a call"
                                          )}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="mt-8">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {t(
                    "channel:channel-connect-new-title",
                    "Connect a new WhatsApp account"
                  )}
                </h3>
              </div>

              <div className="mt-4 sm:mt-6">
                <ul className="grid grid-cols-0 sm:grid-cols-2 md:grid-cols-3 gap-6">
                  {AVAILABLE_CHANNELS.filter((channel) => channel.active)
                    .filter((ch) =>
                      ch.feature_flag
                        ? session?.account.feature_flags.includes(
                            ch.feature_flag
                          )
                        : true
                    )
                    .map((channel) => (
                      <li
                        key={t(channel.name)}
                        className="flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
                      >
                        <div className="flex flex-1 flex-col p-6">
                          <div className="flex justify-center">
                            <span className="flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800">
                              <channel.icon className="mr-1 text-lg" />
                              {channel.type}
                            </span>
                          </div>

                          <img
                            className="mt-3 -mb-2 max-h-14 flex-shrink-0 object-scale-down"
                            src={channel.logo}
                            alt="Channel logo"
                          />
                          {channel.default && (
                            <div className="flex justify-center">
                              <span className="flex items-center rounded-full bg-blue-100 px-2 py-1 text-xs font-medium text-blue-800">
                                {t("recommended", "Recommended")}
                              </span>
                            </div>
                          )}
                          {channel.advanced && (
                            <div className="flex justify-center">
                              <span className="flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800">
                                {t("advanced", "Advanced")}
                              </span>
                            </div>
                          )}
                          <h3 className="mt-3 text-sm font-medium text-gray-900">
                            {t(channel.name)}
                          </h3>
                          <dl className="mt-1 flex flex-grow flex-col justify-start">
                            <dt className="sr-only">
                              {t("description", "Description")}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-500">
                              {t(channel.description)}
                            </dd>
                          </dl>
                        </div>
                        <div>
                          <div className="-mt-px flex divide-x divide-gray-200">
                            <div className="-ml-px flex w-0 flex-1">
                              {channel.active ? (
                                <Link
                                  to={channel.href}
                                  className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-900 hover:text-gray-500"
                                >
                                  <span className="mr-1">
                                    {t("start-now", "Start now")}
                                  </span>
                                  <ArrowSmRightIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </Link>
                              ) : (
                                <a
                                  href="https://calendly.com/alfonso-hilos/onboarding?utm_source=app&utm_campaign=add-channel"
                                  target="_blank"
                                  rel="noreferrer"
                                  className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
                                >
                                  <span className="mr-1">
                                    {t("start-now", "Start now")}
                                  </span>
                                  <ArrowSmRightIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
