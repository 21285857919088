import { useTranslation } from "react-i18next";
import { Card, DonutChart, Legend, Text } from "@tremor/react";
import Loading from "src/components/Loading";
import { useInboxUsageSummaryConversationSource } from "src/hooks/useInboxUsageSummaryAnalytics";
import {
  CalculateQueryParamsParams,
  paramsToQueryString,
} from "../FiltersMeta";
import InboxSummaryCards from "./InboxSummaryCards";
import { InboxSummaryMultiChart } from "./InboxSummaryMultiChart";
import InboxSummaryTable from "./InboxSummaryTable";

export interface InboxReportProps {
  params: CalculateQueryParamsParams;
}
export default function InboxSummary({ params }: InboxReportProps) {
  const { t } = useTranslation();
  const paramsQS = paramsToQueryString(params);
  const { inboxUsageSummaryConvoSource } =
    useInboxUsageSummaryConversationSource(paramsQS);

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-3 gap-x-6">
        <div className="space-y-6">
          <InboxSummaryCards params={params} />
          {inboxUsageSummaryConvoSource ? (
            <Card className="max-w-lg">
              <Text>
                {t(
                  "reports:summary.conversations-by-source",
                  "Conversations by source"
                )}
              </Text>
              <DonutChart
                noDataText={t("reports:summary.no-data", "No data")}
                className="mt-6"
                data={
                  inboxUsageSummaryConvoSource.period &&
                  inboxUsageSummaryConvoSource.period.conversations_per_source.map(
                    (data) => ({
                      source: data[0],
                      count: data[1],
                    })
                  )
                }
                category="count"
                index="source"
                showLabel
                colors={["slate", "violet", "indigo", "rose", "cyan", "amber"]}
              />
              <Legend
                className="mt-3"
                categories={inboxUsageSummaryConvoSource.period.conversations_per_source.map(
                  (data) => data[0]
                )}
                colors={["slate", "violet", "indigo", "rose", "cyan", "amber"]}
              />
            </Card>
          ) : (
            <Loading />
          )}
        </div>
        <div className="col-span-2">
          <InboxSummaryMultiChart params={paramsQS} />
        </div>
      </div>

      <InboxSummaryTable
        params={paramsQS}
        period_type_type="WEEK"
        tableTitle={t(
          "reports:summary.summary-per-date-period-week",
          "Conversations summary (weekly)"
        )}
        dateColumnTitle={t("reports:summary.table.week-head", "Week of")}
      />

      <InboxSummaryTable
        params={paramsQS}
        period_type_type="DAY"
        tableTitle={t(
          "reports:summary.summary-per-date-period-day",
          "Conversations summary (daily)"
        )}
        dateColumnTitle={t("reports:summary.table.day-head", "Day")}
      />
    </div>
  );
}
