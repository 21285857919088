import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/outline";
import Loading from "src/components/Loading";
import useFlowTemplates from "src/hooks/useFlowTemplates";
import image_placeholder from "src/assets/img/wa/template_image_placeholder.svg";
import { buildRoute } from "src/router/router";

export default function FlowTemplateList({ currentTemplateId }) {
  const { data } = useFlowTemplates();

  return (
    <div className="w-full overflow-x-auto">
      {data ? (
        <div className="w-full min-h-full lg:ml-8 mt-8 lg:mt-0 flex-row flex overflow-x-scroll no-scrollbar gap-4 ">
          {data
            .filter((template) => template.id !== Number(currentTemplateId))
            .map((template) => (
              <Link
                to={buildRoute("flow-template", {
                  id: template.id,
                })}
                className=" bg-white w-[380px] md:w-[400px]  shrink-0 p-4 h-32 md:h-28 my-3 flex items-center rounded-lg"
              >
                <img
                  src={template.preview_image || image_placeholder}
                  className="h-16 w-16 object-cover self-center rounded-lg"
                  alt="template"
                />
                <div className="ml-4">
                  <p className="text-md font-semibold">
                    {template.preview_title}
                  </p>
                  <p className="text-xs mt-1 text-gray-500">
                    {template.preview_description}
                  </p>
                </div>
                <div>
                  <ChevronRightIcon className="w-6 h-6 ml-4 text-gray-300" />
                </div>
              </Link>
            ))}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}
