import { PropsWithChildren, useCallback, useEffect, useRef } from "react";
import Loading from "src/components/Loading";
import useOnScreen from "src/hooks/useOnScreen";
import { classNames } from "src/Helpers";
import ContentListGoToStart from "../inbox/Conversation/ContentListGoToStart";

interface ContentListScrollAreaProps {
  className?: string;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  hasFirstPageLoaded: boolean;
  isFetchingNextPage: boolean;
  isFetchingPreviousPage: boolean;
  unseenMessagesCount?: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
  onGoToFirstPage: () => void;
  onFocusStartOfContent: (focused: boolean) => void;
}

export default function ContentListScrollArea({
  children,
  className,
  hasNextPage,
  hasPreviousPage,
  hasFirstPageLoaded,
  isFetchingNextPage,
  isFetchingPreviousPage,
  unseenMessagesCount = 0,
  onNextPage,
  onPreviousPage,
  onGoToFirstPage,
  onFocusStartOfContent,
}: PropsWithChildren<ContentListScrollAreaProps>) {
  const listInnerRef = useRef<HTMLDivElement>(null);
  const nextPageLoadingRef = useRef(false);
  const previousPageLoadingRef = useRef(false);
  const [startOfList, startOfListRef, onScrollToStartOfList] = useOnScreen();
  const [endOfList, endOfListRef] = useOnScreen();

  const handleScrollToStartOfList = useCallback(() => {
    if (hasFirstPageLoaded) {
      onScrollToStartOfList();
    } else {
      onGoToFirstPage();
    }
  }, [hasFirstPageLoaded, onGoToFirstPage, onScrollToStartOfList]);

  useEffect(() => {
    if (endOfList) {
      if (!nextPageLoadingRef.current && hasNextPage) {
        nextPageLoadingRef.current = true;
        onNextPage();
      }
    }
  }, [endOfList, onNextPage, hasNextPage]);

  useEffect(() => {
    if (startOfList) {
      if (!previousPageLoadingRef.current && hasPreviousPage) {
        previousPageLoadingRef.current = true;
        onPreviousPage();
      }
      if (!hasPreviousPage) {
        onFocusStartOfContent(true);
      }
    } else {
      onFocusStartOfContent(false);
    }
  }, [startOfList, onPreviousPage, hasPreviousPage, onFocusStartOfContent]);

  useEffect(() => {
    nextPageLoadingRef.current = isFetchingNextPage;
  }, [isFetchingNextPage]);

  useEffect(() => {
    previousPageLoadingRef.current = isFetchingPreviousPage;
  }, [isFetchingPreviousPage]);

  return (
    <div
      ref={listInnerRef}
      className={classNames(
        "relative h-full flex flex-1 flex-col-reverse space-y-1.5 overflow-y-auto isolate",
        className
      )}
    >
      <ContentListGoToStart
        show={startOfList === false || !hasFirstPageLoaded}
        unseenMessagesCount={unseenMessagesCount}
        onScrollToStartOfList={handleScrollToStartOfList}
      />
      <div ref={startOfListRef} className="h-2 w-full" />
      {isFetchingPreviousPage && <Loading />}
      {children}
      {isFetchingNextPage && <Loading />}
      <div ref={endOfListRef} className="h-2 w-full" />
    </div>
  );
}
