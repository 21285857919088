import { TFunction } from "i18next";
import * as yup from "yup1";
import { ERROR_MESSAGES } from "src/constants/errors";
import { FlowStepBaseSchema } from "../flow";

export const FlowStepWhatsAppCAPISchema = (t: TFunction) =>
  FlowStepBaseSchema(t).shape({
    conversion_event: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
    custom_conversion_event_name: yup
      .string()
      .nullable()
      .when("conversion_event", {
        is: "CUSTOM",
        then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
      }),
  });
