import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ChevronRightIcon, ShareIcon } from "@heroicons/react/outline";
import Loading from "src/components/Loading";
import useFlowTemplates from "src/hooks/useFlowTemplates";
import image_placeholder from "src/assets/img/wa/template_image_placeholder.svg";
import { buildRoute } from "src/router/router";

export default function FlowTemplatesSection() {
  const { t } = useTranslation();
  const { data } = useFlowTemplates();

  return (
    <div className="h-full px-6">
      <div className="flex items-center">
        <div className="h-12 w-12 mr-4 bg-hilos-light rounded-full inline-flex items-center justify-center">
          <ShareIcon className=" text-hilos-alt-700 rotate-90 h-7 w-7" />
        </div>
        <div>
          <Trans i18nKey="dashboard:templates.title">
            <h3 className=" flex items-center text-lg font-bold leading-7 text-neutral-dark  sm:truncate">
              Flow Templates
            </h3>
            <p className=" text-sm text-neutral">
              Learn about everything you can achieve with Flows by exploring
              each of our templates.
            </p>
          </Trans>
        </div>
      </div>
      <div className="mt-6">
        {data ? (
          <>
            {data
              .filter((template) => template.show_in_home)
              .slice(0, 3)
              .map((template) => (
                <Link
                  to={buildRoute("flow-template", {
                    id: template.id,
                  })}
                  className="bg-white p-4 h-32 my-3 flex items-center relative rounded-lg border border-gray-200"
                >
                  <img
                    src={template.preview_image || image_placeholder}
                    className="h-24 w-24 object-cover self-center rounded-lg"
                    alt="template"
                  />
                  <div className="ml-4 mt-2">
                    <p className="text-md font-semibold">
                      {template.preview_title}
                    </p>
                    <p className="text-xs text-gray-500">
                      {template.preview_description}
                    </p>
                  </div>
                  <div className="mr-4 ml-auto">
                    <ChevronRightIcon className="w-6 h-6 text-gray-300" />
                  </div>
                </Link>
              ))}
          </>
        ) : (
          <Loading />
        )}
      </div>
      <div className="w-full mt-2 text-hilos-alt-700 flex">
        <Link
          to="/flow?tab=templates"
          className="flex items-center ml-auto text-sm font-medium"
        >
          More
          <ChevronRightIcon className="h-4 w-4 ml-4" />
        </Link>
      </div>
    </div>
  );
}
