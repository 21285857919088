import useContactDetails from "../../../../hooks/useContactDetails";
import ContactAssignmentList from "./ContactAssignmentList";
import ContactInfo from "./ContactInfo";
import ContactNoteList from "./ContactNoteList";
import ContactTagList from "./ContactTagList";

interface ContactTabProps {
  contactId: string;
}

function ContactTab({ contactId }: ContactTabProps) {
  const { contact, handleAddNote, handleRemoveNote, handleUpdateContact } =
    useContactDetails({
      contactId,
    });

  if (!contact) {
    return null;
  }

  return (
    <div className="flex flex-1 grow flex-col space-y-5">
      <ContactInfo contact={contact} onUpdateContact={handleUpdateContact} />
      <ContactTagList contact={contact} onUpdateContact={handleUpdateContact} />
      <ContactAssignmentList
        contact={contact}
        onUpdateContact={handleUpdateContact}
      />
      <ContactNoteList
        contact={contact}
        onAddNote={handleAddNote}
        onRemoveNote={handleRemoveNote}
      />
    </div>
  );
}

export default ContactTab;
