import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "sonner";
import HilosProvider from "./HilosProvider";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import SupportWidget from "./components/SupportWidget/SupportWidget";
import { SidebarProvider } from "./components/ui/sidebar";
import Conversation from "./containers/inbox/Conversation/Conversation";
import ConversationDetails from "./containers/inbox/Conversation/ConversationDetails";
import WithoutConversation from "./containers/inbox/Conversation/WithoutConversation";
import Inbox from "./containers/inbox/Inbox";
import ScheduledMessageList from "./containers/inbox/ScheduledMessageList";
import WorkflowBoard from "./containers/workflow/WorkflowBoard";
import WorkflowBoardTask from "./containers/workflow/WorkflowBoardTask";
import WorkflowBoardTaskDetails from "./containers/workflow/details/WorkflowBoardTaskDetails";
import NotFound from "./router/NotFound";
import PrivateRoute from "./router/PrivateRoute";
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "./router/router";
import { ServiceWorkerConfig } from "./serviceWorkerRegistration";

interface AppProps {
  config: ServiceWorkerConfig;
}

function App({ config }: AppProps) {
  // Whenever we load app.hilos.io, save every k,v for later attribution
  const searchParams = new URLSearchParams(window.location.search);
  const RELEVANT_PARAMS = [
    "utm_source",
    "utm_term",
    "utm_campaign",
    "utm_medium",
    "referrer",
  ];
  RELEVANT_PARAMS.forEach((param) => {
    if (searchParams.has(param)) {
      if (!localStorage.getItem(param)) {
        localStorage.setItem(param, searchParams.get(param) || "");
      }
    }
  });

  return (
    <BrowserRouter>
      <SidebarProvider className="w-screen h-screen">
        <Toaster closeButton expand={false} position="bottom-right" />
        <HilosProvider config={config}>
          <Routes>
            <Route
              path="/inbox"
              element={
                <PrivateRoute>
                  <ErrorBoundary type="page" key={"inbox"}>
                    <Inbox />
                  </ErrorBoundary>
                </PrivateRoute>
              }
            >
              <Route
                path="scheduled-messages"
                element={<ScheduledMessageList />}
              />
              <Route path="conversation" element={<WithoutConversation />} />
              <Route
                path="conversation/:inboxContactId"
                element={<Conversation />}
              >
                <Route path="details" element={<ConversationDetails />} />
              </Route>
              <Route
                path="conversation/:inboxContactId/message/:messageId"
                element={<Conversation />}
              >
                <Route path="details" element={<ConversationDetails />} />
              </Route>
            </Route>

            <Route
              path="/workflow/:workflowId/board"
              element={
                <PrivateRoute>
                  <ErrorBoundary type="page" key="workflow-board">
                    <WorkflowBoard />
                  </ErrorBoundary>
                </PrivateRoute>
              }
            >
              <Route
                path=":stateId/task/:taskId"
                element={<WorkflowBoardTask />}
              >
                <Route path="details" element={<WorkflowBoardTaskDetails />} />
              </Route>
            </Route>

            {PRIVATE_ROUTES.map((route, idx) => (
              <Route
                key={idx}
                path={route.path}
                element={
                  route.hideSidenav ? (
                    <ErrorBoundary type="page" key={idx}>
                      {route.component}
                    </ErrorBoundary>
                  ) : (
                    <PrivateRoute routeName={route.name}>
                      <ErrorBoundary type="page" key={idx}>
                        {route.component}
                      </ErrorBoundary>
                    </PrivateRoute>
                  )
                }
              />
            ))}

            {PUBLIC_ROUTES.map((route, idx) => (
              <Route
                key={idx}
                path={route.path}
                element={
                  <ErrorBoundary type="page" key={idx}>
                    {route.component}
                  </ErrorBoundary>
                }
              />
            ))}

            <Route element={<NotFound />} />
          </Routes>
          <SupportWidget />
        </HilosProvider>
      </SidebarProvider>
    </BrowserRouter>
  );
}

export default App;
