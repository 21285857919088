import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { FlowData } from "@hilos/types/flow";
import SelectorField from "src/components/Form/SelectorField";
import { getUpdatedVariablesWithExtraSources } from "src/helpers/variables";
import { TriggerCategoryProps } from "../FlowBuilderTrigger";
import FlowAdvancedOptionsDisclosure from "../components/FlowBuilderAdvancedOptionsDisclosure";
import { OUTBOUND_CAMPAIGN_TYPE_TRIGGER } from "../constants/triggers";
import TriggerCategorySelector from "./TriggerCategorySelector";

function CampaignTrigger({ handleTriggerTypeChange }: TriggerCategoryProps) {
  const [t] = useTranslation();
  const { values, setFieldValue } = useFormikContext<FlowData>();

  const availableFlowVariables = useMemo(
    () =>
      values.variables.reduce((nextAvailableFlowVariables, variable) => {
        if (variable.source === "flow") {
          const name = variable.name.replace(
            /^(flow|flow_execution_variable)\./,
            ""
          );
          nextAvailableFlowVariables.push({
            value: name,
            label: name,
          });
        }

        return nextAvailableFlowVariables;
      }, [] as { value: string; label: string }[]),
    [values.variables]
  );

  const handleFlowVariableChange = useCallback(
    (nextValues) => {
      const flowVariableKeys = (nextValues || []).map(({ value }) => value);
      setFieldValue(
        "variables",
        getUpdatedVariablesWithExtraSources({
          flowVariableKeys,
          currentVariables: values.variables,
        })
      );
    },
    [values.variables, setFieldValue]
  );

  return (
    <>
      <div className="pb-4">
        <h2 className="text-lg font-medium text-gray-900">
          {t(
            "flows:trigger.campaign-trigger.title",
            "Start your campaign from"
          )}
        </h2>
      </div>

      <div className="space-y-4">
        <TriggerCategorySelector
          handleTriggerTypeChange={handleTriggerTypeChange}
          triggersForCategory={OUTBOUND_CAMPAIGN_TYPE_TRIGGER}
        />

        <FlowAdvancedOptionsDisclosure
          defaultOpen={
            values.trigger_type === "OUTBOUND_CAMPAIGN_API" &&
            values.flow_execution_variables.length > 0
          }
        >
          <SelectorField
            isMulti
            isCreatable
            name="flow_execution_variables"
            label={t(
              "flows:flow-execution-variables.label",
              "What execution variables will this flow have?"
            )}
            help={t(
              "flows:flow-execution-variables.help",
              "The variables declared here will be available in the flow steps to use and you will provide them when executing this flow."
            )}
            options={availableFlowVariables}
            onSelect={handleFlowVariableChange}
          />
        </FlowAdvancedOptionsDisclosure>
      </div>
    </>
  );
}

export default CampaignTrigger;
