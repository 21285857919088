import { SVGProps } from "react";

function HilosIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 342 342"
      fill="none"
      stroke="currentColor"
      className="scale-125"
      {...props}
    >
      <path
        strokeWidth={"15"}
        d="M284.9,52.6v242a18.3,18.3,0,0,1-18.3,18.3H229.9a18.3,18.3,0,0,1-18.3-18.3V232.3a7.4,7.4,0,0,1,7.3-7.4,7.5,7.5,0,0,1,5.2,2.2,7.2,7.2,0,0,1,2.1,5.2v58.6a7.4,7.4,0,0,0,7.4,7.4h29.3a7.3,7.3,0,0,0,7.3-7.4V56.3a7.3,7.3,0,0,0-7.3-7.4H233.6a7.4,7.4,0,0,0-7.4,7.4v65.3a2.2,2.2,0,0,1-1,1.8l-8.2,4.3-5.4,2.8-4.2,2.2-7.9,4.2-1.7.9-.9.5-2.9,1.5-5.8,3.1-6,3.1-3.6,2-6.9,3.6L169,153l-1.4.8-4.7,2.4-3.5,1.9-6.5,3.4-3.1,1.7-5.8,3.1-3.8,2-2,1-3,1.6-1.9,1a6.1,6.1,0,0,1-2.4.4,7.3,7.3,0,0,1-7.3-7.3h0a7.4,7.4,0,0,1,3.9-6.5l1.9-1,6.5-3.4,2.3-1.3.8-.4,1.3-.7,8.3-4.3,4.3-2.3,5.2-2.8,8.9-4.7.6-.3h.1l.4-.2,9.2-4.9,4.9-2.5,4.7-2.5,8.9-4.7.7-.4.4-.2,1.2-.6,8-4.3,4.4-2.3a1.9,1.9,0,0,0,1.1-1.8V52.6a18.3,18.3,0,0,1,18.3-18.3h36.7A18.3,18.3,0,0,1,284.9,52.6Z"
      />
      <path
        strokeWidth={"15"}
        d="M226.2,182.8a7.2,7.2,0,0,1-3.7,6.4l-.4.2h0l-1.3.7-7.9,4.2-1.3.7-.4.2-.8.4-8.8,4.7-4.7,2.5-4.9,2.6-9.3,4.9h-.5l-.5.3-8.9,4.7-5.2,2.8-4.4,2.3-8.3,4.3-1.3.7-.7.4-2.3,1.2-6.6,3.5-1.9,1a7.4,7.4,0,0,0-3.9,6.5v56.4a18.3,18.3,0,0,1-18.3,18.3H83.2a18.3,18.3,0,0,1-18.3-18.3V52.6A18.3,18.3,0,0,1,83.2,34.3h36.7a18.3,18.3,0,0,1,18.3,18.3v62.3a7.3,7.3,0,0,1-7.3,7.4,7.5,7.5,0,0,1-5.2-2.2,7.2,7.2,0,0,1-2.1-5.2V56.3a7.4,7.4,0,0,0-7.4-7.4H86.9a7.4,7.4,0,0,0-7.3,7.4V290.9a7.4,7.4,0,0,0,7.3,7.4h29.3a7.4,7.4,0,0,0,7.4-7.4V226.3a2.2,2.2,0,0,1,1.2-2l9.6-5.1,3.8-2,4.6-2.4,8.5-4.5,1.1-.6.5-.3,1.8-.9,7.2-3.9,5.7-2.9,3.9-2.1,7.6-4,2-1.1,1.1-.6,3.6-1.9,4.9-2.6,6.2-3.2,3.4-1.8,6.5-3.5,3.1-1.6,1.7-.9,3.8-2h.1a6.7,6.7,0,0,1,3.4-.9A7.3,7.3,0,0,1,226.2,182.8Z"
      />
    </svg>
  );
}

export default HilosIcon;
