import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { InformationCircleIcon, PhoneIcon } from "@heroicons/react/outline";
import { Card, DonutChart, Legend, Text } from "@tremor/react";
import Button from "src/components/Button";
import Loading from "src/components/Loading";
import { useWidgetUsageSummaryCards } from "src/hooks/useWidgetSummaryAnalytics";
import {
  CalculateQueryParamsParams,
  paramsToQueryString,
} from "../FiltersMeta";

export interface WidgetReportProps {
  params: CalculateQueryParamsParams;
}

const WidgetSummary = ({ params }: WidgetReportProps) => {
  const { t } = useTranslation();
  const paramsQS = paramsToQueryString(params);
  const { widgetUsageSummaryCards } = useWidgetUsageSummaryCards(paramsQS);

  const buttonData = useMemo(() => {
    if (!widgetUsageSummaryCards) return [];
    return widgetUsageSummaryCards.period.button_data.filter((data) =>
      params.channel.length === 0
        ? true
        : // @ts-ignore
          params.channel.includes(data.channel_id)
    );
  }, [widgetUsageSummaryCards, params.channel]);

  const clicksPerButtonData = useMemo(() => {
    return buttonData.flatMap((button) => {
      return button.clicks_per_button.map((clickData) => ({
        label: button.button_text[clickData.button_index] || t("unknown"),
        count: clickData.count,
      }));
    });
  }, [buttonData, t]);

  const prepareAggregateData = (dataArray, key) => {
    return dataArray
      .flatMap((data) => data[key])
      .reduce((acc, item) => {
        const existing = acc.find(
          (entry) =>
            entry.label ===
            (item.referrer ||
              item.utm_campaign ||
              item.utm_medium ||
              item.utm_source ||
              item.utm_term ||
              t("unknown"))
        );
        if (existing) {
          existing.count += item.count;
        } else {
          acc.push({
            label:
              item.referrer ||
              item.utm_campaign ||
              item.utm_medium ||
              item.utm_source ||
              item.utm_term ||
              t("unknown"),
            count: item.count,
          });
        }
        return acc;
      }, []);
  };

  const clicksPerReferrerData = useMemo(
    () => prepareAggregateData(buttonData, "clicks_per_referrer"),
    [buttonData, t]
  );
  const clicksPerUtmCampaignData = useMemo(
    () => prepareAggregateData(buttonData, "clicks_per_utm_campaign"),
    [buttonData, t]
  );
  const clicksPerUtmMediumData = useMemo(
    () => prepareAggregateData(buttonData, "clicks_per_utm_medium"),
    [buttonData, t]
  );
  const clicksPerUtmSourceData = useMemo(
    () => prepareAggregateData(buttonData, "clicks_per_utm_source"),
    [buttonData, t]
  );
  const clicksPerUtmTermData = useMemo(
    () => prepareAggregateData(buttonData, "clicks_per_utm_term"),
    [buttonData, t]
  );

  if (!widgetUsageSummaryCards) {
    return <Loading />;
  }

  return (
    <>
      {buttonData.length === 0 ? (
        <div>
          <div className="h-screen w-full flex justify-center items-center">
            <div className="flex md:w-2/4 flex-col space-y-3 self-center text-center">
              <PhoneIcon className="h-10 w-10 mx-auto" />
              <h1 className="text-gray-800">
                {t(
                  "widget:no-widget.title",
                  "Seems like you don't have widgets yet"
                )}
              </h1>
              <p className="text-gray-500">
                {t(
                  "widget:no-widget.subtitle",
                  "What are you waiting for? Create a WhatsApp widget to place on your website!"
                )}
              </p>
              <div className="gap-x-3">
                <Link to="/integrations/wa-widget">
                  <Button className="my-3">
                    {t("widget:create-a-widget", "Create a widget")}
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="space-y-6">
          <div className="grid grid-cols-3 gap-x-6">
            <Card className="max-w-lg">
              <Text>
                {t("reports:summary.clicks-by-button", "Clicks by Button")}
              </Text>
              <DonutChart
                noDataText={t("reports:summary.no-data", "No data")}
                className="mt-6"
                data={clicksPerButtonData}
                category="count"
                index="label"
                showLabel
              />
              <Legend
                className="mt-3"
                categories={clicksPerButtonData.map((data) => data.label)}
              />
              <div className="rounded-md bg-blue-50 p-4 mt-3">
                <div className="flex">
                  <div className="shrink-0">
                    <InformationCircleIcon
                      aria-hidden="true"
                      className="size-5 text-blue-400"
                    />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-blue-700">
                      {t(
                        "widget:versioning-notice",
                        "Currently there is no widget versioning, so the labels above reflect the current widget button labels."
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </Card>

            <Card className="max-w-lg">
              <Text>
                {t("reports:summary.clicks-by-referrer", "Clicks by Referrer")}
              </Text>
              <DonutChart
                noDataText={t("reports:summary.no-data", "No data")}
                className="mt-6"
                data={clicksPerReferrerData}
                category="count"
                index="label"
                showLabel
              />
              <Legend
                className="mt-3"
                categories={clicksPerReferrerData.map((data) => data.label)}
              />
            </Card>

            <Card className="max-w-lg">
              <Text>
                {t(
                  "reports:summary.clicks-by-utm-campaign",
                  "Clicks by UTM Campaign"
                )}
              </Text>
              <DonutChart
                noDataText={t("reports:summary.no-data", "No data")}
                className="mt-6"
                data={clicksPerUtmCampaignData}
                category="count"
                index="label"
                showLabel
              />
              <Legend
                className="mt-3"
                categories={clicksPerUtmCampaignData.map((data) => data.label)}
              />
            </Card>
          </div>

          <div className="grid grid-cols-3 gap-x-6">
            <Card className="max-w-lg">
              <Text>
                {t(
                  "reports:summary.clicks-by-utm-medium",
                  "Clicks by UTM Medium"
                )}
              </Text>
              <DonutChart
                noDataText={t("reports:summary.no-data", "No data")}
                className="mt-6"
                data={clicksPerUtmMediumData}
                category="count"
                index="label"
                showLabel
              />
              <Legend
                className="mt-3"
                categories={clicksPerUtmMediumData.map((data) => data.label)}
              />
            </Card>

            <Card className="max-w-lg">
              <Text>
                {t(
                  "reports:summary.clicks-by-utm-source",
                  "Clicks by UTM Source"
                )}
              </Text>
              <DonutChart
                noDataText={t("reports:summary.no-data", "No data")}
                className="mt-6"
                data={clicksPerUtmSourceData}
                category="count"
                index="label"
                showLabel
              />
              <Legend
                className="mt-3"
                categories={clicksPerUtmSourceData.map((data) => data.label)}
              />
            </Card>

            <Card className="max-w-lg">
              <Text>
                {t("reports:summary.clicks-by-utm-term", "Clicks by UTM Term")}
              </Text>
              <DonutChart
                noDataText={t("reports:summary.no-data", "No data")}
                className="mt-6"
                data={clicksPerUtmTermData}
                category="count"
                index="label"
                showLabel
              />
              <Legend
                className="mt-3"
                categories={clicksPerUtmTermData.map((data) => data.label)}
              />
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default WidgetSummary;
