import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { WidgetUsageSummaryData } from "@hilos/types/analytics";
import { API_ROUTES } from "../router/router";

export function useWidgetUsageSummaryCards(params: string) {
  const fetchUsageSummary = useCallback(async () => {
    const url = API_ROUTES.ANALYTICS_WIDGET_SUMMARY_CARDS + "?" + params;
    const { data } = await axios.get<WidgetUsageSummaryData>(url);
    return data;
  }, [params]);

  const { data } = useQuery(
    "widget_usage_summary_cards" + params,
    fetchUsageSummary,
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    widgetUsageSummaryCards: data || undefined,
  };
}
