import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { FlowTestRead } from "@hilos/types/private-schema";
import { buildRoute } from "src/router/router";

interface FlowBuilderTestExecutionStartedProps {
  flowTest: FlowTestRead;
}

export default function FlowBuilderTestExecutionStarted({
  flowTest,
}: FlowBuilderTestExecutionStartedProps) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="text-lg text-gray-800 font-medium">
        {t("flows:test-modal.during-test.title", "Test started!")}
      </div>
      <div className="text-sm text-gray-600 space-y-1">
        {t(
          "flows:test-modal.during-test.description",
          "Click on the link below to follow your flow execution."
        )}
      </div>
      <Link
        to={buildRoute("flow-execution-contact-detail", {
          id: flowTest.flow_execution_contact,
        })}
        target="_blank"
        className="mt-4 inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-red-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2 w-full justify-center"
      >
        <ExternalLinkIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        {t(
          "flows:test-modal.during-test.button",
          "Go to test execution details"
        )}
      </Link>
    </div>
  );
}
