import { useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { CursorPageData } from "@hilos/types/hilos";
import { CategoryEnum } from "@hilos/types/private-schema";
import { WhatsAppTemplate } from "@hilos/types/wa/templates";
import * as templatesMeta from "../containers/wa/WhatsAppTemplateMeta";
import { API_ROUTES, buildAPIRoute } from "../router/router";

function useWhatsAppTemplates(
  channel: number | null,
  categories?: CategoryEnum[] | null
) {
  const fetchWhatsAppTemplates = useCallback(async () => {
    const { data } = await axios.get<CursorPageData<WhatsAppTemplate>>(
      buildAPIRoute(API_ROUTES.WHATSAPP_TEMPLATE_LIST),
      {
        params: {
          page_size: 2000,
          channel,
          ordering: "-usage",
          category__in: categories ? categories.join(",") : null,
        },
      }
    );
    return data;
  }, [channel, categories]);

  const { data: templates = { results: [] } } = useQuery(
    ["whatsapp_templates", channel],
    fetchWhatsAppTemplates
  );

  const approvedTemplates = useMemo(
    () =>
      templates.results.filter(
        (t) => t.status.toLowerCase() === templatesMeta.STATUS.APPROVED
      ),
    [templates.results]
  );

  const getVariablesFromTemplate = (template, setRequiredFields) => {
    if (setRequiredFields) {
      setRequiredFields(
        template ? templatesMeta.getTemplateVarsFromTemplate(template) : []
      );
    }
  };

  const getTemplateById = useCallback(
    (id: string) => {
      return templates.results.find((t) => t.id === id);
    },
    [templates.results]
  );

  return {
    templates,
    approvedTemplates,
    getTemplateById,
    getVariablesFromTemplate,
  };
}

export default useWhatsAppTemplates;
