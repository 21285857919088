import { Trans, useTranslation } from "react-i18next";
import { TRIGGER_TYPES } from "../builder/constants/triggers";

export default function MetaC2WATriggerDetail({ flow }) {
  const { t } = useTranslation();
  const triggerData = TRIGGER_TYPES[flow.trigger_type];

  return (
    <>
      <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
        {t("trigger", "Trigger")}
      </h3>
      <div className="flex items-center space-x-1">
        <div>
          <triggerData.icon className="h-8 w-8 text-gray-800" />
        </div>
        <div className="font-medium text-gray-900">
          <p className="text-xl">{t(triggerData.name)}</p>
        </div>
      </div>
      <div className="text-sm text-gray-700 mt-2">
        <div className="space-y-3">
          <p>
            {flow.trigger_config.c2wa_match_type === "MATCH_ALL" &&
              t(
                "flows:trigger-detail.meta-c2wa-match-all",
                "This flow will trigger when we receive any message from a Meta post or ad."
              )}
            {flow.trigger_config.c2wa_match_type === "MATCH_AD" && (
              <>
                {flow.trigger_config.c2wa_match_specific_ad ? (
                  <Trans i18nKey="flows:trigger-detail.meta-c2wa-match-specific-ad">
                    <p>
                      This flow will trigger when we receive a message from an
                      ad with id
                    </p>
                    <p className="rounded-md bg-gray-50 p-2">
                      <code className="text-sm font-medium text-gray-900">
                        {flow.trigger_config.c2wa_match_ad_id}
                      </code>
                    </p>
                  </Trans>
                ) : (
                  t(
                    "flows:trigger-detail.meta-c2wa-match-any-ad",
                    "This flow will trigger when we receive a message attributed to any ad"
                  )
                )}
              </>
            )}
            {flow.trigger_config.c2wa_match_type === "MATCH_POST" && (
              <>
                {flow.trigger_config.c2wa_match_specific_post ? (
                  <Trans i18nKey="flows:trigger-detail.meta-c2wa-match-specific-post">
                    <p>
                      This flow will trigger when we receive a message from a
                      post with id
                    </p>
                    <p className="rounded-md bg-gray-50 p-2">
                      <code className="text-sm font-medium text-gray-900">
                        {flow.trigger_config.c2wa_match_post_id}
                      </code>
                    </p>
                  </Trans>
                ) : (
                  t(
                    "flows:trigger-detail.meta-c2wa-match-any-post",
                    "This flow will trigger when we receive a message attributed to any post"
                  )
                )}
              </>
            )}
          </p>
        </div>
      </div>
    </>
  );
}
