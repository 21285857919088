import { PropsWithChildren } from "react";
import { AlertTriangle } from "lucide-react";
import { SidebarMenuButton, SidebarMenuItem } from "../ui/sidebar";

interface SidebarMenuNoticeProps {
  title: string;
  description: string;
}

function SidebarMenuNotice({
  title,
  description,
  children,
}: PropsWithChildren<SidebarMenuNoticeProps>) {
  return (
    <SidebarMenuItem>
      <SidebarMenuButton
        asChild
        className="h-auto w-full hover:bg-red-50 active:bg-red-50"
      >
        <div className="rounded-md bg-red-50 py-2 px-4">
          <div className="flex flex-col items-center gap-2">
            <AlertTriangle
              aria-hidden="true"
              className="shrink-0 self-center size-4 text-red-400"
            />
            <p className="group-data-[collapsible=icon]:hidden text-sm text-center font-semibold text-red-800">
              {title}
            </p>
            <p className="group-data-[collapsible=icon]:hidden text-sm text-center text-red-700">
              {description}
            </p>
            {children}
          </div>
        </div>
      </SidebarMenuButton>
    </SidebarMenuItem>
  );
}

export default SidebarMenuNotice;
