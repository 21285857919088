import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { axiosErr } from "@hilos/types/axios";
import usePostHogHelpers from "src/hooks/usePostHogHelpers";
import { signup } from "../../helpers/session";
import useHilosStore from "../../hooks/useHilosStore";
import { buildRoute } from "../../router/router";
import { SignupForm } from "./SignupForm";

export default function Signup() {
  const navigate = useNavigate();
  const { identifyUser, maybeStartRecording } = usePostHogHelpers();
  const { setCredentials } = useHilosStore();
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { t } = useTranslation();

  const onSubmit = async (data) => {
    setBackendValidationErrors({});
    setBackendError("");
    setSuccess(false);
    setSubmitted(false);
    try {
      const response = await signup(data);
      if (response) {
        const credentials = {
          token_access: response.data.access,
          token_refresh: response.data.refresh,
        };
        setSuccess(true);
        setSubmitted(true);
        setCredentials(credentials, (user) => {
          identifyUser(user, "signup");
          if (
            response.data.invitations &&
            response.data.invitations.length > 0
          ) {
            navigate(buildRoute("account-selection"));
          } else {
            navigate(buildRoute("welcome"));
            // @ts-ignore
            if (window.dataLayer) {
              // @ts-ignore
              window.dataLayer.push({
                event: "sign-up",
                signupEmail: user.email,
              });
            }
          }
        });
      }
    } catch (error) {
      const axiosErr = error as axiosErr;
      if (
        axiosErr?.response &&
        axiosErr.response.status === 400 &&
        axiosErr.response.data
      ) {
        setBackendValidationErrors(axiosErr.response.data);
      } else {
        setBackendError("An error occurred, please try again.");
      }
      setSuccess(false);
      setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    }
  };

  useEffect(() => {
    maybeStartRecording();
  }, [maybeStartRecording]);

  return (
    <div className="flex flex-1 w-full flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div className="mx-auto w-full ">
        <div className="text-center">
          <Trans i18nKey="login:sign-up.title">
            <h2 className="mt-6 text-lg font-extrabold text-gray-900">
              👋 Welcome to <span className="text-hilos">Hilos</span> !
            </h2>
          </Trans>
          <p className="text-2xl mt-4 text-gray-800 font-bold">
            {t(
              "login:sign-up.subtitle",
              "Close and onboard your customers on WhatsApp"
            )}
          </p>
        </div>

        <div className="mx-auto w-full max-w-md lg:w-96">
          <div className="mt-8">
            <div className="mt-6">
              <SignupForm
                onSubmit={onSubmit}
                backendError={backendError}
                backendValidationErrors={backendValidationErrors}
                success={success}
                submitted={submitted}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
